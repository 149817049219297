<template>
  <div>
    <portal v-if="!$isSmallDevice" to="modal">
      <notes-modal
        :time-tracking-id="timeTracking.id"
        :notes="timeTracking.notes"
        :followers="timeTracking.followers"
        :action-status="actionStatus"
        @close="$emit('close')"
      />
    </portal>

    <portal v-else to="bottom-sheet">
      <notes-bottom-sheet
        :time-tracking-id="timeTracking.id"
        :notes="timeTracking.notes"
        :followers="timeTracking.followers"
        :action-status="actionStatus"
        @close="$emit('close')"
      />
    </portal>
  </div>
</template>

<script>
import NotesModal from './time-tracking-notes-modal/TimeTrackingNotesModal'
import NotesBottomSheet from './time-tracking-notes-bottom-sheet/TimeTrackingNotesBottomSheet'

export default {
  name: 'TimeTrackingNotes',
  components: {
    NotesModal,
    NotesBottomSheet,
  },
  props: {
    timeTracking: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
