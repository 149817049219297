import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { TIME_TRACKING_NOTE_ACTION_UPDATE } from '../../../store/actions/time-tracking.actions.names'

export default function useEditNote(
  timeTrackingId,
  currentNote,
  newAttachments,
  removedAttachments,
  clearAttachments,
  switchToNote,
  fetchNotes
) {
  const editingNote = ref(null)

  const editNote = (note) => {
    editingNote.value = { ...note.value }
    switchToNote(editingNote)
  }

  const submitEditingNote = async () => {
    const note = await store.dispatch(TIME_TRACKING_NOTE_ACTION_UPDATE, {
      timeTrackingId: timeTrackingId.value,
      id: editingNote.value.id,
      content: editingNote.value.content,
      newAttachments: [...newAttachments.value],
      removedAttachments: [...removedAttachments.value],
    })
    await fetchNotes()
    editingNote.value = null
    clearAttachments()
    switchToNote(note)
  }

  watch(currentNote, () => {
    if (currentNote.value?.id !== editingNote.value?.id) {
      editingNote.value = null
    }
  })

  return { editNote, editingNote, submitEditingNote }
}
