<template>
  <div class="flex mb-3">
    <span class="w-1/3 md:w-1/5 mr-4">
      {{ $t('time_tracking.export_modal.time_period') }}
    </span>
    <span class="text-coal-550 flex-1">
      {{ displayText }}
    </span>
  </div>
</template>

<script>
import shortcutsMixin from '@/app/util-modules/ui/date-range-picker/shortcuts.mixin'
import { formatDate, getShortcuts } from '@/utils/date-time.utils'

const DATE_FILTER_FORMAT = 'LLL d yyyy'

export default {
  name: 'TimeTrackingAppliedDateFilter',
  mixins: [shortcutsMixin],
  props: {
    defaultValue: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    startTime() {
      return this.defaultValue[0]
    },
    endTime() {
      return this.defaultValue[1]
    },
    formattedStartTime() {
      if (!this.startTime) return ''
      return formatDate(this.startTime, DATE_FILTER_FORMAT)
    },
    formattedEndTime() {
      if (!this.endTime) return ''
      return formatDate(this.endTime, DATE_FILTER_FORMAT)
    },
    displayText() {
      return this.defaultValue
        ? `${this.shortcutSelectedName} ${this.formattedStartTime} - ${this.formattedEndTime}`
        : this.shortcutSelectedName
    },
  },
  methods: {
    getShortcuts,
  },
}
</script>
