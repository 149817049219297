import Vue from 'vue'

export default function useScrollToNote($refs, notes) {
  const scrollToNote = (note) => {
    if (note.value && note.value.id) {
      const noteIndex = notes.value.findIndex(
        (item) => item.id === note.value.id
      )
      Vue.nextTick(() => {
        // on bottom-sheet, note list is completely hidden
        // when editing a note, so ? check is needed
        $refs['note-list']?.scrollToIndex(noteIndex)
      })
    } else {
      // it requires 2 ticks. First tick for rendering new-note-card,
      // another tick to populate $refs with it
      Vue.nextTick(() => {
        Vue.nextTick(() => {
          // on bottom-sheet, note list is completely hidden
          // when adding a note, so this check is needed
          if ($refs['new-note-card']) {
            const target = $refs['new-note-card'].$el
            target.parentNode.scrollTop = target.offsetTop
          }
        })
      })
    }
  }

  return { scrollToNote }
}
