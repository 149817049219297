import store from '@/store'
import { State, RecordGeneric, RecordsIndexes } from '@/utils/jsonapi/types'
import { getDateRangeLast30Days } from '@/utils/date-time.utils'
import {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { TAG_PUBLIC_GETTER_TAG_LIST } from '@/app/core/tag/store/getters/tag.getters.names'
import { AREA_PUBLIC_GETTER_AREA_LIST } from '@/app/core/area/store/getters/area.getters.names'
import { TimeTracking, User } from '../models/time-tracking.model'
import { Break } from '../models/time-tracking-break.model'
import { Signature } from '../models/time-tracking-signature.model'
import { Attachment } from '@/utils/note-attachments'
import { Note } from '../models/time-tracking-note.model'
import { BaseResponseMeta } from '@/utils/api/base-response'

export interface ActionBreak {
  starts_at: string
  ends_at: string
  id?: string
}
export interface TimeTrackingMeta extends BaseResponseMeta {
  total_unconfirmed?: number
  total_confirmed?: number
}
export interface TimeTrackingState extends State {
  records: {
    time_tracking: TimeTracking[]
    active_time_tracking: TimeTracking[]
    // user record type is being used in followers
    user: User[]
    user_overview: User[]
    break: Break[]
    note: Note[]
    attachment: Attachment[]
    signature: Signature[]
    area: () => RecordGeneric[]
    tag: () => RecordGeneric[]
  }
  // used for data indexing by jsonapi utils.
  recordsIndexes: RecordsIndexes

  noteCurrentMeta: Partial<BaseResponseMeta>
  timeTrackingsPagination: BaseRequestPagination
  timeTrackingFilterbarFilters: string[]
  timeTrackingFilterbarValues: Record<string, unknown>
  timeTrackingFilterbarShowFormNew: boolean
  timeTrackingAllMeta: TimeTrackingMeta
  timeTrackingCurrentMeta: TimeTrackingMeta
  timeTrackingTab: string
  timeTrackingFilterDateRange: Date[]
  timeTrackingFilters: Record<string, unknown>
  timeTrackingsSorting: BaseRequestSorting
  pendingExportIds: string[]
  actionBreaks: ActionBreak[]
}

export const makeEmptyState = (): TimeTrackingState => ({
  records: {
    time_tracking: [],
    active_time_tracking: [],
    user: [],
    user_overview: [],
    break: [],
    note: [],
    attachment: [],
    signature: [],
    area: () => store.getters[AREA_PUBLIC_GETTER_AREA_LIST],
    tag: () => store.getters[TAG_PUBLIC_GETTER_TAG_LIST],
  },
  recordsIndexes: {},

  noteCurrentMeta: {},

  timeTrackingsPagination: {
    items: 30,
    page: 0,
  },
  timeTrackingFilterbarFilters: [],
  timeTrackingFilterbarValues: {},
  timeTrackingFilterbarShowFormNew: false,
  timeTrackingAllMeta: <TimeTrackingMeta>{},
  timeTrackingCurrentMeta: <TimeTrackingMeta>{},
  timeTrackingTab: 'all',
  timeTrackingFilterDateRange: getDateRangeLast30Days(),
  timeTrackingFilters: {},
  timeTrackingsSorting: {
    starts_at: -1,
    gross_time: 0,
    break_time: 0,
    net_time: 0,
    // e.g.
    //
    // asc sorting
    // starts_at: 1,
    //
    // desc sorting
    // net_time: -1
    //
    // no sorting
    // employee: 0
  },
  pendingExportIds: JSON.parse(
    localStorage.getItem('pendingExportIds') || '[]'
  ),
  actionBreaks: [],
})

export default makeEmptyState()
