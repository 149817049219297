<template>
  <div>
    <portal v-if="!$isSmallDevice" to="modal">
      <base-attachments-modal
        :attachments="timeTracking.attachments"
        :action-status="actionStatus"
        data-id-prefix="time_tracking.index"
        @close="$emit('close')"
      />
    </portal>

    <portal v-else to="bottom-sheet">
      <attachments-bottom-sheet
        :time-tracking-id="timeTracking.id"
        :attachments="timeTracking.attachments"
        :action-status="actionStatus"
        @close="$emit('close')"
      />
    </portal>
  </div>
</template>

<script>
import BaseAttachmentsModal from '@/app/util-modules/ui/attachments-modal/BaseAttachmentsModal.vue'
import AttachmentsBottomSheet from './time-tracking-attachments-bottom-sheet/TimeTrackingAttachmentsBottomSheet'

export default {
  name: 'TimeTrackingAttachments',
  components: {
    BaseAttachmentsModal,
    AttachmentsBottomSheet,
  },
  props: {
    timeTracking: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
