<template>
  <base-modal has-modal-card @close="$emit('close')">
    <base-card-modal
      :title="$t('time_tracking.notes_modal.title')"
      class="h-full"
      data-id-prefix="time_tracking.notes_modal"
      @close="$emit('close')"
    >
      <div class="relative w-full h-full">
        <base-loading v-if="actionStatus.isLoading" :active="true" />

        <div class="flex h-full">
          <div class="w-50 mr-8">
            <base-button
              v-if="!newNote"
              size="is-small"
              type="is-outlined"
              class="w-full mb-1"
              data-id="time_tracking.notes_modal.new_note_button"
              @click="addNewNoteCard()"
            >
              {{ $t('time_tracking.notes_modal.new_note_button') }}
            </base-button>

            <base-system-notes-toggler
              v-if="hasSystemNotesPermission"
              class="bg-coal-30 flex justify-between align-middle p-2 rounded font-semibold md:text-sm"
              :value="systemNotesShown"
              data-id-prefix="time_tracking.notes_modal.toggle_system"
              @toggle="toggleSystemNotes($event)"
            />

            <div class="h-full">
              <note-card
                v-if="newNote"
                ref="new-note-card"
                class="mb-2.5"
                :source="{
                  ...newNote,
                  content:
                    newNote.content || $t('time_tracking.notes_modal.new_note'),
                }"
              />

              <base-virtual-list
                ref="note-list"
                :data-sources="notes"
                :data-component="noteCardComponent"
                :item-scoped-slots="{ t: 12 }"
                :estimate-size="86"
                :extra-props="{ currentNote }"
                :keeps="notesPerPage"
                class="relative h-full overflow-y-auto hidden-scrollbar"
                :class="{ 'pt-2.5 ': !newNote }"
                item-class="mb-2.5 last:mb-20"
                @card-click="currentNote = $event"
                @edit="editNote($event)"
                @delete="deleteNote($event)"
                @tobottom="fetchAppendNotes()"
              />
            </div>
          </div>

          <div class="w-100">
            <note-details
              v-if="currentNote && !editingNote"
              :current-note="currentNote"
              @edit="editNote(currentNote)"
              @delete="deleteNote(currentNote)"
            />
            <note-form
              v-else-if="editingNote"
              :note="editingNote"
              :action-status="$actions.updateNote"
              data-id="time_tracking.notes_modal.new_note_textarea"
              @input="editingNote.content = $event"
              @file-input="setNewAttachments($event)"
              @attachment-delete="removeExistingAttachment($event)"
              @submit-click="submitEditingNote()"
            />

            <note-form
              v-if="newNote"
              :note="newNote"
              :action-status="$actions.addNote"
              @input="newNote.content = $event"
              @file-input="setNewAttachments($event)"
              @attachment-delete="removeExistingAttachment($event)"
              @submit-click="submitNewNote()"
            />
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <base-card-modal-footer-wrapper class="justify-between">
          <followers
            :followers="followers"
            data-id-prefix="time_tracking_notes_modal"
            @addFollower="addFollower($event)"
            @removeFollower="removeFollower($event)"
          />
          <base-button
            :disabled="false"
            type="is-filled"
            data-id="time_tracking.notes_modal.button_done"
            @click="$emit('close')"
          >
            {{ $t('time_tracking.notes_modal.button_done') }}
          </base-button>
        </base-card-modal-footer-wrapper>
      </template>
    </base-card-modal>
  </base-modal>
</template>

<script>
import { defineComponent, ref, toRefs } from '@vue/composition-api'
import store from '@/store'
import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'
import {
  TIME_TRACKING_NOTE_ACTION_ADD,
  TIME_TRACKING_NOTE_ACTION_UPDATE,
} from '../../../store/actions/time-tracking.actions.names'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModal from '@/app/util-modules/ui/card-modal/BaseCardModal'
import BaseCardModalFooterWrapper from '@/app/util-modules/ui/card-modal/BaseCardModalFooterWrapper'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseVirtualList from '@/app/util-modules/ui/virtual-list/BaseVirtualList'
import Followers from '@/app/util-modules/ui/followers/Followers'
import BaseSystemNotesToggler from '@/app/util-modules/ui/system-notes-toggler/BaseSystemNotesToggler'
import NoteCard from '../TimeTrackingNotesNoteCard'
import NoteDetails from '../TimeTrackingNotesDetails'
import NoteForm from '../TimeTrackingNotesForm'

import useFetchNotes from '../composables/use-fetch-notes'
import useSystemNotes from '../composables/use-system-notes'
import useCurrentNote from '../composables/use-current-note'
import useScrollToNote from '../composables/use-scroll-to-note'
import useNewNote from '../composables/use-new-note'
import useEditNote from '../composables/use-edit-note'
import useDeleteNote from '../composables/use-delete-note'
import useAttachments from '../composables/use-attachments'
import useFollowers from '../composables/use-followers'

export default defineComponent({
  name: 'TimeTrackingNotesModal',
  components: {
    BaseModal,
    BaseCardModal,
    BaseCardModalFooterWrapper,
    BaseLoading,
    BaseButton,
    BaseVirtualList,
    Followers,
    BaseSystemNotesToggler,
    // avoids mutation, because NoteCard object is used in 2 different places
    NoteCard: { ...NoteCard },
    NoteDetails,
    NoteForm,
  },
  props: {
    timeTrackingId: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    followers: {
      type: Array,
      default: () => [],
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  trackedActions: {
    addNote: TIME_TRACKING_NOTE_ACTION_ADD,
    updateNote: TIME_TRACKING_NOTE_ACTION_UPDATE,
  },

  setup(props, { refs }) {
    const hasSystemNotesPermission =
      store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        'General'
      ).show_system_notes

    const { notes, followers, timeTrackingId } = toRefs(props)
    const { systemNotesShown, toggleSystemNotes, noteType } = useSystemNotes()
    const { notesPerPage, fetchNotes, fetchAppendNotes } = useFetchNotes(
      timeTrackingId,
      noteType
    )
    const { scrollToNote } = useScrollToNote(refs, notes)
    const { currentNote, switchToNote } = useCurrentNote(notes, scrollToNote)
    const {
      newAttachments,
      removedAttachments,
      setNewAttachments,
      removeExistingAttachment,
      clearAttachments,
    } = useAttachments()
    const { newNote, addNewNoteCard, submitNewNote } = useNewNote(
      timeTrackingId,
      currentNote,
      newAttachments,
      clearAttachments,
      switchToNote,
      fetchNotes
    )
    const { editNote, editingNote, submitEditingNote } = useEditNote(
      timeTrackingId,
      currentNote,
      newAttachments,
      removedAttachments,
      clearAttachments,
      switchToNote,
      fetchNotes
    )
    const { deleteNote } = useDeleteNote(timeTrackingId, fetchNotes)
    const { addFollower, removeFollower } = useFollowers(
      followers,
      timeTrackingId
    )

    return {
      hasSystemNotesPermission,

      notesPerPage,
      fetchAppendNotes,

      systemNotesShown,
      toggleSystemNotes,

      currentNote,

      newNote,
      addNewNoteCard,
      submitNewNote,

      editingNote,
      editNote(note) {
        editNote(ref(note))
      },
      submitEditingNote,

      deleteNote(note) {
        deleteNote(ref(note))
      },

      setNewAttachments,
      removeExistingAttachment,

      addFollower,
      removeFollower,

      noteCardComponent: NoteCard,
    }
  },
})
</script>

<style scoped>
::v-deep .base-card-modal-body {
  @apply overflow-hidden;
}
</style>
