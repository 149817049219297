import state, { makeEmptyState } from './time-tracking.state'
import mutations from './mutations/time-tracking.mutations'
import timeTrackingActions from './actions/time-tracking.actions'
import timeTrackingNoteActions from './actions/time-tracking-note.actions'
import timeTrackingAttachmentActions from './actions/time-tracking-attachment.actions'
import timeTrackingSignatureActions from './actions/time-tracking-signature.actions'
import timeTrackingFollowerActions from './actions/time-tracking-follower.actions'
import timeTrackingExportActions from './actions/time-tracking-export.actions'
import timeTrackingClockActions from './actions/time-tracking-clock.actions'
import getters from './getters/time-tracking.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ...timeTrackingActions,
    ...timeTrackingNoteActions,
    ...timeTrackingAttachmentActions,
    ...timeTrackingSignatureActions,
    ...timeTrackingFollowerActions,
    ...timeTrackingExportActions,
    ...timeTrackingClockActions,
  },
  getters,
}

export { makeEmptyState }
