<template>
  <div
    class="flex justify-between items-center py-4 first:pt-0 last:pb-0 border-b border-coal-150 last:border-b-0"
  >
    <div class="flex">
      <div class="flex mr-0.5">
        <base-icon
          v-if="titleData.icon"
          :icon="titleData.icon"
          size="1rem"
          class="mr-2 mt-px"
        />
        <span class="font-semibold">
          {{ titleData.text }}
        </span>
      </div>
      <span class="text-coal-550">
        {{
          $t('time_tracking.signatures_modal.signature_time', {
            time: signatureTime,
          })
        }}
      </span>
    </div>

    <img :src="image" :alt="title" class="h-9" />
  </div>
</template>

<script>
import { formatTime } from '@/utils/date-time.utils'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TimeTrackingSignaturesModalItem',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
  computed: {
    signatureTime() {
      return formatTime(new Date(this.time))
    },
    titleData() {
      switch (this.title) {
        case 'start':
          return {
            icon: 'play-start/play-start-16',
            text: this.$t('time_tracking.signatures_modal.start'),
          }
        case 'end_of_break':
          return {
            icon: 'play-start/play-start-16',
            text: this.$t('time_tracking.signatures_modal.end_of_break'),
          }
        case 'start_of_break':
          return {
            icon: 'pause/pause-16',
            text: this.$t('time_tracking.signatures_modal.start_of_break'),
          }
        case 'end':
          return {
            icon: 'stop/stop-16',
            text: this.$t('time_tracking.signatures_modal.end'),
          }
        default:
          return {
            icon: '',
            text: '',
          }
      }
    },
  },
}
</script>
