<template>
  <component
    :is="visibleComponentName"
    :title="title"
    :tracked-action="$actions.batchAssignTags"
    @closeModal="closeModal()"
    @submit="submit()"
  >
    <time-tracking-assign-tag-form
      :class="{ 'px-4 pt-4 pb-6': $isSmallDevice }"
      :selected-tag.sync="selectedTag"
      :assign-option.sync="assignOption"
    />
  </component>
</template>

<script>
import TimeTrackingAssignTagForm from './TimeTrackingAssignTagForm'
import { mapActions } from 'vuex'
import { TIME_TRACKING_ACTION_BATCH_ASSIGN_TAGS } from '../../store/actions/time-tracking.actions.names'

const TimeTrackingAssignTagDesktop = () =>
  import('./TimeTrackingAssignTagDesktop')
const TimeTrackingAssignTagMobile = () =>
  import('./TimeTrackingAssignTagMobile')

export default {
  name: 'TimeTrackingAssignTag',
  components: {
    TimeTrackingAssignTagForm,
    TimeTrackingAssignTagDesktop,
    TimeTrackingAssignTagMobile,
  },
  trackedActions: {
    batchAssignTags: TIME_TRACKING_ACTION_BATCH_ASSIGN_TAGS,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTag: null,
      assignOption: 'add',
    }
  },
  computed: {
    visibleComponentName() {
      return this.$isSmallDevice
        ? TimeTrackingAssignTagMobile
        : TimeTrackingAssignTagDesktop
    },
    title() {
      let multipleTrackingTitle = ''
      if (this.rows.length > 1) {
        if (this.$isSmallDevice) {
          multipleTrackingTitle = this.$t(
            'time_tracking.assign_tag_modal.to_multiple_trackings_mobile',
            {
              trackings: this.rows.length,
            }
          )
        } else {
          multipleTrackingTitle = this.$t(
            'time_tracking.assign_tag_modal.to_multiple_trackings',
            {
              trackings: this.rows.length,
            }
          )
        }
      }

      return `
        ${this.$t('time_tracking.assign_tag_modal.title')}
        ${multipleTrackingTitle}
      `
    },
  },
  methods: {
    ...mapActions({
      batchAssignTags: TIME_TRACKING_ACTION_BATCH_ASSIGN_TAGS,
    }),
    closeModal() {
      this.$emit('closeModal')
    },
    async submit() {
      const message =
        this.rows.length > 1
          ? this.$t('time_tracking.assign_tag_modal.success_message_batch')
          : this.$t('time_tracking.assign_tag_modal.success_message')

      await this.batchAssignTags({
        trackingIds: this.rows.map((tracking) => tracking.id),
        tagId: this.selectedTag.id,
        overrideTags: this.assignOption === 'override',
      })

      this.$buefy.snackbar.open(message)

      this.closeModal()
    },
  },
}
</script>
