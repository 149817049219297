import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import * as actionNamesObj from './time-tracking.actions.names'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import {
  TIME_TRACKING_TYPE,
  TIME_TRACKING_REL_FOLLOWERS,
} from '../../models/time-tracking.model'
import { TIME_TRACKING_FOLLOWER_LIST_TYPE } from '../../models/time-tracking-follower.model'
import api from '../../network/time-tracking.api'
import { TimeTrackingState } from '../time-tracking.state'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.TIME_TRACKING_FOLLOWER_ACTION_LIST]: async (
    { commit, rootState }: ActionContext<TimeTrackingState, any>,
    { timeTrackingId }: { timeTrackingId: RecordId }
  ): Promise<void> => {
    const responseData = await api.listTimeTrackingFollowers({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_RECORDS, {
      type: TIME_TRACKING_FOLLOWER_LIST_TYPE,
      records: responseData.data,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_ATTACH_RELATIONSHIPS, {
      parentType: TIME_TRACKING_TYPE,
      parentId: timeTrackingId,
      relName: TIME_TRACKING_REL_FOLLOWERS,
      children: responseData.data,
    })
  },

  [actionNames.TIME_TRACKING_FOLLOWER_ACTION_ADD]: async (
    { rootState, dispatch }: ActionContext<TimeTrackingState, any>,
    { timeTrackingId, userId }: { timeTrackingId: RecordId; userId: RecordId }
  ): Promise<void> => {
    await api.addTimeTrackingFollower({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      userIds: [userId],
    })
    await dispatch(actionNames.TIME_TRACKING_FOLLOWER_ACTION_LIST, {
      timeTrackingId,
    })
  },

  [actionNames.TIME_TRACKING_FOLLOWER_ACTION_DELETE]: async (
    { rootState, commit }: ActionContext<TimeTrackingState, any>,
    { timeTrackingId, userId }: { timeTrackingId: RecordId; userId: RecordId }
  ): Promise<void> => {
    await api.deleteTimeTrackingFollower({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      userId,
    })
    commit(mutationNames.TIME_TRACKING_MUTATION_DETACH_RELATIONSHIPS, {
      parentType: TIME_TRACKING_TYPE,
      parentId: timeTrackingId,
      relName: TIME_TRACKING_REL_FOLLOWERS,
      children: [{ id: userId }],
    })
  },
}
