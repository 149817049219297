<template>
  <base-date-time-range-picker
    :key="breakItem.id"
    v-model="dateTimeRange"
    class="time-tracking-form-break mb-2.5 last:mb-0"
    :class="{
      desktop: !$isSmallDevice,
      'is-same-day': isSameDayTimeTracking,
    }"
    :time-only="isSameDayTimeTracking && !$isSmallDevice"
    :clearable="true"
    :range-start-label="
      $t('time_tracking.add_modal.form_tracking_break_date_range_start_label')
    "
    :range-end-label="
      $t('time_tracking.add_modal.form_tracking_break_date_range_end_label')
    "
    name="time_tracking_break"
    direction="is-top-right"
    append-to-body
    :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
    data-id-prefix="time_tracking.add_modal.form_break"
    @clear="$emit('remove', breakItem.id)"
    @change-start="handleStartChange($event)"
  >
    <template v-if="$isSmallDevice" v-slot:clear>
      <div>
        <button
          class="text-red-400 font-semibold mt-3"
          @click="$emit('remove', breakItem.id)"
        >
          {{
            $t('time_tracking.add_modal.form_tracking_break_remove_text_mobile')
          }}
        </button>
      </div>
    </template>
  </base-date-time-range-picker>
</template>

<script>
import { setHours, setMinutes, isSameDay } from 'date-fns'
import BaseDateTimeRangePicker from '@/app/util-modules/ui/date-time-picker/BaseDateTimeRangePicker'
import { isValidDate } from '@/utils/date-time.utils'

export default {
  name: 'TimeTrackingFormBreak',
  components: {
    BaseDateTimeRangePicker,
  },
  props: {
    isSameDayTimeTracking: {
      type: Boolean,
      required: true,
    },
    breakItem: {
      type: Object,
      required: true,
      validator: ({ id, startDateTime, endDateTime }) =>
        (typeof id === 'number' || typeof id === 'string') &&
        isValidDate(startDateTime) &&
        isValidDate(endDateTime),
    },
  },
  computed: {
    dateTimeRange: {
      get() {
        const { startDateTime, endDateTime } = this.breakItem

        return {
          startDateTime,
          endDateTime,
        }
      },
      set({ startDateTime, endDateTime }) {
        this.$nextTick(() => {
          this.$emit('update-break', {
            ...this.breakItem,
            startDateTime,
            endDateTime,
          })
        })
      },
    },
    isSameDayBreak() {
      return isSameDay(
        new Date(this.dateTimeRange.startDateTime),
        new Date(this.dateTimeRange.endDateTime)
      )
    },
  },
  methods: {
    handleStartChange(startDateTime) {
      if (this.isSameDayBreak) {
        const { endDateTime } = this.dateTimeRange

        const newEndDateTime = setHours(
          setMinutes(new Date(startDateTime), endDateTime.getMinutes()),
          endDateTime.getHours()
        )

        this.dateTimeRange = {
          startDateTime,
          endDateTime: newEndDateTime,
        }
      }
    },
  },
}
</script>

<style scoped>
.time-tracking-form-break.desktop:not(.is-same-day) {
  @apply relative;
}
.time-tracking-form-break.desktop.is-same-day {
  width: calc(100% - 130px);
}
.time-tracking-form-break.desktop.is-same-day >>> .base-date-time-picker {
  width: 100%;
}

.time-tracking-form-break.desktop.is-same-day
  >>> .base-date-time-picker-wrapper:first-child {
  width: 306px;
  min-width: 306px;
}
.time-tracking-form-break.desktop:not(.is-same-day) >>> .button-clear {
  @apply absolute left-full top-2;
}
</style>
