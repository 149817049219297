import { shortname } from '@/utils/store'
import { Commit } from 'vuex'
import { TIME_TRACKING_TYPE } from '../../models/time-tracking.model'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import api from '../../network/time-tracking.api'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n'

const mutationNames = shortname(mutationNamesObj)

export async function refetchTrackings(
  { commit }: { commit: Commit },
  {
    workspaceId,
    trackingIds,
  }: { workspaceId: RecordId; trackingIds: RecordId[] }
) {
  const responseData = await Promise.all(
    trackingIds.map((timeTrackingId) =>
      api.getTimeTracking({ workspaceId, timeTrackingId })
    )
  )
  responseData.forEach((res, index) => {
    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_TYPE,
      match: { id: trackingIds[index] },
      replacement: res.data,
    })
  })
}

export const downloadExportedTrackings = (exportResponse: {
  attributes: {
    export_type: string
    file_url: string
  }
}) => {
  if (exportResponse.attributes.export_type === 'zip') {
    Snackbar.open(i18n.t('export.zip_success_message'))
  } else {
    Snackbar.open({
      message: i18n.t('export.download_link'),
      info: i18n.t('export.download_link_info', {
        link: exportResponse.attributes.file_url,
      }),
      indefinite: true,
      renderHtml: true,
    })

    const anchor = document.createElement('a')
    anchor.href = exportResponse.attributes.file_url

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }
}
