<template>
  <div
    class="flex items-center justify-between py-2.5 first:pt-0 last:pb-0 border-b border-coal-150 last:border-b-0"
  >
    <span class="font-medium mr-6 truncate">
      {{ title }}
    </span>

    <img :src="signature.image_url" :alt="signature.title" class="w-4 h-4" />
  </div>
</template>

<script>
import { formatTime } from '@/utils/date-time.utils'

export default {
  name: 'TableCellSignatureItem',
  props: {
    signature: {
      type: Object,
      required: true,
    },
  },
  computed: {
    signatureTime() {
      return formatTime(new Date(this.signature.created_at))
    },
    title() {
      switch (this.signature.title) {
        case 'start':
          return this.$t('time_tracking.index.body.signatures.start', {
            time: this.signatureTime,
          })
        case 'end_of_break':
          return this.$t('time_tracking.index.body.signatures.end_of_break', {
            time: this.signatureTime,
          })
        case 'start_of_break':
          return this.$t('time_tracking.index.body.signatures.start_of_break', {
            time: this.signatureTime,
          })
        case 'end':
          return this.$t('time_tracking.index.body.signatures.end', {
            time: this.signatureTime,
          })
        default:
          return ''
      }
    },
  },
}
</script>
