<template>
  <div
    class="md:h-auto md:hover:border-coal-60 md:hover:bg-coal-60 break-words group h-full flex flex-col justify-between p-2.5 border rounded cursor-pointer"
    :class="
      isCurrent
        ? 'border-coal-60 bg-coal-60'
        : 'border-coal-100 md:border-coal-100'
    "
    :data-id="`time_tracking.notes_modal.card_list.${currentNoteId}`"
    @click="cardClick($event)"
  >
    <div
      class="mb-1.5 font-semibold line-clamp-2 text-coal-550 md:text-coal"
      :class="{ 'text-coal': isCurrent }"
    >
      {{ currentNoteContent }}
    </div>
    <div
      class="flex gap-1 items-center justify-between font-semibold text-coal-550 md:text-sm md:group-hover:text-coal"
      :class="{ 'text-coal': isCurrent }"
    >
      <div class="whitespace-nowrap truncate">
        <base-icon
          v-if="source.attachments && source.attachments.length"
          icon="file-clip/file-clip-16"
          size="0.75rem"
          class="mb-px mr-1"
        />
        <span class="font-semibold" :class="{ 'leading-6': $isSmallDevice }">{{
          authorName
        }}</span>
      </div>

      <div
        v-if="date && !$isSmallDevice"
        :class="{
          'font-semibold flex-none text-right': true,
          'md:group-hover:hidden': isUserNote,
          hidden: isUserNote && isCurrent,
        }"
      >
        {{ date }}
      </div>

      <note-options
        v-if="isUserNote"
        :permissions="source.permissions"
        class="md:group-hover:block"
        :class="$isSmallDevice || isCurrent ? 'block' : 'hidden'"
        :position="!$isSmallDevice ? 'is-top-left' : 'is-bottom-left'"
        :data-id-prefix="`time_tracking.notes_modal.note_card_options.${currentNoteId}`"
        @edit="$emit('edit', source)"
        @delete="$emit('delete', source)"
      />
    </div>
  </div>
</template>

<script>
import { NOTE_TYPES } from '@/constants'
import { formatDate } from '@/utils/date-time.utils'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import NoteOptions from './TimeTrackingNotesNoteOptions'
import { unescapeNoteContent } from '@/utils/helpers'

export default {
  name: 'TimeTrackingNotesNoteCard',
  components: {
    BaseIcon,
    NoteOptions,
  },
  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
    currentNote: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentNoteContent() {
      return unescapeNoteContent(this.source)
    },
    currentNoteId() {
      return this.currentNote?.id || ''
    },
    isUserNote() {
      return this.source.type === NOTE_TYPES.USER
    },
    isCurrent() {
      return this.source.id === this.currentNote?.id
    },
    authorName() {
      if (this.source.type === NOTE_TYPES.SYSTEM) {
        return this.$t('time_tracking.notes_modal.system_note')
      }
      return this.source.author
    },
    date() {
      if (!this.source.created_at) {
        return null
      }
      return formatDate(new Date(this.source.created_at))
    },
  },
  methods: {
    cardClick(e) {
      if (e.target.classList.contains('context-menu')) {
        return
      }
      this.$emit('card-click', this.source)
    },
  },
}
</script>
