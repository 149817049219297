<template>
  <portal to="modal">
    <base-modal has-modal-card @close="closeModal()">
      <base-card-modal-form
        :loading="trackedAction.isLoading"
        :action-status="trackedAction"
        :title="title"
        :button-submit-text="$t('time_tracking.assign_tag_modal.button_submit')"
        @submit="$emit('submit')"
        @close="closeModal()"
      >
        <slot />
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'

export default {
  name: 'TimeTrackingAssignTagDesktop',
  components: {
    BaseModal,
    BaseCardModalForm,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    trackedAction: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
