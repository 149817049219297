<template>
  <span class="">
    <base-badge
      :type="statusData.class"
      size="is-medium"
      :title="statusData.label"
    >
    </base-badge>
  </span>
</template>

<script>
import { TIME_TRACKING_STATES } from '@/app/modules/time-tracking/constants'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge'

export default {
  name: 'TableRowCellConfirmation',
  components: { BaseBadge },
  props: {
    status: {
      type: String,
      required: true,
    },
    confirmed: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    fontWeightClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusData() {
      switch (this.status) {
        case TIME_TRACKING_STATES.RUNNING:
        case TIME_TRACKING_STATES.BREAKED:
        case TIME_TRACKING_STATES.PAUSED:
          return {
            label: this.$t('time_tracking.index.body.status.unconfirmed'),
            class: 'is-warning',
          }
        case TIME_TRACKING_STATES.FINISHED:
          if (this.confirmed) {
            return {
              label: this.$t('time_tracking.index.body.status.confirmed'),
              class: 'is-success',
            }
          }
          return {
            label: this.$t('time_tracking.index.body.status.unconfirmed'),
            class: 'is-warning',
          }
        default:
          return {
            label: '',
          }
      }
    },
  },
}
</script>
