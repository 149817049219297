export const TIME_TRACKING_MODULE = 'TimeTracking'
export const TIME_TRACKING_STATES = {
  RUNNING: 'running',
  PAUSED: 'paused',
  BREAKED: 'breaked',
  NOT_STARTED: 'not-started',
  FINISHED: 'finished',
}

export const TIME_TRACKING_TABS = {
  ALL: 'all',
  UNCONFIRMED: 'unconfirmed',
  CONFIRMED: 'confirmed',
  RUNNING: 'running',
}

export const TIME_TRACKING_ACTIONS = {
  CONFIRM_ALL: 'confirm-all',
}

export const TIME_TRACKING_CREATED_BY = {
  LOCATION_APP: 'location-app',
  ACTIONS: 'actions',
  SHIFT: 'shift',
  MERGE_HANDLER: 'merge-handler',
}
