<template>
  <div>
    <table-row-cell-break-duration-item
      v-for="br in breaks"
      :key="br.id"
      :break-data="br"
    />
  </div>
</template>

<script>
import TableRowCellBreakDurationItem from './TableRowCellBreakDurationItem'

export default {
  name: 'TableRowCellBreakTooltipContent',
  components: {
    TableRowCellBreakDurationItem,
  },
  props: {
    breaks: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
