<template>
  <div class="time-tracking-add">
    <portal v-if="!$isSmallDevice" to="modal">
      <base-modal has-modal-card data-id-prefix="time_tracking.add_modal">
        <base-card-modal-form
          v-slot="{ handleInputChange }"
          :title="$t('time_tracking.add_modal.title')"
          :button-submit-text="$t('time_tracking.add_modal.button_submit')"
          :action-status="$actions.createTimeTracking"
          :loading="$actions.createTimeTracking.isLoading"
          @submit="handleCreate()"
          @close="$emit('close')"
        >
          <time-tracking-form
            v-model="payload"
            :action-status="$actions.createTimeTracking"
            :is-admin="isAssumedRoleAdmin"
            :details="details"
            @updated="handleInputChange(true)"
          />
        </base-card-modal-form>
      </base-modal>
    </portal>
    <time-tracking-add-mobile
      v-else
      v-model="payload"
      :details="details"
      :action-status="$actions.createTimeTracking"
      :is-admin="isAssumedRoleAdmin"
      @close="$emit('close')"
      @submit="handleCreate()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TimeTrackingAddMobile from './time-tracking-add-mobile/TimeTrackingAddMobile'
import TimeTrackingForm from '../time-tracking-form/TimeTrackingForm'
import { TIME_TRACKING_ACTION_CREATE_TIME_TRACKING } from '../../store/actions/time-tracking.actions.names'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import {
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'TimeTrackingAdd',
  components: {
    TimeTrackingAddMobile,
    TimeTrackingForm,
    BaseCardModalForm,
    BaseModal,
  },
  trackedActions: {
    createTimeTracking: TIME_TRACKING_ACTION_CREATE_TIME_TRACKING,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payload: {
        tags: [],
        enforceExactBreakDuration: false,
        breaks: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
      user: USER_GETTER_USER,
    }),
    createdBy() {
      return this.isAssumedRoleAdmin ? 'admin' : 'employee'
    },
  },
  methods: {
    async handleCreate() {
      const params = {
        created_by: this.createdBy,
        ...this.payload,
      }

      if (!this.isAssumedRoleAdmin) {
        params.employee = this.user
      }

      await this.$store.dispatch(
        TIME_TRACKING_ACTION_CREATE_TIME_TRACKING,
        params
      )

      this.$emit('success')
      this.$emit('close')
    },
  },
}
</script>
