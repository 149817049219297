import { shortname } from '@/utils/store'
import { logError } from '@/utils/helpers'
import type { ActionContext, Commit } from 'vuex'
import { extractIncluded, extractRelationships } from '@/utils/jsonapi/utils'
import { ResourceLink } from '@/utils/jsonapi/types'
import { type TimeTrackingState } from '../time-tracking.state'
import {
  TIME_TRACKING_TYPE,
  ACTIVE_TIME_TRACKING_TYPE,
  TIME_TRACKING_ITEMS_PER_PAGE,
  TimeTracking,
  TimeTrackingRaw,
} from '../../models/time-tracking.model'
import {
  Break,
  BreakInclude,
  TIME_TRACKING_BREAK_TYPE,
} from '../../models/time-tracking-break.model'
import { AREA_PUBLIC_GETTER_AREA_LIST_SELECTED } from '@/app/core/area/store/getters/area.getters.names'
import * as actionNamesObj from './time-tracking.actions.names'
import { refetchTrackings } from './time-tracking.actions.utils'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import * as getterNamesObj from '../getters/time-tracking.getters.names'
import api from '../../network/time-tracking.api'
import store from '@/store'
import {
  TIME_TRACKING_STATES,
  TIME_TRACKING_TABS,
} from '@/app/modules/time-tracking/constants'

import { TAG_PUBLIC_ACTION_LIST_BY_ID } from '@/app/core/tag/store/actions/tag.actions.names'
import { AREA_PUBLIC_ACTION_LIST_BY_ID } from '@/app/core/area/store/actions/area.actions.names'
import { BaseResponseMeta } from '@/utils/api/base-response'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)
const getterNames = shortname(getterNamesObj)

export default {
  [actionNames.TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS]: async ({
    rootState,
    state,
    getters,
    commit,
    dispatch,
  }: ActionContext<TimeTrackingState, any>): Promise<void> => {
    commit(mutationNames.TIME_TRACKING_MUTATION_RESET_TIME_TRACKINGS_PAGE)

    const responseData = await api.listTimeTrackings({
      workspaceId: rootState.workspace.workspace.id,
      sorting: state.timeTrackingsSorting,
      filter: getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_FILTERS],
      pagination: {
        ...state.timeTrackingsPagination,
        page: 1,
      },
    })

    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META, {
      meta: responseData.meta,
    })

    if (responseData.data.length) {
      handleTagAndAreasRelationships(responseData.data)

      commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
        records: responseData.included,
      })

      commit(mutationNames.TIME_TRACKING_MUTATION_SET_RECORDS, {
        type: TIME_TRACKING_TYPE,
        records: responseData.data,
      })
      commit(mutationNames.TIME_TRACKING_MUTATION_INCREMENT_TIME_TRACKINGS_PAGE)
    } else {
      commit(mutationNames.TIME_TRACKING_MUTATION_CLEAR_RECORDS, {
        type: TIME_TRACKING_TYPE,
      })
    }
  },

  [actionNames.TIME_TRACKING_ACTION_LIST_APPEND_TIME_TRACKINGS]: async ({
    commit,
    dispatch,
    state,
    getters,
    rootState,
  }: ActionContext<TimeTrackingState, any>): Promise<void> => {
    const pagination = {
      ...state.timeTrackingsPagination,
      page: state.timeTrackingsPagination.page + 1,
    }

    if (
      state.timeTrackingCurrentMeta.total_pages > 0 &&
      pagination.page > state.timeTrackingCurrentMeta.total_pages
    ) {
      return
    }

    const responseData = await api.listTimeTrackings({
      workspaceId: rootState.workspace.workspace.id,
      pagination,
      sorting: state.timeTrackingsSorting,
      filter: getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_FILTERS],
    })

    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META, {
      meta: responseData.meta,
    })

    if (responseData.data.length) {
      handleTagAndAreasRelationships(responseData.data)

      commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
        records: responseData.included,
      })

      commit(mutationNames.TIME_TRACKING_MUTATION_APPEND_RECORDS, {
        type: TIME_TRACKING_TYPE,
        records: responseData.data,
      })
      commit(mutationNames.TIME_TRACKING_MUTATION_INCREMENT_TIME_TRACKINGS_PAGE)
    }
  },

  [actionNames.TIME_TRACKING_ACTION_UPDATE_META]: async (
    { rootState, getters, commit }: ActionContext<TimeTrackingState, any>,
    { meta }: { meta?: BaseResponseMeta } = {}
  ): Promise<void> => {
    if (!meta) {
      const responseData = await api.listTimeTrackings({
        workspaceId: rootState.workspace.workspace.id,
        pagination: { items: 1, page: 1 },
        include: '',
        filter: getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_FILTERS],
      })
      meta = responseData.meta
    }
    if (!meta) {
      logError('Missing meta data')
      return
    }
    meta.page_items = TIME_TRACKING_ITEMS_PER_PAGE
    meta.total_pages = Math.ceil(
      meta.total_count / TIME_TRACKING_ITEMS_PER_PAGE
    )

    commit(mutationNames.TIME_TRACKING_MUTATION_SET_META_TIME_TRACKINGS, meta)
  },

  [actionNames.TIME_TRACKING_ACTION_CLEAR_TIME_TRACKINGS]: ({
    commit,
  }: ActionContext<TimeTrackingState, any>) => {
    commit(mutationNames.TIME_TRACKING_MUTATION_CLEAR_RECORDS, {
      type: TIME_TRACKING_TYPE,
    })
    commit(mutationNames.TIME_TRACKING_MUTATION_RESET_TIME_TRACKINGS_PAGE)
  },

  [actionNames.TIME_TRACKING_ACTION_CREATE_TIME_TRACKING]: async (
    { rootState, dispatch }: ActionContext<TimeTrackingState, any>,
    timeTracking: TimeTracking
  ): Promise<void> => {
    const res = await api.createTimeTracking(
      rootState.workspace.workspace.id,
      timeTracking
    )
    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)

    return res
  },

  [actionNames.TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING]: async (
    { rootState, dispatch }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      timeTracking,
    }: { timeTrackingId: RecordId; timeTracking: TimeTracking }
  ) => {
    await api.updateTimeTracking(
      rootState.workspace.workspace.id,
      timeTrackingId,
      timeTracking
    )
    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)
    dispatch(actionNames.TIME_TRACKING_ACTION_GET_TIME_TRACKING, timeTrackingId)
  },

  [actionNames.TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING_START_AT]: async (
    { rootState, dispatch }: ActionContext<TimeTrackingState, any>,
    {
      actionId,
      timeTracking,
    }: { actionId: RecordId; timeTracking: TimeTracking }
  ) => {
    const response = await api.updateTimeTrackingStartAt(
      rootState.workspace.workspace.id,
      actionId,
      timeTracking
    )

    await dispatch(
      actionNames.TIME_TRACKING_ACTION_GET_TIME_TRACKING,
      response.data.id
    )
    await dispatch(actionNames.TIME_TRACKING_ACTION_SHOW_ACTIVE)
    //call the list to update the list of time trackings
    await dispatch(actionNames.TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS)
  },

  [actionNames.TIME_TRACKING_ACTION_GET_TIME_TRACKING]: async (
    { rootState, commit, dispatch }: ActionContext<TimeTrackingState, any>,
    timeTrackingId: RecordId
  ) => {
    const responseData = await api.getTimeTracking({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })

    const relationships = extractRelationships(
      [responseData.data],
      ['tags', 'area']
    )
    const tagIds = relationships.get('tags')
    const areaIds = relationships.get('area')

    if (tagIds?.size) {
      dispatch(TAG_PUBLIC_ACTION_LIST_BY_ID, [...tagIds], { root: true })
    }
    if (areaIds?.size) {
      dispatch(AREA_PUBLIC_ACTION_LIST_BY_ID, [...areaIds], {
        root: true,
      })
    }

    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
      records: responseData.included,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_TYPE,
      match: {
        id: timeTrackingId,
      },
      replacement: responseData.data,
    })
  },

  [actionNames.TIME_TRACKING_ACTION_CONFIRM_TIME_TRACKING]: async (
    {
      rootState,
      dispatch,
      commit,
      state,
    }: ActionContext<TimeTrackingState, any>,
    { timeTrackingId }: { timeTrackingId: RecordId }
  ) => {
    await api.confirmTimeTracking({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })

    const responseData = await api.getTimeTracking({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_TYPE,
      match: { id: timeTrackingId },
      replacement:
        state.timeTrackingTab === TIME_TRACKING_TABS.UNCONFIRMED
          ? {
              ...responseData.data,
              attributes: {
                ...responseData.data.attributes,
                _hideRecordFromTable: true,
              },
            }
          : responseData.data,
    })

    if (state.timeTrackingTab === TIME_TRACKING_TABS.UNCONFIRMED) {
      dispatch(
        actionNames.TIME_TRACKING_ACTION_UPDATE_DATA_AFTER_CONFIRM,
        timeTrackingId
      )
    } else {
      dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)
    }
  },

  [actionNames.TIME_TRACKING_ACTION_UPDATE_DATA_AFTER_CONFIRM]: (
    { state, commit, dispatch }: ActionContext<TimeTrackingState, any>,
    timeTrackingId: RecordId
  ) => {
    setTimeout(() => {
      dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)
      if (
        typeof state.timeTrackingAllMeta.total_unconfirmed !== 'undefined' &&
        typeof state.timeTrackingAllMeta.total_confirmed !== 'undefined'
      ) {
        commit(
          mutationNames.TIME_TRACKING_MUTATION_UPDATE_TABS_META_TIME_TRACKINGS,
          {
            ...state.timeTrackingAllMeta,
            total_unconfirmed: state.timeTrackingAllMeta.total_unconfirmed - 1,
            total_confirmed: state.timeTrackingAllMeta.total_confirmed + 1,
          }
        )
      }

      commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
        type: TIME_TRACKING_TYPE,
        match: { id: timeTrackingId },
        replacement: null,
      })
    }, 1300)
  },

  [actionNames.TIME_TRACKING_ACTION_DELETE_TIME_TRACKING]: async (
    { commit, dispatch, rootState }: ActionContext<TimeTrackingState, any>,
    timeTrackingId: RecordId
  ) => {
    await api.deleteTimeTracking({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })
    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_TYPE,
      match: { id: timeTrackingId },
      replacement: null,
    })
  },

  [actionNames.TIME_TRACKING_ACTION_SHOW_ACTIVE]: async ({
    commit,
    rootState,
  }: ActionContext<TimeTrackingState, any>) => {
    const workspaceId = rootState.workspace.workspace.id
    const response = await api.showActiveTimeTracking(workspaceId)

    commit(mutationNames.TIME_TRACKING_MUTATION_CLEAR_RECORDS, {
      type: ACTIVE_TIME_TRACKING_TYPE,
    })
    commit(mutationNames.TIME_TRACKING_MUTATION_CLEAR_ACTION_BREAKS)

    if (response.data.length) {
      commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
        records: response.included,
      })
      commit(mutationNames.TIME_TRACKING_MUTATION_ADD_RECORDS, {
        type: ACTIVE_TIME_TRACKING_TYPE,
        records: response.data,
      })
      // TODO:
      // active time-tracking created by "actions" does not have id in for their breaks field
      // and breaks object in relationships returns null
      // remove this mutation when time-tracking created by "actions" does not return null in breaks relationship
      const currentUserActiveTimeTracking: {
        attributes: {
          breaks: []
        }
      } = response.data.find(
        (item: { relationships: { user: { data: ResourceLink } } }) =>
          item.relationships.user.data.id === rootState.user.user.id
      )
      if (currentUserActiveTimeTracking?.attributes?.breaks?.length) {
        commit(
          mutationNames.TIME_TRACKING_MUTATION_SET_ACTION_BREAKS,
          currentUserActiveTimeTracking.attributes.breaks
        )
      }
    }
  },

  [actionNames.TIME_TRACKING_ACTION_START]: async ({
    commit,
    dispatch,
    rootState,
    rootGetters,
  }: ActionContext<TimeTrackingState, any>) => {
    const workspaceId = rootState.workspace.workspace.id
    const userId = rootState.user.user.id
    const areas = rootGetters[AREA_PUBLIC_GETTER_AREA_LIST_SELECTED]

    const response = await api.startTimeTracking(workspaceId, userId, areas)

    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
      records: response.included,
    })

    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)

    commit(mutationNames.TIME_TRACKING_MUTATION_PREPEND_RECORDS, {
      type: 'active_time_tracking',
      records: [response.data],
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_PREPEND_RECORDS, {
      type: TIME_TRACKING_TYPE,
      records: [response.data],
    })
  },

  [actionNames.TIME_TRACKING_ACTION_STOP_ACTIVE]: async ({
    commit,
    dispatch,
    rootState,
    getters,
  }: ActionContext<TimeTrackingState, any>) => {
    const workspaceId = rootState.workspace.workspace.id
    const activeTimeTracking =
      getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING]

    const response = await api.stopTimeTracking(
      workspaceId,
      activeTimeTracking.id
    )
    dispatch(actionNames.TIME_TRACKING_ACTION_UPDATE_META)

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: 'active_time_tracking',
      match: { id: activeTimeTracking.id },
      replacement: null,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_TYPE,
      match: { id: activeTimeTracking.id },
      replacement: response,
    })
  },

  [actionNames.TIME_TRACKING_ACTION_BREAK_ACTIVE]: async ({
    commit,
    rootState,
    getters,
  }: ActionContext<TimeTrackingState, any>) => {
    const workspaceId = rootState.workspace.workspace.id
    const activeTimeTracking =
      getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING]

    const response = await api.breakTimeTracking(
      workspaceId,
      activeTimeTracking.id
    )
    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_RECORDS, {
      type: TIME_TRACKING_BREAK_TYPE,
      records: [response.data],
    })

    const included: BreakInclude = extractIncluded(response.included)

    if (included.time_tracking?.length) {
      commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
        type: ACTIVE_TIME_TRACKING_TYPE,
        match: { id: activeTimeTracking.id },
        replacement: included.time_tracking[0],
      })

      commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
        type: TIME_TRACKING_TYPE,
        match: { id: activeTimeTracking.id },
        replacement: included.time_tracking[0],
      })
    }
  },

  [actionNames.TIME_TRACKING_ACTION_CONTINUE_ACTIVE]: async ({
    commit,
    rootState,
    getters,
  }: ActionContext<TimeTrackingState, any>) => {
    const workspaceId = rootState.workspace.workspace.id
    const activeTimeTracking =
      getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING]
    const breakId = activeTimeTracking.breaks.find(
      (brk: Break) => !brk.ends_at
    ).id

    const response = await api.continueTimeTracking(
      workspaceId,
      activeTimeTracking.id,
      breakId
    )

    commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
      type: TIME_TRACKING_BREAK_TYPE,
      match: { id: breakId },
      replacement: response.data,
    })
    const included: BreakInclude = extractIncluded(response.included)

    if (included.time_tracking?.length) {
      const updatedTimeTracking = included.time_tracking.reduce(
        (acc, tracking) => {
          if (tracking.id === activeTimeTracking.id) {
            acc = {
              ...tracking,
              attributes: {
                ...tracking.attributes,
                current_status: TIME_TRACKING_STATES.RUNNING,
              },
            }
          }

          return acc
        },
        {}
      )

      commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
        type: 'active_time_tracking',
        match: { id: activeTimeTracking.id },
        replacement: updatedTimeTracking,
      })

      commit(mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD, {
        type: TIME_TRACKING_TYPE,
        match: { id: activeTimeTracking.id },
        replacement: updatedTimeTracking,
      })
    }
  },

  [actionNames.TIME_TRACKING_ACTION_SHOW_NEW_FILTER_FORM]: async ({
    commit,
  }: {
    commit: Commit
  }) => {
    commit(mutationNames.TIME_TRACKING_MUTATION_SET_SHOW_NEW_FILTER_FORM, true)
  },

  [actionNames.TIME_TRACKING_ACTION_CONFIRM_LIST]: async (
    { rootState, commit }: ActionContext<TimeTrackingState, any>,
    {
      trackingIds,
      checkAll = false,
    }: { trackingIds: RecordId[]; checkAll: boolean }
  ) => {
    const workspaceId = rootState.workspace.workspace.id
    await api.batchConfirmTimeTrackings(workspaceId, trackingIds, checkAll)

    // TODO: consider changing data locally, instead of making network requests
    await refetchTrackings({ commit }, { workspaceId, trackingIds })
  },

  [actionNames.TIME_TRACKING_ACTION_DELETE_LIST]: async (
    { rootState, commit }: ActionContext<TimeTrackingState, any>,
    { trackingIds }: { trackingIds: RecordId[] }
  ) => {
    const workspaceId = rootState.workspace.workspace.id
    await api.batchDeleteTimeTrackings(workspaceId, trackingIds)

    commit(mutationNames.TIME_TRACKING_MUTATION_DELETE_RECORDS, {
      type: TIME_TRACKING_TYPE,
      matches: trackingIds.map((id) => ({ id })),
    })
  },

  [actionNames.TIME_TRACKING_ACTION_BATCH_ASSIGN_TAGS]: async (
    { rootState, commit }: ActionContext<TimeTrackingState, any>,
    {
      trackingIds,
      tagId,
      overrideTags,
    }: { trackingIds: RecordId[]; tagId: RecordId; overrideTags: boolean }
  ) => {
    const workspaceId = rootState.workspace.workspace.id
    await api.batchAssignTags({ workspaceId, trackingIds, tagId, overrideTags })

    // TODO: consider changing data locally, instead of making network requests
    await refetchTrackings({ commit }, { workspaceId, trackingIds })
  },

  [actionNames.TIME_TRACKING_ACTION_BATCH_ADD_NOTE]: async (
    { rootState, commit }: ActionContext<TimeTrackingState, any>,
    {
      trackingIds,
      noteContent,
      attachments,
    }: { trackingIds: RecordId[]; noteContent: string; attachments: [] }
  ) => {
    const workspaceId = rootState.workspace.workspace.id

    await api.batchAddNote(workspaceId, trackingIds, noteContent, attachments)

    // TODO: consider changing data locally, instead of making network requests
    await refetchTrackings({ commit }, { workspaceId, trackingIds })
  },
}

function handleTagAndAreasRelationships(data: TimeTrackingRaw[]) {
  const relationships = extractRelationships(data, ['areas', 'tags'])

  const tagIds = relationships.get('tags')
  const areaIds = relationships.get('area')

  if (tagIds?.size) {
    store.dispatch(TAG_PUBLIC_ACTION_LIST_BY_ID, [...tagIds], { root: true })
  }
  if (areaIds?.size) {
    store.dispatch(AREA_PUBLIC_ACTION_LIST_BY_ID, [...areaIds], {
      root: true,
    })
  }
}
