<template>
  <portal to="modal">
    <base-modal @close="closeModal()">
      <base-card-modal-form :title="title" has-modal-card @close="closeModal()">
        <slot />
        <template v-slot:footer> <div /> </template>
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'

export default {
  name: 'TimeTrackingExportDesktop',
  components: {
    BaseModal,
    BaseCardModalForm,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
