import { ref, watch } from '@vue/composition-api'
import realStore from '@/store'
import { TIME_TRACKING_NOTE_ACTION_ADD } from '../../../store/actions/time-tracking.actions.names'

export default function useNewNote(
  timeTrackingId,
  currentNote,
  attachments,
  clearAttachments,
  switchToNote,
  fetchNotes
) {
  const store =
    process.env.VUE_APP_MODE === 'test' ? this._storeMock : realStore
  const newNote = ref(null)

  const addNewNoteCard = () => {
    newNote.value = {
      content: '',
      author: store.state.user.user.attributes.username,
    }
    switchToNote(null)
  }

  const submitNewNote = async () => {
    const note = await store.dispatch(TIME_TRACKING_NOTE_ACTION_ADD, {
      timeTrackingId: timeTrackingId.value,
      content: newNote.value.content,
      attachments: [...attachments.value],
    })
    await fetchNotes()
    newNote.value = null
    clearAttachments()
    switchToNote(note)
  }

  watch(currentNote, () => {
    if (currentNote.value?.id !== newNote.value?.id) {
      newNote.value = null
    }
  })

  return {
    newNote,
    addNewNoteCard,
    submitNewNote,
  }
}
