import { shortname } from '@/utils/store'
import {
  TIME_TRACKING_STATES,
  TIME_TRACKING_TABS,
} from '@/app/modules/time-tracking/constants'
import { TAG_PUBLIC_GETTER_TAG_LIST_SELECTED } from '@/app/core/tag/store/getters/tag.getters.names'
import { USER_GETTER_USER } from '@/app/core/user/store/getters/user.getters.names'
import { AREA_PUBLIC_GETTER_AREA_LIST_SELECTED } from '@/app/core/area/store/getters/area.getters.names'
import * as getterNamesObj from './time-tracking.getters.names'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.TIME_TRACKING_GETTER_ACTIVE_FILTERS]: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    let activeFilters = {}

    const tab = state.timeTrackingTab
    const dateRange = state.timeTrackingFilterDateRange
    const filterbarFilters = state.timeTrackingFilterbarFilters
    const filterbarFiltersValues = state.timeTrackingFilterbarValues

    const tags = rootGetters[TAG_PUBLIC_GETTER_TAG_LIST_SELECTED]
    const areas = rootGetters[AREA_PUBLIC_GETTER_AREA_LIST_SELECTED]

    if (tags.length) {
      activeFilters.tags = tags
    }
    if (areas.length) {
      activeFilters.areas = areas
    }

    if (tab !== TIME_TRACKING_TABS.ALL) {
      if (tab === TIME_TRACKING_TABS.UNCONFIRMED) {
        activeFilters.confirmed = 'eq:false'
      } else if (tab === TIME_TRACKING_TABS.CONFIRMED) {
        activeFilters.confirmed = 'eq:true'
      } else {
        activeFilters.running = '1'
      }
    }

    if (Array.isArray(dateRange)) {
      activeFilters.starts_at = dateRange
    }

    filterbarFilters.forEach((filter) => {
      activeFilters = {
        ...activeFilters,
        ...filterbarFiltersValues[filter].filter,
      }
    })

    return activeFilters
  },

  [getterNames.TIME_TRACKING_GETTER_TRACKINGS_TOTALS]: (state) => {
    const currentMeta = state.timeTrackingCurrentMeta

    return {
      timeTrackings: currentMeta.total_count,
      gross: currentMeta.total_gross_time,
      break: currentMeta.total_break_time,
      net: currentMeta.total_net_time,
    }
  },

  [getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING]: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return (
      state.records.active_time_tracking.find(
        (item) => item.user.id === rootGetters[USER_GETTER_USER].id
      ) || null
    )
  },
  [getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING_STATUS]: (
    state,
    getters
  ) => {
    const activeTimeTracking =
      getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_TIME_TRACKING]

    if (!activeTimeTracking) {
      return TIME_TRACKING_STATES.NOT_STARTED
    }

    return activeTimeTracking.current_status
  },
  [getterNames.TIME_TRACKING_GETTER_GET_BY_ID]: (state) => (id) => {
    return state.records.time_tracking.find((item) => item.id === id)
  },
}
