<template>
  <portal to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-title="$t('time_tracking.add_modal.title_mobile')"
      :action-status="actionStatus"
      validation-messages-class="mx-4 mt-4 mb-2"
      :header-right-actions="bottomSheetActions"
      @done="$emit('submit')"
      @close="$emit('close')"
    >
      <time-tracking-form
        v-model="passedValue"
        :action-status="actionStatus"
        :details="details"
        :is-admin="isAdmin"
        class="px-4 pt-4 pb-6"
      />
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'
import TimeTrackingForm from '../../time-tracking-form/TimeTrackingForm'

export default {
  name: 'TimeTrackingAddMobile',
  components: {
    TimeTrackingForm,
    BottomSheetFormWrapper,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    bottomSheetActions() {
      return [
        {
          title: this.$t('time_tracking.add_modal.button_submit_mobile'),
          loading: this.actionStatus.isLoading,
          class: 'justify-self-end text-green  font-semibold text-lg',
          event: 'create',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.time-tracking-add {
  .base-card-modal {
    @apply overflow-visible;
  }
}
</style>
