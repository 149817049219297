import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import * as actionNamesObj from './time-tracking.actions.names'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import api from '../../network/time-tracking.api'
import { NOTE_ATTACHMENT_TYPE } from '../../models/time-tracking-attachment.model'
import { TimeTrackingState } from '../time-tracking.state'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.TIME_TRACKING_ATTACHMENT_ACTION_LIST]: async (
    { commit, rootState }: ActionContext<TimeTrackingState, any>,
    { timeTrackingId }: { timeTrackingId: RecordId }
  ): Promise<void> => {
    const responseData = await api.listTimeTrackingAttachments({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
    })
    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_RECORDS, {
      type: NOTE_ATTACHMENT_TYPE,
      records: responseData.data,
    })
  },
}
