import BaseRequest from '@/utils/api/base-request'
import { TIME_TRACKING_ENDPOINT_LIST_SIGNATURES } from '../network/time-tracking.endpoints'

export const TIME_TRACKING_SIGNATURE_TYPE = 'signature'

export interface Signature {
  id?: RecordId
  title: string
  image_file_name: string
  image_url: string
  created_at: Date
}

export interface SignatureListRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
}

export class TimeTrackingSignatureList extends BaseRequest {
  constructor({ workspaceId, timeTrackingId }: SignatureListRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_LIST_SIGNATURES(
      workspaceId,
      timeTrackingId
    )
    super.type = TIME_TRACKING_SIGNATURE_TYPE
  }
}
