<template>
  <div>
    <time-tracking-header
      v-if="!$isSmallDevice"
      :can-create-tracking="canCreateTracking"
      :selected-item-count="checkedTimeTrackings.length"
      @exportTrackings="toggleExportsModal(true)"
      @assignTags="isAssignTagsModalOpen = true"
      @addTrackings="isAddTrackingsModalOpen = true"
      @confirm-all="confirmAllTimeTrackings()"
    />

    <time-tracking-header-mobile
      v-else
      :can-create-tracking="canCreateTracking"
      :selected-items="checkedTimeTrackings"
      @exportTrackings="toggleExportsModal(true)"
      @confirmTimeTrackings="confirmTimeTrackings($event)"
      @confirm-all="confirmAllTimeTrackings()"
      @assignTags="isAssignTagsModalOpen = true"
      @addTrackings="isAddTrackingsModalOpen = true"
      @addNote="isAddNoteModalOpen = true"
    />

    <base-table
      ref="baseTable"
      class="time-tracking-table md:px-6 pb-10"
      :columns="displayedColumns"
      :rows="timeTrackings"
      :page-mode="true"
      :buffer-size="bufferSize"
      :row-height="rowHeight"
      :disabled-rows-ids="loadingRowsIds"
      :is-loading="
        $actions.fetchTrackings.isLoading || $actions.appendTrackings.isLoading
      "
      :no-rows-message="$t('time_tracking.index.no_matching_tracking')"
      @row-click="rowClick($event)"
      @scroll="onScroll()"
    />

    <mobile-table-row-details
      v-if="mobileRow.id"
      :details="mobileRow"
      :actions-statuses="mobileActionStatuses"
      @confirm-action-click="confirmTrackingOnMobile()"
      @attachments-action-click="showAttachments(mobileRow)"
      @signatures-action-click="showSignatures(mobileRow)"
      @notes-action-click="showNotes(mobileRow)"
      @edit-action-click="editingTimeTrackingId = $event"
      @close="mobileRowIndex = -1"
    />

    <base-group-selection-toolbar
      v-if="showDesktopSelectionToolbar"
      :selected-items="checkedTimeTrackings"
      :total-items-number="rowCount"
      @deselect="setAllTimeTrackingChecked(false)"
      @addNote="isAddNoteModalOpen = true"
      @confirmTimeTrackings="confirmTimeTrackings($event)"
      @exportRows="toggleExportsModal(true)"
      @assignTags="isAssignTagsModalOpen = true"
    />

    <time-tracking-note-add
      v-if="isAddNoteModalOpen"
      :selected-items="checkedTimeTrackings"
      @closeModal="isAddNoteModalOpen = false"
    />

    <time-tracking-export
      v-if="isExportModalOpen"
      :rows="checkedTimeTrackings"
      :custom-rows="timeTrackings"
      :total="timeTrackingsTotals.timeTrackings"
      @closeModal="toggleExportsModal(false)"
    />

    <time-tracking-assign-tag
      v-if="isAssignTagsModalOpen"
      :rows="checkedTimeTrackings"
      @closeModal="isAssignTagsModalOpen = false"
    />

    <time-tracking-add
      v-if="isAddTrackingsModalOpen"
      :details="mobileRow"
      @success="fetchTimeTrackings()"
      @close="isAddTrackingsModalOpen = false"
    />

    <time-tracking-edit
      v-if="editingTimeTrackingId"
      :time-tracking-id="editingTimeTrackingId"
      :details="selectedRow"
      @close="closeTimeTrackingEditView()"
    />

    <time-tracking-notes
      v-if="notesTimeTrackingIndex !== -1"
      :time-tracking="timeTrackings[notesTimeTrackingIndex]"
      :action-status="$actions.fetchTrackingNotes"
      @close="notesTimeTrackingIndex = -1"
    />

    <time-tracking-signatures
      v-if="signaturesTimeTracking"
      :time-tracking="signaturesTimeTracking"
      :action-status="$actions.fetchTrackingSignatures"
      @close="signaturesTimeTracking = null"
    />

    <time-tracking-attachments
      v-if="attachmentsTimeTracking"
      :time-tracking="attachmentsTimeTracking"
      :action-status="$actions.fetchTrackingAttachments"
      @close="attachmentsTimeTracking = null"
    />

    <time-tracking-confirm-dialog
      v-if="batchConfirmDialog"
      :unconfirmed-trackings="batchConfirmDialog.unconfirmedTrackings"
      :confirmable-trackings="batchConfirmDialog.confirmableTrackings"
      @done="approveTimeTrackingsConfirm($event)"
      @close="batchConfirmDialog = null"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import emitter from '@/app/util-modules/emitter'
import {
  formatDate,
  getDurationBasedOnUserSetting,
  formatTimeCell,
  formatViaRosterTimeCell,
} from '@/utils/date-time.utils'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../time-tracking.module'
import {
  NOTES_MODAL_ITEMS_PER_PAGE,
  NOTES_BOTTOM_SHEET_ITEMS_PER_PAGE,
} from '../models/time-tracking-note.model'
import {
  TIME_TRACKING_GETTER_TRACKINGS_TOTALS,
  TIME_TRACKING_GETTER_ACTIVE_FILTERS,
} from '../store/getters/time-tracking.getters.names'
import {
  TIME_TRACKING_ACTION_LIST_APPEND_TIME_TRACKINGS,
  TIME_TRACKING_ACTION_CLEAR_TIME_TRACKINGS,
  TIME_TRACKING_ACTION_CONFIRM_TIME_TRACKING,
  TIME_TRACKING_ACTION_DELETE_LIST,
  TIME_TRACKING_NOTE_ACTION_LIST,
  TIME_TRACKING_FOLLOWER_ACTION_LIST,
  TIME_TRACKING_ATTACHMENT_ACTION_LIST,
  TIME_TRACKING_SIGNATURE_ACTION_LIST,
  TIME_TRACKING_ACTION_CONFIRM_LIST,
  TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS,
} from '../store/actions/time-tracking.actions.names'
import {
  TIME_TRACKING_MUTATION_SET_TIME_TRACKING_CHECKED,
  TIME_TRACKING_MUTATION_SET_ALL_TIME_TRACKINGS_CHECKED,
  TIME_TRACKING_MUTATION_SET_TIME_TRACKING_SORTING_FIELD,
} from '../store/mutations/time-tracking.mutations.names'
import BaseTable from '@/app/util-modules/ui/table/BaseTable'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox'
import BaseGroupSelectionToolbar from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbar'
import TableHeaderCellDefault from '@/app/util-modules/ui/table/BaseTableHeaderCell'
import TimeTrackingHeader from '../components/time-tracking-header/TimeTrackingHeader.vue'
import TimeTrackingHeaderMobile from '../components/time-tracking-header/TimeTrackingHeaderMobile'
import TableHeaderActions from '../components/time-tracking-table/table-header-actions/TableHeaderActions'
import TableRowCellDefault from '../components/time-tracking-table/table-row-cell-default/TableRowCellDefault'
import TableRowCellEmployee from '../components/time-tracking-table/table-row-cell-employee/TableRowCellEmployee'
import TableRowCellStatus from '../components/time-tracking-table/table-row-cell-status/TableRowCellStatus'
import TableRowCellConfirmation from '../components/time-tracking-table/table-row-cell-confirmation/TableRowCellConfirmation'
import TableRowCellNetTime from '../components/time-tracking-table/table-row-cell-net-time/TableRowCellNetTime.vue'
import TableRowCellInfo from '../components/time-tracking-table/table-row-cell-info/TableRowCellInfo'
import TableRowCellActions from '../components/time-tracking-table/table-row-cell-actions/TableRowCellActions'
import TableRowCellTags from '../components/time-tracking-table/table-row-cell-tags/TableRowCellTags'
import TableRowCellBreak from '../components/time-tracking-table/table-row-cell-break/TableRowCellBreak'
import {
  TIME_TRACKING_CREATED_BY,
  TIME_TRACKING_STATES,
} from '@/app/modules/time-tracking/constants'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

const TimeTrackingNoteAdd = () =>
  import('../components/time-tracking-note-add/TimeTrackingNoteAdd')
const TimeTrackingEdit = () =>
  import('../components/time-tracking-edit/TimeTrackingEdit')
const TimeTrackingExport = () =>
  import('../components/time-tracking-export/TimeTrackingExport')
const TimeTrackingAssignTag = () =>
  import('../components/time-tracking-assign-tag/TimeTrackingAssignTag')
const TimeTrackingAdd = () =>
  import('../components/time-tracking-add/TimeTrackingAdd')
const TimeTrackingNotes = () =>
  import('../components/time-tracking-notes/TimeTrackingNotes')
const TimeTrackingSignatures = () =>
  import('../components/time-tracking-signatures/TimeTrackingSignatures')
const TimeTrackingAttachments = () =>
  import('../components/time-tracking-attachments/TimeTrackingAttachments')
const MobileTableRowDetails = () =>
  import(
    '../components/time-tracking-table/mobile-table-row-details/MobileTableRowDetails'
  )
const TimeTrackingConfirmDialog = () =>
  import('../components/time-tracking-confirm-dialog/TimeTrackingConfirmDialog')

const formatDateCell = ({ starts_at }) => {
  return formatDate(new Date(starts_at))
}

export default {
  name: 'PageTimeTracking',
  components: {
    TimeTrackingNotes,
    TimeTrackingNoteAdd,
    TimeTrackingAttachments,
    TimeTrackingSignatures,
    TimeTrackingExport,
    TimeTrackingAssignTag,
    TimeTrackingAdd,
    TimeTrackingEdit,
    TimeTrackingHeader,
    TimeTrackingHeaderMobile,
    BaseGroupSelectionToolbar,
    BaseTable,
    MobileTableRowDetails,
    TimeTrackingConfirmDialog,
  },
  trackedActions: {
    appendTrackings: TIME_TRACKING_ACTION_LIST_APPEND_TIME_TRACKINGS,
    fetchTrackings: TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS,
    fetchTrackingNotes: TIME_TRACKING_NOTE_ACTION_LIST,
    fetchTrackingAttachments: TIME_TRACKING_ATTACHMENT_ACTION_LIST,
    fetchTrackingSignatures: TIME_TRACKING_SIGNATURE_ACTION_LIST,
    batchDeleteTrackings: {
      action: TIME_TRACKING_ACTION_DELETE_LIST,
      includePayload: true,
    },
    batchConfirmTrackings: {
      action: TIME_TRACKING_ACTION_CONFIRM_LIST,
      includePayload: true,
    },
    confirmTracking: {
      action: TIME_TRACKING_ACTION_CONFIRM_TIME_TRACKING,
      // trackByKey allows tracking each request grouped by payload key
      trackByKey: 'timeTrackingId',
    },
  },
  data() {
    return {
      rowHeight: 64, // pixels
      prevScrollOffset: 0,
      mobileRowIndex: -1,
      isScrolling: false,
      scrollTimer: null,
      isAddNoteModalOpen: false,
      isExportModalOpen: false,
      isAssignTagsModalOpen: false,
      isAddTrackingsModalOpen: false,

      // we use index for notes,
      // instead of object ref, because
      // replacing the object in array does not
      // trigger changes for this object, but triggers
      // for the array
      notesTimeTrackingIndex: -1,

      signaturesTimeTracking: null,
      attachmentsTimeTracking: null,
      editingTimeTrackingId: null,
      batchConfirmDialog: null,
      selectedRow: null,
    }
  },
  computed: {
    columns() {
      return [
        {
          header: {
            class: 'table-cell-checkbox',
            element: BaseCheckbox,
            props: {
              value: () => this.isAllChecked,
              size: () => (this.$isSmallDevice ? null : 'is-small'),
              indeterminate: () =>
                this.checkedTimeTrackings.length > 0 && !this.isAllChecked,
              stopPropagation: true,
              dataIdAttr: 'time_tracking_table_select_all',
            },
            events: {
              input: this.setAllTimeTrackingChecked,
            },
          },
          cell: {
            class: 'table-cell-checkbox',
            element: BaseCheckbox,
            props: {
              value: (row) => row.checked,
              size: () => (this.$isSmallDevice ? null : 'is-small'),
              stopPropagation: true,
              dataIdAttr: (row) =>
                'time_tracking_table_select_single_' + row.id,
            },
            events: {
              input: (row, checked) =>
                this.setTimeTrackingChecked({
                  id: row.id,
                  checked,
                }),
            },
          },
          mobile: this.isAdmin,
          tablet: true,
          hidden: false,
        },
        {
          header: {
            class: () => this.employeeCellClass,
            element: TableHeaderCellDefault,
            props: {
              value: () =>
                this.$t('time_tracking.index.body.row_cells.employee'),
              dataIdAttr: 'time_tracking.index.body.row_cells.employee',
            },
          },
          cell: {
            class: () => `employee-cell-class ${this.employeeCellClass}`,
            element: TableRowCellEmployee,
            props: {
              label: (row) => row.user.username,
              isScrolling: () => this.isScrolling,
              user: (row) => row.user,
            },
          },
          footer: () => this.totalNumbOfTrackings,
          footerClass: 'whitespace-nowrap table-footer-total-trackings',
          mobile: true,
          tablet: true,
          hidden: !this.isAdmin,
        },
        {
          width: () => (this.$isMediumDevice ? '16%' : '8%'),
          header: {
            class: () => `${this.distanceBetweenCells} justify-center flex-1`,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.status'),
              dataIdAttr: 'time_tracking.index.body.row_cells.status',
            },
          },
          cell: {
            class: () => `${this.distanceBetweenCells} flex-1`,
            element: TableRowCellStatus,
            props: {
              status: (row) => row.current_status,
            },
          },
          footer: () => (this.isAdmin ? '' : this.totalNumbOfTrackings),
          footerClass:
            'whitespace-nowrap table-footer-total-trackings status-cell',
          mobile: true,
          tablet: true,
        },
        {
          width: () => (this.$isMediumDevice ? '12%' : '8%'),
          header: {
            class: () => this.distanceBetweenCells,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.areas'),
              dataIdAttr: 'time_tracking.index.body.row_cells.areas',
            },
          },
          cell: {
            class: () => `${this.distanceBetweenCells} line-clamp-2`,
            element: TableRowCellDefault,
            props: {
              value: (row) => row.area?.name || '--',
            },
          },
          mobile: false,
          tablet: false,
          hidden: false,
        },
        {
          width: '8%',
          header: {
            class: () => this.distanceBetweenCells,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.tags'),
              dataIAttrd: 'time_tracking.index.body.row_cells.tags',
            },
          },
          cell: {
            class: () => this.distanceBetweenCells,
            element: TableRowCellTags,
            props: {
              tags: (row) => row.tags.map((t) => t.title),
            },
          },
          hidden: false,
        },
        {
          width: () => this.dateCellWidth,
          header: {
            class: () => `${this.distanceBetweenCells} md:flex-1.5`,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.date'),
              dataIdAttr: 'time_tracking.index.body.row_cells.date',
              sorting: () => this.sorting.starts_at,
            },
            events: {
              sort: (direction) => this.sortByField('starts_at', direction),
            },
          },
          cell: {
            class: () => `${this.distanceBetweenCells} md:flex-1.5`,
            element: TableRowCellDefault,
            props: {
              value: (row) => formatDateCell(row),
            },
          },
          mobile: !this.isAdmin,
          tablet: true,
          hidden: false,
        },
        {
          width: () => (this.$isSmallDevice ? '100px' : '8%'),
          header: {
            class: () => `${this.distanceBetweenCells} md:flex-1.5`,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.time'),
              dataIdAttr: 'time_tracking.index.body.row_cells.time',
            },
          },
          cell: {
            class: () => `${this.distanceBetweenCells} md:flex-1.5`,
            element: TableRowCellDefault,
            props: {
              value: (row) =>
                row.current_status === TIME_TRACKING_STATES.RUNNING &&
                (row.created_by === TIME_TRACKING_CREATED_BY.SHIFT ||
                  row.created_by === TIME_TRACKING_CREATED_BY.MERGE_HANDLER)
                  ? formatViaRosterTimeCell(row)
                  : formatTimeCell(row),
            },
          },
          mobile: !this.isAdmin,
          tablet: true,
          hidden: false,
        },
        {
          width: '5%',
          header: {
            class: () => `${this.distanceBetweenCells} md:justify-end`,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.gross'),
              sorting: () => this.sorting.gross_time,
            },
            events: {
              sort: (direction) => this.sortByField('gross_time', direction),
            },
          },
          cell: {
            class: () => `${this.distanceBetweenCells} text-right`,
            element: TableRowCellDefault,
            props: {
              value: (row) =>
                getDurationBasedOnUserSetting(
                  row.gross_time,
                  this.currentUser.attributes.timeFormat
                ),
            },
          },
          footer: () =>
            getDurationBasedOnUserSetting(
              this.timeTrackingsTotals.gross,
              this.currentUser.attributes.timeFormat
            ),
          mobile: false,
          tablet: true,
          hidden: false,
        },
        {
          width: '5%',
          header: {
            class: () =>
              `${this.distanceBetweenCells} justify-end md:justify-end flex-1`,
            element: TableHeaderCellDefault,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.break'),
              dataIdAttr: 'time_tracking.index.body.row_cells.break',
              sorting: () => this.sorting.break_time,
            },
            events: {
              sort: (direction) => this.sortByField('break_time', direction),
            },
          },
          cell: {
            class: () =>
              `${this.distanceBetweenCells} md:justify-end text-right flex-1 items-end md:items-center`,
            element: TableRowCellBreak,
            props: {
              breaksSum: (row) => row.break_time,
              breaks: (row) => row.breaks,
            },
          },
          footer: () =>
            getDurationBasedOnUserSetting(
              this.timeTrackingsTotals.break,
              this.currentUser.attributes.timeFormat
            ),
          footerClass: 'total-break-cell',
          mobile: !this.isAdmin,
          tablet: true,
          hidden: false,
        },
        {
          header: {
            class: () => `${this.netTimeCellClass} table-cell-net-time`,
            element: TableHeaderCellDefault,
            props: {
              value: () =>
                this.$isSmallDevice
                  ? this.$t('time_tracking.index.body.row_cells.net_time')
                  : this.$t('time_tracking.index.body.row_cells.net'),
              dataIdAttr: 'time_tracking.index.body.row_cells.net',
              sorting: () => this.sorting.net_time,
            },
            events: {
              sort: (direction) => this.sortByField('net_time', direction),
            },
          },
          cell: {
            class: () => `${this.netTimeCellClass} table-cell-net-time`,
            element: TableRowCellNetTime,
            props: {
              row: (row) => row,
            },
          },
          footer: () =>
            getDurationBasedOnUserSetting(
              this.timeTrackingsTotals.net,
              this.currentUser.attributes.timeFormat
            ),
          mobile: true,
          tablet: true,
          hidden: false,
        },
        {
          width: () => this.infoCellWidth,
          header: {
            class: 'mr-5',
            element: TableHeaderActions,
            props: {
              value: () => this.$t('time_tracking.index.body.row_cells.info'),
              dataIdAttr: 'time_tracking.index.body.row_cells.info',
            },
          },
          cell: {
            class: 'mr-5',
            element: TableRowCellInfo,
            props: {
              row: (row) => row,
              actionsStatuses: () => ({
                notes: this.$actions.fetchTrackingNotes,
                attachments: this.$actions.fetchTrackingAttachments,
                signatures: this.$actions.fetchTrackingSignatures,
              }),
            },
            events: {
              'notes-hover': (row) => {
                if (!row.notes.length) {
                  this.fetchTimeTrackingNotes({
                    timeTrackingId: row.id,
                    type: 'eq:UserNote',
                    pagination: { page: 1, items: 1 },
                  })
                }
              },
              'attachments-hover': (row) => {
                if (!row.attachments.length) {
                  this.fetchTimeTrackingAttachments({ timeTrackingId: row.id })
                }
              },
              'signatures-hover': (row) => {
                if (!row.signatures.length) {
                  this.fetchTimeTrackingSignatures({ timeTrackingId: row.id })
                }
              },
              'notes-action-click': this.showNotes,
              'signatures-action-click': this.showSignatures,
              'attachments-action-click': this.showAttachments,
            },
          },
          mobile: false,
          tablet: false,
          hidden: false,
        },
        {
          width: () => this.statusCellWidth,
          header: {
            class: () => this.statusCellClass,
            element: TableHeaderCellDefault,
            props: {
              value: () =>
                this.$t('time_tracking.index.body.row_cells.confirmation'),
              dataIdAttr: 'time_tracking.index.body.row_cells.confirmation',
            },
          },
          cell: {
            class: () => this.statusCellClass,
            element: TableRowCellConfirmation,
            props: {
              status: (row) => row.current_status,
              confirmed: (row) => row.confirmed,
            },
          },
          mobile: this.isAdmin,
          tablet: false,
          hidden: false,
        },
        {
          width: () => (this.$isMediumDevice ? '72px' : '108px'),
          header: {
            element: TableHeaderActions,
            props: {
              value: () =>
                this.$t('time_tracking.index.body.row_cells.actions'),
              dataIdAttr: 'time_tracking.index.body.row_cells.actions',
            },
          },
          cell: {
            element: TableRowCellActions,
            props: {
              row: (row) => {
                return Object.assign(row, {
                  dateValue: formatDateCell(row),
                  timeValue: formatTimeCell(row),
                })
              },
            },
            events: {
              'confirm-action-click': (row) => {
                this.confirmTimeTracking({ timeTrackingId: row.id })
              },
              'notes-action-click': this.showNotes,
              'edit-action-click': (row) => {
                this.editingTimeTrackingId = row.id
                this.selectedRow = row
              },
            },
          },
          mobile: false,
          tablet: false,
          hidden: false,
        },
      ]
    },
    loadingRowsIds() {
      const confirmingIds = Object.keys(
        this.$actions.confirmTracking.instances || {}
      ).filter((id) => this.$actions.confirmTracking.instances[id].isLoading)

      let batchConfirmingIds = []
      let batchDeletingIds = []

      if (this.$actions.batchConfirmTrackings.isLoading) {
        batchConfirmingIds =
          this.$actions.batchConfirmTrackings.payload.trackingIds
      }
      if (this.$actions.batchDeleteTrackings.isLoading) {
        batchDeletingIds =
          this.$actions.batchDeleteTrackings.payload.trackingIds
      }

      return [...confirmingIds, ...batchConfirmingIds, ...batchDeletingIds]
    },
    ...mapState(TIME_TRACKING_MODULE_NAME, {
      bufferSize: (state) => state.timeTrackingsPagination.items,
      timeTrackings: (state) => state.records.time_tracking,
      pendingExportIds: (state) => state.pendingExportIds,
      sorting: (state) => state.timeTrackingsSorting,
    }),
    ...mapState({
      currentUser: ({ user }) => user.user,
    }),
    ...mapGetters({
      timeTrackingsTotals: TIME_TRACKING_GETTER_TRACKINGS_TOTALS,
      filters: TIME_TRACKING_GETTER_ACTIVE_FILTERS,
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    canCreateTracking() {
      return (
        this.isAdmin || this.currentUser.attributes.working_sessions_creator
      )
    },
    rowCount() {
      return this.timeTrackings.length
    },
    checkedTimeTrackings() {
      return this.timeTrackings.filter((item) => item.checked)
    },
    isAllChecked() {
      return (
        this.rowCount > 0 && this.checkedTimeTrackings.length === this.rowCount
      )
    },
    showDesktopSelectionToolbar() {
      return this.checkedTimeTrackings.length >= 1 && !this.$isSmallDevice
    },
    displayedColumns() {
      return this.$isSmallDevice
        ? this.columns.filter((c) => c.mobile && !c.hidden)
        : this.$isMediumDevice
        ? this.columns.filter((c) => c.tablet && !c.hidden)
        : this.columns.filter((c) => !c.hidden)
    },
    employeeCellClass() {
      return `${this.distanceBetweenCells} table-cell-employee flex-2`
    },
    distanceBetweenCells() {
      return 'mr-2 md:mr-4'
    },
    netTimeCellClass() {
      return `${this.distanceBetweenCells} flex flex-1.5 justify-end`
    },
    statusCellClass() {
      return `${this.distanceBetweenCells} flex justify-end md:justify-start`
    },
    statusCellWidth() {
      return this.$isSmallDevice ? '25%' : this.$isMediumDevice ? '12%' : '8%'
    },
    infoCellWidth() {
      return this.$isMediumDevice ? '72px' : '88px'
    },
    dateCellWidth() {
      return this.isAdmin
        ? this.$isMediumDevice
          ? '18%'
          : '8%'
        : this.$isMediumDevice
        ? '30%'
        : '20%'
    },
    totalNumbOfTrackings() {
      return this.$t('time_tracking.index.footer.total_trackings', {
        total: this.timeTrackingsTotals.timeTrackings,
      })
    },
    mobileRow() {
      if (this.mobileRowIndex === -1) {
        return {}
      }
      const row = this.timeTrackings[this.mobileRowIndex]

      return Object.assign(row, {
        dateValue: formatDateCell(row),
        timeValue: formatTimeCell(row),
      })
    },
    mobileActionStatuses() {
      const statuses = {
        confirm: {},
      }

      if (!this.mobileRow) {
        return statuses
      }

      if (!this.$actions.confirmTracking.instances) {
        return statuses
      }

      statuses.confirm =
        this.$actions.confirmTracking.instances[this.mobileRow.id] || {}

      return statuses
    },
  },
  watch: {
    timeTrackings({ length }) {
      if (!length) {
        this.prevScrollOffset = 0
      }
    },
    filters() {
      this.clearTimeTrackings()
      this.fetchMoreTimeTrackings()
    },
  },
  created() {
    this.fetchMoreTimeTrackings()
    emitter.on('app-sidebar.module-navigation', this.onModuleNavigation)
  },
  destroyed() {
    emitter.off('app-sidebar.module-navigation', this.onModuleNavigation)
  },
  methods: {
    ...mapActions({
      fetchMoreTimeTrackings: TIME_TRACKING_ACTION_LIST_APPEND_TIME_TRACKINGS,
      fetchTimeTrackings: TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS,
      clearTimeTrackings: TIME_TRACKING_ACTION_CLEAR_TIME_TRACKINGS,
      fetchTimeTrackingNotes: TIME_TRACKING_NOTE_ACTION_LIST,
      fetchTimeTrackingFollowers: TIME_TRACKING_FOLLOWER_ACTION_LIST,
      fetchTimeTrackingAttachments: TIME_TRACKING_ATTACHMENT_ACTION_LIST,
      fetchTimeTrackingSignatures: TIME_TRACKING_SIGNATURE_ACTION_LIST,
      confirmTimeTracking: TIME_TRACKING_ACTION_CONFIRM_TIME_TRACKING,
      batchConfirmTimeTrackings: TIME_TRACKING_ACTION_CONFIRM_LIST,
    }),
    ...mapMutations({
      setTimeTrackingChecked: TIME_TRACKING_MUTATION_SET_TIME_TRACKING_CHECKED,
      setAllTimeTrackingChecked:
        TIME_TRACKING_MUTATION_SET_ALL_TIME_TRACKINGS_CHECKED,
      setFieldSorting: TIME_TRACKING_MUTATION_SET_TIME_TRACKING_SORTING_FIELD,
    }),
    rowClick(row) {
      if (this.$isSmallDevice && !row._hideRecordFromTable) {
        this.selectedRow = row
        this.mobileRowIndex = this.timeTrackings.findIndex(
          (item) => item.id === row.id
        )
      }
    },
    onModuleNavigation(moduleName) {
      if (moduleName === TIME_TRACKING_MODULE_NAME) {
        this.clearTimeTrackings()
        this.fetchMoreTimeTrackings()
      }
    },
    onScroll() {
      if (
        this.$actions.appendTrackings.isLoading ||
        this.$actions.fetchTrackings.isLoading
      )
        return

      const currentOffset = this.$refs.baseTable.getCurrentScrollOffset()
      const rowsOffsetSum = this.rowHeight * 16

      if (currentOffset - this.prevScrollOffset > rowsOffsetSum) {
        this.prevScrollOffset = currentOffset
        this.fetchMoreTimeTrackings()
      }
      if (!this.$isSmallDevice) {
        return false
      }
      if (!this.isScrolling) {
        this.isScrolling = true
      }
      clearTimeout(this.scrollTimer)
      this.scrollTimer = setTimeout(() => {
        this.isScrolling = false
      }, 200)
    },
    sortByField(fieldName, direction) {
      this.setFieldSorting({ fieldName, direction })
      this.clearTimeTrackings()
      this.fetchMoreTimeTrackings()
    },
    toggleExportsModal(open) {
      this.isExportModalOpen = open
    },
    showSignatures(row) {
      this.fetchTimeTrackingSignatures({ timeTrackingId: row.id })
      this.signaturesTimeTracking = row
    },
    showAttachments(row) {
      this.fetchTimeTrackingAttachments({ timeTrackingId: row.id })
      this.attachmentsTimeTracking = row
    },
    async showNotes(row) {
      this.notesTimeTrackingIndex = this.timeTrackings.findIndex(
        (t) => t.id === row.id
      )
      const rowTimeTracking = this.timeTrackings[this.notesTimeTrackingIndex]

      this.fetchTimeTrackingFollowers({ timeTrackingId: rowTimeTracking.id })

      await this.fetchTimeTrackingNotes({
        timeTrackingId: rowTimeTracking.id,
        pagination: {
          page: 1,
          items: this.$isSmallDevice
            ? NOTES_BOTTOM_SHEET_ITEMS_PER_PAGE
            : NOTES_MODAL_ITEMS_PER_PAGE,
        },
      })

      if (
        !rowTimeTracking.user_notes_count &&
        !rowTimeTracking.system_notes_count
      ) {
        this.notesTimeTrackingIndex = -1
        this.setAllTimeTrackingChecked(false)
        this.setTimeTrackingChecked({ id: rowTimeTracking.id, checked: true })
        this.isAddNoteModalOpen = true
      }
    },
    closeTimeTrackingEditView() {
      // Simply setting editTimeTrackingId keeps the details bottom sheet open
      // which is an invalid state since the details for that tracking are
      // no longer required the following if block fixes that
      if (this.mobileRow.id === this.editingTimeTrackingId) {
        this.mobileRowIndex = -1
      }
      this.editingTimeTrackingId = null
    },
    confirmTimeTrackings(confirmableTrackings) {
      if (this.isAllChecked) {
        this.approveTimeTrackingsConfirm(confirmableTrackings, true)
      } else {
        const unconfirmedTrackings = this.checkedTimeTrackings.filter(
          (item) =>
            item.current_status === TIME_TRACKING_STATES.FINISHED &&
            !item.confirmed
        )
        if (unconfirmedTrackings.length === confirmableTrackings.length) {
          this.approveTimeTrackingsConfirm(confirmableTrackings)
        } else {
          this.batchConfirmDialog = {
            unconfirmedTrackings,
            confirmableTrackings,
          }
        }
      }
    },
    confirmAllTimeTrackings() {
      this.approveTimeTrackingsConfirm([], true)
    },
    async approveTimeTrackingsConfirm(trackings, checkAll = false) {
      const trackingIds = trackings.map((item) => item.id)
      await this.batchConfirmTimeTrackings({
        trackingIds,
        checkAll,
      })
      if (this.batchConfirmDialog) {
        this.batchConfirmDialog = null
      }

      this.$buefy.snackbar.open(
        this.$t('group_selection.batch_confirm_success_message')
      )
    },
    async confirmTrackingOnMobile() {
      await this.confirmTimeTracking({ timeTrackingId: this.mobileRow.id })

      this.mobileRowIndex = -1
    },
  },
}
</script>

<style lang="scss" scoped>
$table-checkbox-mobile-width: theme('width.6');
$table-checkbox-mobile-margin-right: theme('margin[2.5]');
$table-cells-mobile-distance: theme('margin.2');
$table-cells-desktop-distance: theme('margin.4');
::v-deep .time-tracking-table {
  @apply relative max-w-full-vw;

  @screen md {
    max-width: calc(100vw - 80px);
  }

  .table-cell-employee {
    width: calc(
      50% - #{$table-checkbox-mobile-width} - #{$table-checkbox-mobile-margin-right} -
        #{$table-cells-mobile-distance}
    );
    @screen md {
      min-width: 10%;
      @apply flex-1 w-auto;
    }

    @media (max-width: 360px) {
      width: calc(
        40% - #{$table-checkbox-mobile-width} - #{$table-checkbox-mobile-margin-right} -
          #{$table-cells-mobile-distance}
      );
    }
  }

  .table-cell-checkbox {
    width: $table-checkbox-mobile-width;
    margin-right: $table-checkbox-mobile-margin-right;

    @screen md {
      @apply w-4 mr-2;
    }

    @screen lg {
      margin-right: $table-cells-desktop-distance;
      @apply w-4;
    }
  }

  .table-cell-net-time {
    width: calc(20% - #{$table-cells-mobile-distance});
    @screen md {
      width: 5%;
    }

    @media (max-width: 360px) {
      width: calc(30% - #{$table-cells-mobile-distance});
    }
  }

  .table-footer-total-trackings {
    @apply -mr-2;

    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateX(
      calc(
        (
            #{$table-checkbox-mobile-width} + #{$table-checkbox-mobile-margin-right}
          ) * -1
      )
    );

    span {
      display: block;
      width: 100px;
      white-space: nowrap;
    }

    @screen md {
      overflow: visible;
      transform: none;

      span {
        overflow: visible;
      }
    }
  }
  .table-footer-total-trackings.status-cell {
    @apply mr-2;

    transform: translateX(1rem);

    span {
      display: block;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @screen md {
      @apply mr-0;

      transform: none;

      span {
        overflow: visible;
      }
    }
  }

  .total-break-cell {
    @apply -ml-1;
  }
}

@media (min-width: theme('screens.md.min')) {
  ::v-deep .table-header-container {
    @apply z-1;

    top: 10.875rem;
  }
}
</style>
