import realStore from '@/store'
import {
  TIME_TRACKING_FOLLOWER_ACTION_ADD,
  TIME_TRACKING_FOLLOWER_ACTION_DELETE,
} from '../../../store/actions/time-tracking.actions.names'

export default function useFollowers(followers, timeTrackingId) {
  const store =
    process.env.VUE_APP_MODE === 'test' ? this._storeMock : realStore
  const addFollower = (user) => {
    const existing = followers.value.some((f) => f.id === user.id)

    if (!existing) {
      store.dispatch(TIME_TRACKING_FOLLOWER_ACTION_ADD, {
        timeTrackingId: timeTrackingId.value,
        userId: user.id,
      })
    }
  }

  const removeFollower = (user) => {
    store.dispatch(TIME_TRACKING_FOLLOWER_ACTION_DELETE, {
      timeTrackingId: timeTrackingId.value,
      userId: user.id,
    })
  }

  return {
    addFollower,
    removeFollower,
  }
}
