<template>
  <div class="max-h-full overflow-y-auto hidden-scrollbar">
    <div class="flex justify-between items-start mb-2">
      <div class="flex-1">
        <note-field
          :label="$t('time_tracking.notes_modal.created_by_field')"
          class="w-11/12"
          :value="currentNoteAuthor"
        />
        <note-field
          :label="$t('time_tracking.notes_modal.date_field')"
          :value="currentNoteDate"
        />
        <note-field
          v-if="currentNoteAttachments.length"
          :label="$t('time_tracking.notes_modal.attachments_field')"
          :value="currentNoteAttachments"
        />
      </div>

      <note-options
        v-if="
          !$isSmallDevice && currentNote.type === TIME_TRACKING_NOTE_TYPES.USER
        "
        class="flex-none"
        :data-id-prefix="`time_tracking.notes_modal.note_details_options.${currentNote.id}`"
        @edit="$emit('edit')"
        @delete="$emit('delete')"
      />
    </div>

    <div class="md:pt-6 md:pb-4 md:border-t font-medium">
      {{ currentNoteContent }}
    </div>
  </div>
</template>

<script>
import { NOTE_TYPES } from '@/constants'
import { formatDate } from '@/utils/date-time.utils'
import { unescapeNoteContent } from '@/utils/helpers'
import TimeTrackingNotesNoteFieldAttachment from './TimeTrackingNotesNoteFieldAttachment'
import NoteField from './TimeTrackingNotesNoteField'
import NoteOptions from './TimeTrackingNotesNoteOptions'

export default {
  name: 'TimeTrackingNotesDetails',
  components: {
    NoteField,
    NoteOptions,
  },
  props: {
    currentNote: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentNoteContent() {
      return unescapeNoteContent(this.currentNote)
    },
    currentNoteAuthor() {
      if (this.currentNote.type === NOTE_TYPES.SYSTEM) {
        return this.$t('time_tracking.notes_modal.author_system')
      }
      return this.currentNote.author
    },
    currentNoteDate() {
      if (!this.currentNote.created_at) {
        return ''
      }
      return formatDate(new Date(this.currentNote.created_at))
    },
    currentNoteAttachments() {
      if (!this.currentNote.attachments) {
        return []
      }
      return this.currentNote.attachments.map((attachment) => ({
        component: TimeTrackingNotesNoteFieldAttachment,
        props: {
          fileName: attachment.file_name,
          fileUrl: attachment.file_url,
        },
      }))
    },
  },
  created() {
    this.TIME_TRACKING_NOTE_TYPES = NOTE_TYPES
  },
}
</script>
