import Vue from 'vue'
import {
  setRecords,
  addRecords,
  addIncluded,
  prependRecords,
  appendRecords,
  replaceRecord,
  deleteRecords,
  clearRecords,
} from '@/utils/jsonapi/records.mutations'
import {
  attachRelationships,
  detachRelationships,
} from '@/utils/jsonapi/relationships.mutations'
import { shortname } from '@/utils/store'
import * as mutationNamesObj from './time-tracking.mutations.names'
import { TIME_TRACKING_TABS } from '@/app/modules/time-tracking/constants'
import { TimeTrackingState, ActionBreak } from '../time-tracking.state'
import { BaseResponseMeta } from '@/utils/api/base-response'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.TIME_TRACKING_MUTATION_SET_RECORDS]: setRecords,

  [mutationNames.TIME_TRACKING_MUTATION_ADD_RECORDS]: addRecords,

  [mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED]: addIncluded,

  [mutationNames.TIME_TRACKING_MUTATION_PREPEND_RECORDS]: prependRecords,

  [mutationNames.TIME_TRACKING_MUTATION_APPEND_RECORDS]: appendRecords,

  [mutationNames.TIME_TRACKING_MUTATION_REPLACE_RECORD]: replaceRecord,

  [mutationNames.TIME_TRACKING_MUTATION_DELETE_RECORDS]: deleteRecords,

  [mutationNames.TIME_TRACKING_MUTATION_CLEAR_RECORDS]: clearRecords,

  [mutationNames.TIME_TRACKING_MUTATION_ATTACH_RELATIONSHIPS]:
    attachRelationships,

  [mutationNames.TIME_TRACKING_MUTATION_DETACH_RELATIONSHIPS]:
    detachRelationships,

  [mutationNames.TIME_TRACKING_MUTATION_SET_TIME_TRACKING_CHECKED]: (
    state: TimeTrackingState,
    {
      id,
      checked,
    }: {
      id: RecordId
      checked: boolean
    }
  ) => {
    const record = state.records.time_tracking.find((item) => item.id === id)
    if (record) {
      Vue.set(record, 'checked', checked)
    }
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_ALL_TIME_TRACKINGS_CHECKED]: (
    state: TimeTrackingState,
    checked: boolean
  ) => {
    state.records.time_tracking.forEach((item) =>
      Vue.set(item, 'checked', checked)
    )
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_CURRENT_TAB]: (
    state: TimeTrackingState,
    {
      tab,
    }: {
      tab: string
    }
  ) => {
    state.timeTrackingTab = tab
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_DATE_RANGE]: (
    state: TimeTrackingState,
    dateRange: Date[]
  ) => {
    state.timeTrackingFilterDateRange = dateRange
  },
  [mutationNames.TIME_TRACKING_MUTATION_SET_SHOW_NEW_FILTER_FORM]: (
    state: TimeTrackingState,
    show: boolean
  ) => {
    state.timeTrackingFilterbarShowFormNew = show
  },
  [mutationNames.TIME_TRACKING_MUTATION_ADD_FILTERBAR_FILTER]: (
    state: TimeTrackingState,
    filter: {
      field: string
    }
  ) => {
    if (state.timeTrackingFilterbarFilters.indexOf(filter.field) === -1) {
      state.timeTrackingFilterbarFilters = [
        ...state.timeTrackingFilterbarFilters,
        filter.field,
      ]
    }
    state.timeTrackingFilterbarValues = {
      ...state.timeTrackingFilterbarValues,
      [filter.field]: { ...filter },
    }
  },
  [mutationNames.TIME_TRACKING_MUTATION_REMOVE_FILTERBAR_FILTER]: (
    state: TimeTrackingState,
    removedFilter: string
  ) => {
    state.timeTrackingFilterbarFilters =
      state.timeTrackingFilterbarFilters.filter(
        (filter) => filter !== removedFilter
      )
    state.timeTrackingFilterbarValues = {
      ...state.timeTrackingFilterbarValues,
      [removedFilter]: undefined,
    }
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_META_TIME_TRACKINGS]: (
    state: TimeTrackingState,
    meta: BaseResponseMeta
  ) => {
    if (state.timeTrackingTab === TIME_TRACKING_TABS.ALL) {
      state.timeTrackingAllMeta = { ...meta }
    }
    state.timeTrackingCurrentMeta = { ...meta }
  },
  [mutationNames.TIME_TRACKING_MUTATION_UPDATE_TABS_META_TIME_TRACKINGS]: (
    state: TimeTrackingState,
    meta: BaseResponseMeta
  ) => {
    state.timeTrackingAllMeta = { ...meta }
  },

  [mutationNames.TIME_TRACKING_MUTATION_INCREMENT_TIME_TRACKINGS_PAGE]: (
    state: TimeTrackingState
  ) => {
    state.timeTrackingsPagination.page++
  },

  [mutationNames.TIME_TRACKING_MUTATION_RESET_TIME_TRACKINGS_PAGE]: (
    state: TimeTrackingState
  ) => {
    state.timeTrackingsPagination.page = 0
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_TIME_TRACKING_SORTING_FIELD]: (
    state: TimeTrackingState,
    {
      fieldName,
      direction,
    }: {
      fieldName: string
      direction: number
    }
  ) => {
    Object.keys(state.timeTrackingsSorting).forEach(
      (k) => (state.timeTrackingsSorting[k] = 0)
    )
    Vue.set(state.timeTrackingsSorting, fieldName, Number(direction))
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_NOTE_META]: (
    state: TimeTrackingState,
    meta: BaseResponseMeta
  ) => {
    state.noteCurrentMeta = meta
  },
  [mutationNames.TIME_TRACKING_MUTATION_SET_EXPORT_ID]: (
    state: TimeTrackingState,
    exportId: RecordId
  ) => {
    const newListOfIds = [...state.pendingExportIds, exportId]
    localStorage.setItem('pendingExportIds', JSON.stringify(newListOfIds))
    state.pendingExportIds = newListOfIds
  },
  [mutationNames.TIME_TRACKING_MUTATION_REMOVE_EXPORT_ID]: (
    state: TimeTrackingState,
    exportId: RecordId
  ) => {
    const remainingExportIds = state.pendingExportIds.filter(
      (id) => id !== exportId
    )
    localStorage.setItem('pendingExportIds', JSON.stringify(remainingExportIds))
    state.pendingExportIds = remainingExportIds
  },

  [mutationNames.TIME_TRACKING_MUTATION_SET_ACTION_BREAKS]: (
    state: TimeTrackingState,
    breaks: ActionBreak[]
  ) => {
    state.actionBreaks = breaks
  },

  [mutationNames.TIME_TRACKING_MUTATION_CLEAR_ACTION_BREAKS]: (
    state: TimeTrackingState
  ) => {
    state.actionBreaks = []
  },
}
