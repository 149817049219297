import { RecordRelationship } from '@/utils/jsonapi/types'
import BaseRequest, {
  BaseRequestPermissions,
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import {
  TIME_TRACKING_ENDPOINT_SHOW_TIME_TRACKING,
  TIME_TRACKING_ENDPOINT_LIST_TIME_TRACKINGS,
  TIME_TRACKING_ENDPOINT_CONFIRM_TIME_TRACKING,
  TIME_TRACKING_ENDPOINT_DELETE_TIME_TRACKING,
  TIME_TRACKING_ENDPOINT_SHOW_ACTIVE,
  TIME_TRACKING_ENDPOINT_START,
  TIME_TRACKING_ENDPOINT_STOP,
  TIME_TRACKING_ENDPOINT_CREATE_TIME_TRACKING,
  TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING,
  TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING_START_AT,
} from '../network/time-tracking.endpoints'
import { Note } from './time-tracking-note.model'
import { Signature } from './time-tracking-signature.model'
import { Attachment } from '@/utils/note-attachments'
import { Follower } from './time-tracking-follower.model'
import { Employee } from '@/app/modules/employee/models/employee.model'
import { Break } from '../models/time-tracking-break.model'
import { generateDateRangeQueryParam } from '@/utils/helpers'

export const TIME_TRACKING_TYPE = 'time_tracking'
export const ACTIVE_TIME_TRACKING_TYPE = 'active_time_tracking'
export const TIME_TRACKING_REL_FOLLOWERS = 'followers'

export const TIME_TRACKING_ITEMS_PER_PAGE = 30

const INCLUDE_PARAMS = 'user, signatures, working_session_pauses'
const PERMISSIONS_PARAMS = {
  update: true,
  destroy: true,
  confirm: true,
  update_area: true,
  update_tags: true,
}

export interface Workspace {
  id?: RecordId
  name: string
}
export interface Area {
  id?: RecordId
  name: string
}

export interface Tag {
  id?: RecordId
  name: string
}

export interface User {
  id?: RecordId
  avatar: string
  email: string
  locale: string
  salary: number
  username: string
}

export interface TimeTrackingRaw {
  id: RecordId
  attributes: Record<string, unknown>
  relationships: RecordRelationship[]
}

export interface TimeTracking {
  id?: RecordId
  type: string
  //attributes
  permissions?: BaseRequestPermissions
  starts_at: Date
  ends_at: Date
  confirmed: boolean
  default_break_duration: number
  created_by: string
  force_custom_break_duration: boolean
  status: string
  actual_starts_at: Date
  actual_ends_at: Date
  current_status: string
  gross_time: number
  net_time: number
  break_time: number
  system_notes_count: number
  user_notes_count: number
  signatures_count: number
  attachments_count: number
  area: Area
  startDateTime: Date
  endDateTime: Date
  employee: Employee
  enforceExactBreakDuration: boolean
  // relationships
  attachments?: Attachment[]
  breaks: Break[]
  notes?: Note[]
  signatures?: Signature[]
  tags?: Tag[]
  followers?: Follower[]
  areas?: Area[]
  workspace?: Workspace[]
}

export interface TimeTrackingListRequestParams {
  workspaceId: RecordId
  include?: string
  permissions?: BaseRequestPermissions
  pagination: BaseRequestPagination
  sorting?: BaseRequestSorting
  filter: {
    starts_at: Date[]
  }
}

export interface TimeTrackingRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  include?: string
}

export interface TimeTrackingConfirmRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
}

export interface TimeTrackingStartRequestParams {
  workspaceId: RecordId
  userId: RecordId
  areas: Area[]
}

export interface TimeTrackingDeleteRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
}

export class TimeTrackingRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    include,
  }: TimeTrackingRequestParams) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_SHOW_TIME_TRACKING(
      workspaceId,
      timeTrackingId
    )
    super.type = TIME_TRACKING_TYPE

    super.include = include || INCLUDE_PARAMS
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class ActiveTimeTrackingRequest extends BaseRequest {
  constructor({ workspaceId }: { workspaceId: RecordId }) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_SHOW_ACTIVE(workspaceId)
    super.type = TIME_TRACKING_TYPE

    super.filter = {
      running: 1,
    }

    super.include = INCLUDE_PARAMS

    super.permissions = PERMISSIONS_PARAMS
  }
}
export class StartTimeTrackingRequest extends BaseRequest {
  constructor({ workspaceId, userId, areas }: TimeTrackingStartRequestParams) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_START(workspaceId)
    super.type = TIME_TRACKING_TYPE

    super.method = 'post'

    super.attributes = {
      starts_at: new Date(),
      user_id: userId,
      area_id: areas.map((area) => area.id).join(),
    }

    super.include = INCLUDE_PARAMS

    super.permissions = PERMISSIONS_PARAMS
  }
}
export class StopTimeTrackingRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
  }: {
    workspaceId: RecordId
    timeTrackingId: RecordId
  }) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_STOP(workspaceId, timeTrackingId)
    super.type = TIME_TRACKING_TYPE

    super.method = 'patch'

    super.attributes = {
      ends_at: new Date(),
    }

    super.include = INCLUDE_PARAMS

    super.permissions = PERMISSIONS_PARAMS
  }
}
export class TimeTrackingListRequest extends BaseRequest {
  constructor({
    workspaceId,
    include = INCLUDE_PARAMS,
    permissions,
    pagination,
    sorting = {},
    filter,
  }: TimeTrackingListRequestParams) {
    super()

    const { starts_at: dateRange, ...restFilters } = filter

    const baseURL = TIME_TRACKING_ENDPOINT_LIST_TIME_TRACKINGS(workspaceId)

    if (dateRange) {
      const dateFilter = generateDateRangeQueryParam(dateRange)

      super.url = `${baseURL}${dateFilter}`
    } else {
      super.url = baseURL
    }

    super.type = TIME_TRACKING_TYPE

    super.include = include

    super.permissions = permissions || PERMISSIONS_PARAMS

    super.pagination = {
      items: pagination.items,
      page: pagination.page,
    }

    super.sorting = sorting
    super.filter = restFilters
  }
}
export class TimeTrackingConfirmRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
  }: TimeTrackingConfirmRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_CONFIRM_TIME_TRACKING(
      workspaceId,
      timeTrackingId
    )
    super.type = TIME_TRACKING_TYPE
    super.method = 'patch'
  }
}

export class SingleTimeTrackingOperationRequest extends BaseRequest {
  formatTimeTracking(timeTracking: TimeTracking) {
    return {
      starts_at: timeTracking.startDateTime,
      ends_at: timeTracking.endDateTime,
      user_id: timeTracking.employee.id,
      area_id: timeTracking.area ? timeTracking.area.id : null,
      force_custom_break_duration: timeTracking.enforceExactBreakDuration,
      created_by: timeTracking.created_by,
      tag_ids: timeTracking.tags ? timeTracking.tags.map(({ id }) => id) : [],
      breaks_attributes: timeTracking.breaks.map(
        ({ id, startDateTime, endDateTime, existing, deleted }) => ({
          ...(existing && { id }),
          starts_at: startDateTime,
          ends_at: endDateTime,
          ...(deleted && { _destroy: true }),
        })
      ),
    }
  }
}

export class TimeTrackingCreateRequest extends SingleTimeTrackingOperationRequest {
  constructor({
    workspaceId,
    timeTracking,
  }: {
    workspaceId: RecordId
    timeTracking: TimeTracking
  }) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_CREATE_TIME_TRACKING(workspaceId)
    super.type = TIME_TRACKING_TYPE
    super.method = 'post'

    super.attributes = super.formatTimeTracking(timeTracking)
  }
}

export class TimeTrackingUpdateRequest extends SingleTimeTrackingOperationRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    timeTracking,
  }: {
    workspaceId: RecordId
    timeTrackingId: RecordId
    timeTracking: TimeTracking
  }) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING(
      workspaceId,
      timeTrackingId
    )
    super.type = TIME_TRACKING_TYPE
    super.method = 'patch'

    super.attributes = super.formatTimeTracking(timeTracking)
  }
}

export class TimeTrackingUpdateStartAtRequest extends SingleTimeTrackingOperationRequest {
  constructor({
    workspaceId,
    actionId,
    timeTracking,
  }: {
    workspaceId: RecordId
    actionId: RecordId
    timeTracking: TimeTracking
  }) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING_START_AT(
      workspaceId,
      actionId
    )
    super.type = TIME_TRACKING_TYPE
    super.method = 'patch'

    super.attributes = { starts_at: timeTracking.startDateTime }
  }
}

export class TimeTrackingDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
  }: TimeTrackingDeleteRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_DELETE_TIME_TRACKING(
      workspaceId,
      timeTrackingId
    )
    super.type = TIME_TRACKING_TYPE
    super.method = 'delete'
  }
}
