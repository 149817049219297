import BaseRequest from '@/utils/api/base-request'
import {
  TIME_TRACKING_ENDPOINT_LIST_FOLLOWERS,
  TIME_TRACKING_ENDPOINT_ADD_FOLLOWER,
  TIME_TRACKING_ENDPOINT_DELETE_FOLLOWER,
} from '../network/time-tracking.endpoints'

export const TIME_TRACKING_FOLLOWER_TYPE = 'follower'
export const TIME_TRACKING_FOLLOWER_LIST_TYPE = 'user'

export interface Follower {
  id?: RecordId
  name: string
}

export interface FollowerListRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
}

export interface FollowerAddRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  userIds: RecordId[]
}

export interface FollowerDeleteRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  userId: RecordId
}

export class TimeTrackingFollowerListRequest extends BaseRequest {
  constructor({ workspaceId, timeTrackingId }: FollowerListRequestParams) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_LIST_FOLLOWERS(
      workspaceId,
      timeTrackingId
    )

    // TODO: discuss possibility of large followers count
    super.pagination = {
      items: 99,
      page: 1,
    }
  }
}

export class TimeTrackingFollowerAddRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    userIds,
  }: FollowerAddRequestParams) {
    super()

    super.method = 'post'
    super.url = TIME_TRACKING_ENDPOINT_ADD_FOLLOWER(workspaceId, timeTrackingId)
    super.type = TIME_TRACKING_FOLLOWER_TYPE

    super.attributes = {
      ids: userIds,
    }
  }
}

export class TimeTrackingFollowerDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    userId,
  }: FollowerDeleteRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_DELETE_FOLLOWER(
      workspaceId,
      timeTrackingId,
      userId
    )
    super.method = 'delete'
    super.type = TIME_TRACKING_FOLLOWER_TYPE
  }
}
