<template>
  <base-table-row-cell-icon-with-tooltip
    icon-name="signature/signature-16"
    data-id="time_tracking.index.body.signatures_icon"
    :is-loading="actionStatus.isLoading"
    @hover="$emit('hover')"
  >
    <template v-slot:tooltip-content>
      <div v-if="actionStatus.isLoading" class="pb-6">
        <base-loading :active="true" />
      </div>
      <div v-else class="cursor-pointer" @click="$emit('tooltip-click')">
        <table-cell-signature-item
          v-for="(signature, index) in displayedSignatures"
          :key="index"
          :signature="signature"
        />

        <button
          class="text-green-400 font-medium mt-2.5 mb-1"
          data-id="time_tracking.index.body.signatures.review_more"
        >
          {{ moreSignaturesText }}
        </button>
      </div>
    </template>
  </base-table-row-cell-icon-with-tooltip>
</template>

<script>
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import BaseTableRowCellIconWithTooltip from '@/app/util-modules/ui/table-row-cell-icon/BaseTableRowCellIconWithTooltip.vue'
import TableCellSignatureItem from './TableCellSignatureItem'

const MAX_DISPLAYED_SIGNATURES = 4

export default {
  name: 'TableCellSignatures',
  components: {
    BaseLoading,
    BaseTableRowCellIconWithTooltip,
    TableCellSignatureItem,
  },
  props: {
    signatures: {
      type: Array,
      default: () => [],
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasMoreThanFourSignatures() {
      return this.signatures.length > MAX_DISPLAYED_SIGNATURES
    },
    moreSignaturesText() {
      return this.hasMoreThanFourSignatures
        ? this.$t('time_tracking.index.body.signatures.review_more', {
            signatures: this.signatures.length - MAX_DISPLAYED_SIGNATURES,
          })
        : this.$t('time_tracking.index.body.signatures.show_signatures')
    },
    displayedSignatures() {
      return this.hasMoreThanFourSignatures
        ? this.signatures.slice(0, MAX_DISPLAYED_SIGNATURES)
        : this.signatures
    },
  },
}
</script>

<style scoped>
::v-deep .tooltip-content {
  @apply w-65;
}
</style>
