<template>
  <div>
    <base-tooltip
      position="is-right"
      :active="overflowTagName || moreThanOneTag"
      type="is-white"
      class="tooltip-wrapper is-primary"
      :label="tagsText || label"
    >
      <div class="flex items-center">
        <table-row-cell-default
          class="line-clamp-2 break-all"
          :value="label"
          :archived="archived"
        />

        <span v-if="moreThanOneTag" class="text-coal-550 ml-1">
          {{ restOfTagsLength }}
        </span>
      </div>
    </base-tooltip>
  </div>
</template>

<script>
import TableRowCellDefault from '../table-row-cell-default/TableRowCellDefault'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'

export default {
  name: 'TableRowCellTags',
  components: { TableRowCellDefault, BaseTooltip },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return this.tags.length === 0 ? '--' : this.tags[0]
    },
    moreThanOneTag() {
      return this.tags.length > 1
    },
    overflowTagName() {
      return this.tags[0] ? this.tags[0].length > 40 : false
    },
    restOfTagsLength() {
      return this.moreThanOneTag ? `+${this.tags.length - 1}` : ''
    },
    tagsText() {
      return this.moreThanOneTag ? this.tags.join(', ') : ''
    },
  },
}
</script>

<style scoped>
.tooltip-wrapper >>> .tooltip-content {
  @apply whitespace-normal normal-case break-words text-white bg-coal-850 md:max-w-lg w-40;
}
</style>
