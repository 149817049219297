

















import { defineComponent, computed, ref } from '@vue/composition-api'
import { TIME_TRACKING_STATES } from '@/app/modules/time-tracking/constants'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import i18n from '@/utils/vendors/i18n/index'

export default defineComponent({
  name: 'TableRowCellStatus',
  components: {
    BaseTooltip,
  },
  props: {
    status: {
      type: String,
      default: TIME_TRACKING_STATES.NOT_STARTED,
      validator: (value: string) => {
        return [
          TIME_TRACKING_STATES.BREAKED,
          TIME_TRACKING_STATES.RUNNING,
          TIME_TRACKING_STATES.FINISHED,
          TIME_TRACKING_STATES.NOT_STARTED,
        ].includes(value)
      },
    },
  },
  setup(props) {
    const timetrackingStates = ref(TIME_TRACKING_STATES)
    const statusData = computed(() => {
      switch (props.status) {
        case TIME_TRACKING_STATES.BREAKED:
          return {
            label: i18n.t('time_tracking.index.body.status.break'),
            color: 'bg-yellow-300',
          }
        case TIME_TRACKING_STATES.RUNNING:
          return {
            label: i18n.t('time_tracking.index.body.status.running'),
            color: 'bg-green-300',
          }
        default:
          return {
            label: '',
            color: '',
          }
      }
    })

    const showToolTip = computed(() => {
      return (
        props.status !== TIME_TRACKING_STATES.FINISHED &&
        props.status !== TIME_TRACKING_STATES.NOT_STARTED
      )
    })

    return {
      statusData,
      timetrackingStates,
      showToolTip,
    }
  },
})
