<template>
  <div>
    <portal v-if="!$isSmallDevice" to="modal">
      <signatures-modal
        :signatures="timeTracking.signatures"
        :action-status="actionStatus"
        @close="$emit('close')"
      />
    </portal>

    <portal v-else to="bottom-sheet">
      <signatures-bottom-sheet
        :signatures="timeTracking.signatures"
        :action-status="actionStatus"
        @close="$emit('close')"
      />
    </portal>
  </div>
</template>

<script>
import SignaturesModal from './TimeTrackingSignaturesModal'
import SignaturesBottomSheet from './TimeTrackingSignaturesBottomSheet'

export default {
  name: 'TimeTrackingSignatures',
  components: {
    SignaturesModal,
    SignaturesBottomSheet,
  },
  props: {
    timeTracking: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
