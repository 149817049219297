<template>
  <base-date-range-picker
    :default-value="dateRange"
    position="is-bottom-left"
    :get-shortcuts-method="getShortcuts"
    data-id-prefix="time_tracking.index.date_picker"
    class="cursor-pointer"
    @input="updateDateRange($event)"
  />
</template>

<script>
import { getShortcuts } from '@/utils/date-time.utils'

import { mapMutations, mapState } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../time-tracking.module'
import { TIME_TRACKING_MUTATION_SET_DATE_RANGE } from '../../store/mutations/time-tracking.mutations.names'
import BaseDateRangePicker from '@/app/util-modules/ui/date-range-picker/BaseDateRangePicker'

export default {
  name: 'TimeTrackingDateRangePicker',
  components: {
    BaseDateRangePicker,
  },
  computed: mapState(TIME_TRACKING_MODULE_NAME, {
    dateRange: (state) => state.timeTrackingFilterDateRange,
  }),
  methods: {
    ...mapMutations({
      updateDateRange: TIME_TRACKING_MUTATION_SET_DATE_RANGE,
    }),
    getShortcuts,
  },
}
</script>
