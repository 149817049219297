<template>
  <tabs-topbar
    :tabs="installedModules"
    data-id-prefix="time_tracking_tabs_topbar"
    :modules="noninstalledModules"
  />
</template>

<script>
import TabsTopbar from '@/app/util-modules/ui/tabs-topbar/TabsTopbar'

export default {
  name: 'TimeTrackingTabsTopbar',
  components: {
    TabsTopbar,
  },
  data() {
    return {
      modules: [
        {
          id: 'timeTracking',
          smallIcon: 'time-tracking/time-tracking-16',
          smallIconType: 'is-filled',
          label: () => this.$t('modules.time_tracking.label'),
          route: '',
          installed: true,
        },
        {
          id: 'calendar',
          largeIcon: 'calendar/calendar-32',
          bgClass: 'bg-blue-400',
          iconClass: 'text-blue-300',
          label: () => this.$t('modules.task_tracking.label'),
          description: this.$t('modules.task_tracking.description'),
          route: '',
          installed: false,
        },
      ],
    }
  },
  computed: {
    installedModules() {
      return this.modules.filter((m) => m.installed)
    },
    noninstalledModules() {
      return this.modules.filter((m) => !m.installed)
    },
  },
}
</script>
