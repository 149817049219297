<template>
  <div class="flex justify-end items-center">
    <base-attachment-item-tooltip
      v-if="row.attachments_count"
      data-id-prefix="time_tracking.index.body"
      :action-status="actionsStatuses.attachments"
      :attachments="row.attachments"
      attachment-item-size="is-small"
      @hover="$emit('attachments-hover')"
      @more-attachments-click="$emit('attachments-action-click')"
    />

    <table-cell-signatures
      v-if="row.signatures_count"
      :action-status="actionsStatuses.signatures"
      :signatures="row.signatures"
      @hover="$emit('signatures-hover')"
      @tooltip-click="$emit('signatures-action-click')"
    />

    <note-item-tooltip
      v-if="row.user_notes_count"
      :user-notes-count="row.user_notes_count"
      :action-status="actionsStatuses.notes"
      :notes="userNotes"
      data-id-prefix="time_tracking.index.body"
      @hover="$emit('notes-hover')"
      @more-notes-click="$emit('notes-action-click')"
    />

    <table-row-cell-recorded-by
      v-if="!$isMediumDevice"
      :recorded-by="row.created_by"
      :last-editor-username="lastEditorUsername"
    />
  </div>
</template>

<script>
import TableRowCellRecordedBy from '../table-row-cell-recorded-by/TableRowCellRecordedBy'
import { NOTE_TYPES } from '@/constants'

const BaseAttachmentItemTooltip = () =>
  import(
    '@/app/util-modules/ui/attachment-item-tooltip/BaseAttachmentItemTooltip'
  )
const NoteItemTooltip = () =>
  import('@/app/util-modules/ui/note-item-tooltip/NoteItemTooltip.vue')
const TableCellSignatures = () =>
  import('../table-cell-signatures/TableCellSignatures')

export default {
  name: 'TableRowCellInfo',
  components: {
    NoteItemTooltip,
    BaseAttachmentItemTooltip,
    TableCellSignatures,
    TableRowCellRecordedBy,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    actionsStatuses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    userNotes() {
      if (!this.row.notes) return []

      return this.row.notes.filter((note) => note.type === NOTE_TYPES.USER)
    },
    lastEditorUsername() {
      return this.row.last_editor?.username ? this.row.last_editor.username : ''
    },
  },
}
</script>
