<template>
  <div class="pb-3">
    <time-tracking-applied-date-filter :default-value="dateRangeFilter" />

    <time-tracking-applied-filterbar-filters />
  </div>
</template>

<script>
import TimeTrackingAppliedDateFilter from './TimeTrackingAppliedDateFilter'
import TimeTrackingAppliedFilterbarFilters from './TimeTrackingAppliedFilterbarFilters'
import { mapState } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../time-tracking.module'

export default {
  name: 'TimeTrackingAppliedFiltersPanel',
  components: {
    TimeTrackingAppliedDateFilter,
    TimeTrackingAppliedFilterbarFilters,
  },
  computed: {
    ...mapState(TIME_TRACKING_MODULE_NAME, {
      dateRangeFilter: (state) => state.timeTrackingFilterDateRange,
    }),
  },
}
</script>
