<template>
  <div class="flex justify-between pb-3 px-4 border-b border-color-coal">
    <button
      class="justify-self-start text-left text-lg text-coal-550 flex-1"
      type="button"
      @click="$emit('close')"
    >
      {{ $t('bottom_sheet.cancel') }}
    </button>
    <div class="text-center text-lg font-semibold flex-3">
      {{ title }}
    </div>
    <div class="flex-1" />
  </div>
</template>

<script>
export default {
  name: 'TimeTrackingExportBottomSheetHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
