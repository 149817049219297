<template>
  <div class="flex items-center" :class="wrapperClass">
    <component
      :is="wrapperComponent"
      :to="{
        name: routeLinkToName,
        params: {
          userId: user.id,
        },
      }"
      :class="[
        textClass,
        archivedClass,
        responsiveClass,
        labelClass,
        'w-full font-bold md:text-base text-lg flex-1.5',
      ]"
    >
      {{ label }}
    </component>
    <span v-if="archived" class="text-coal-300 flex items-center">
      <base-icon icon="archive/archive-16" size="0.875rem" />
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'TableRowCellEmployee',
  components: { BaseIcon },
  props: {
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    archived: {
      type: Boolean,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    wrapperClass() {
      return {
        'overflow-hidden': !this.isScrolling,
      }
    },
    wrapperComponent() {
      if (this.user.id) return 'router-link'

      return 'span'
    },
    textClass() {
      return this.isScrolling ? 'whitespace-nowrap' : 'truncate'
    },
    archivedClass() {
      return this.archived ? 'mr-2' : null
    },
    responsiveClass() {
      return this.$isSmallDevice && this.isScrolling
        ? 'table-cell-employee-name'
        : null
    },
    routeLinkToName() {
      if (this.isAdmin) {
        return `${EMPLOYEE_MODULE_NAME}.details`
      }
      return `${PROFILE_MODULE_NAME}.details`
    },
  },
}
</script>

<style lang="scss" scoped>
.table-cell-employee-name {
  z-index: 1;
  @apply relative;

  &::before,
  &::after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full;
  }

  &::before {
    z-index: -2;
    @apply bg-white;
  }
  &::after {
    @apply bg-coal-40 -z-1;
  }
}
</style>
