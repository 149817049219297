<template>
  <div class="flex sticky flex-col pt-5 px-6 top-15 z-8 bg-white">
    <div class="flex md:items-start justify-between md:gap-x-4">
      <div>
        <time-tracking-filterbar />
      </div>
      <div class="flex items-center">
        <div v-if="canCreateTracking" class="mr-2">
          <base-button
            data-id="time_tracking.index.header.add_button"
            size="is-small"
            type="is-filled"
            @click="$emit('addTrackings')"
          >
            {{ $t('time_tracking.index.header.add_button') }}
          </base-button>
        </div>
        <div>
          <time-tracking-header-actions
            @assignTags="$emit('assignTags')"
            @exportTrackings="$emit('exportTrackings')"
            @confirm-all="$emit('confirm-all')"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-2">
      <time-tracking-tabs-header />
      <time-tracking-date-range-picker />
    </div>
  </div>
</template>

<script>
import TimeTrackingDateRangePicker from '../time-tracking-date-range-picker/TimeTrackingDateRangePicker'
import TimeTrackingFilterbar from '../time-tracking-filterbar/TimeTrackingFilterbar'
import TimeTrackingTabsHeader from '../time-tracking-tabs-header/TimeTrackingTabsHeader'
import TimeTrackingHeaderActions from '../time-tracking-header-actions/TimeTrackingHeaderActions'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'

export default {
  name: 'TimeTrackingHeader',
  components: {
    TimeTrackingTabsHeader,
    TimeTrackingFilterbar,
    TimeTrackingDateRangePicker,
    TimeTrackingHeaderActions,
    BaseButton,
  },
  props: {
    canCreateTracking: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
