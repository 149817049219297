<template>
  <component
    :is="visibleComponentName"
    :title="title"
    @closeModal="closeModal()"
  >
    <time-tracking-export-form
      v-model="payload"
      :class="{ 'px-4 pt-4': $isSmallDevice }"
      :rows="rows"
      :total="total"
      @submit="exportTrackings($event)"
    />
  </component>
</template>

<script>
import TimeTrackingExportForm from './TimeTrackingExportForm'
import { TIME_TRACKING_ACTION_EXPORT } from '../../store/actions/time-tracking.actions.names'
import { mapActions } from 'vuex'

const TimeTrackingExportDesktop = () => import('./TimeTrackingExportDesktop')
const TimeTrackingExportMobile = () => import('./TimeTrackingExportMobile')

export default {
  name: 'TimeTrackingExport',
  components: {
    TimeTrackingExportForm,
    TimeTrackingExportDesktop,
    TimeTrackingExportMobile,
  },
  trackedActions: {
    batchExport: TIME_TRACKING_ACTION_EXPORT,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    customRows: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      payload: {
        export_all: false,
        staff_number: false,
        include_deactivated_areas: false,
      },
    }
  },
  computed: {
    visibleComponentName() {
      return this.$isSmallDevice
        ? TimeTrackingExportMobile
        : TimeTrackingExportDesktop
    },
    title() {
      return this.rows.length > 1
        ? this.$t('time_tracking.export_modal.multiple_trackings_title', {
            trackings: this.rows.length,
          })
        : this.$t('time_tracking.export_modal.export_all_title')
    },
  },
  methods: {
    ...mapActions({
      exportTrackingsAction: TIME_TRACKING_ACTION_EXPORT,
    }),
    closeModal() {
      this.$emit('closeModal')
    },
    async exportTrackings({ format, type }) {
      const exportData = {
        export_type: type,
        format,
        ...this.payload,
      }

      if (this.rows.length) {
        exportData.ids = this.rows.map((tracking) => tracking.id)
      } else if (!this.payload.export_all) {
        exportData.ids = this.customRows.map((tracking) => tracking.id)
      } else {
        exportData.ids = []
      }
      await this.exportTrackingsAction(exportData)

      this.closeModal()
    },
  },
}
</script>
