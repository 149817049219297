import BaseRequest, { BaseRequestSorting } from '@/utils/api/base-request'
import { generateDateRangeQueryParam } from '@/utils/helpers'
import {
  TIME_TRACKING_ENDPOINT_EXPORT,
  TIME_TRACKING_ENDPOINT_SHOW_EXPORT,
} from '../network/time-tracking.endpoints'

const TIME_TRACKING_EXPORT_TYPE = 'export'

export interface TimeTrackingExportRequestParams {
  workspaceId: RecordId
  requestParams: Record<string, unknown>
  filter: {
    starts_at: Date[]
  }
  sorting: BaseRequestSorting
}

export class TimeTrackingShowExportRequest extends BaseRequest {
  constructor(workspaceId: RecordId, exportId: RecordId) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_SHOW_EXPORT(workspaceId, exportId)
    super.type = TIME_TRACKING_EXPORT_TYPE
    super.method = 'get'
  }
}

export class TimeTrackingExportRequest extends BaseRequest {
  constructor({
    workspaceId,
    requestParams,
    filter,
    sorting,
  }: TimeTrackingExportRequestParams) {
    super()
    const { starts_at: dateRange, ...restOfFilters } = filter
    const baseURL = TIME_TRACKING_ENDPOINT_EXPORT(workspaceId)

    if (dateRange) {
      const dateFilter = generateDateRangeQueryParam(dateRange)

      super.url = `${baseURL}${dateFilter}`
    } else {
      super.url = baseURL
    }

    super.type = TIME_TRACKING_EXPORT_TYPE
    super.method = 'post'
    super.attributes = requestParams

    super.sorting = sorting
    super.filter = restOfFilters
  }
}
