<template>
  <div class="flex items-center">
    <table-row-cell-default
      :class="{ 'mr-1': row.limited }"
      :value="netTime"
      :archived="archived"
    />

    <base-tooltip
      v-if="row.limited"
      type="is-white"
      position="is-top"
      :label="$t('time_tracking.index.body.row_cells.net_time_tooltip')"
    >
      <div class="text-coal-300 flex">
        <base-icon icon="warning/warning-16" size="1rem" type="is-filled" />
      </div>
    </base-tooltip>
  </div>
</template>

<script>
import TableRowCellDefault from '../table-row-cell-default/TableRowCellDefault'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'
import { getDurationBasedOnUserSetting } from '@/utils/date-time.utils'
import { mapState } from 'vuex'

export default {
  name: 'TableRowCellNetTime',
  components: {
    TableRowCellDefault,
    BaseTooltip,
    BaseIcon,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentUser: ({ user }) => user.user,
    }),
    netTime() {
      return getDurationBasedOnUserSetting(
        this.row.net_time,
        this.currentUser.attributes.timeFormat
      )
    },
  },
}
</script>
