import i18n from '@/utils/vendors/i18n'

export default [
  {
    field: 'user_username',
    label: () => i18n.t('filters.employee'),
    type: 'string',
    params: {
      valueInputProps: {
        placeholder: () =>
          i18n.t('time_tracking.filterbar.user_username.placeholder'),
        label: () => i18n.t('time_tracking.filterbar.user_username.label'),
      },
    },
  },
  {
    field: 'created_by',
    label: () => i18n.t('filters.recorded_by.label'),
    type: 'select',
    params: {
      options: [
        {
          label: () => i18n.t('filters.recorded_by.params.shift'),
          value: 'shift',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.browser_stamps'),
          value: 'browser-stamps',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.location_app'),
          value: 'location-app',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.public_api'),
          value: 'public-api',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.admin'),
          value: 'admin',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.employee'),
          value: 'employee',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.import'),
          value: 'import',
        },
        {
          label: () => i18n.t('filters.recorded_by.params.actions'),
          value: 'actions',
        },
      ],
    },
  },
  {
    field: 'time',
    label: () => i18n.t('filters.time'),
    type: 'time',
  },
  {
    field: 'with_gross_time',
    label: () => i18n.t('filters.gross'),
    type: 'duration',
  },
  {
    field: 'with_break_time',
    label: () => i18n.t('filters.break'),
    type: 'duration',
  },
  {
    field: 'with_net_time',
    label: () => i18n.t('filters.net'),
    type: 'duration',
  },
]
