import { ref, isRef, watch, onMounted } from '@vue/composition-api'

export default function useCurrentNote(notes, scrollToNote) {
  const currentNote = ref(null)

  const switchToNote = (note) => {
    currentNote.value = isRef(note) ? note.value : note
    scrollToNote(currentNote)
  }

  onMounted(() => {
    watch(
      notes,
      () => {
        if (notes.value.length) {
          if (currentNote.value) {
            // check to see if current cached note still in list
            // (maybe it was deleted)
            if (!notes.value.some((n) => n.id === currentNote.value.id)) {
              switchToNote(notes.value[0])
            }
          } else {
            // no note shown currently, open first from list
            switchToNote(notes.value[0])
          }
        } else {
          // no notes to show, clear cached
          currentNote.value = null
        }
      },
      { immediate: true }
    )
  })

  return { currentNote, switchToNote }
}
