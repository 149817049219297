import { computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { TIME_FORMAT_TYPE } from '@/constants'
import { TIME_TRACKING_ACTION_SHOW_NEW_FILTER_FORM } from '../../../store/actions/time-tracking.actions.names'
import { USER_MUTATION_SWITCH_TIME_FORMAT } from '@/app/core/user/store/mutations/user.mutations.names'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

const getTimeFormatLabel = () => {
  const currentTimeFormat = computed(
    () => store.state.user.user.attributes.timeFormat
  )

  return computed(() => {
    return !currentTimeFormat.value ||
      currentTimeFormat.value === TIME_FORMAT_TYPE.STANDARD
      ? i18n.t('time_tracking.index.header.actions.switch_to_decimal_time')
      : i18n.t('time_tracking.index.header.actions.switch_to_standard_time')
  })
}

export default function useOptions(
  emit: (event: string) => void,
  canCreateTracking: boolean = false
) {
  const { $isSmallDevice } = useDeviceSize()

  const timeFormatLabel = getTimeFormatLabel()

  const switchTimeFormat = () => {
    store.commit(USER_MUTATION_SWITCH_TIME_FORMAT)
  }

  const showNewFilterForm = () => {
    store.dispatch(TIME_TRACKING_ACTION_SHOW_NEW_FILTER_FORM)
  }

  const isAssumedRoleAdmin = computed(
    () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
  )

  const actionOptions = computed(() => {
    return [
      {
        id: 'add',
        label: () => i18n.t('time_tracking.index.header.actions.add'),
        icon: 'plus/plus-16',
        clickEvent: () => emit('addTrackings'),
        hidden: !canCreateTracking || !$isSmallDevice.value,
      },
      // Hidden in desktop
      // https://app.asana.com/0/1201720894518194/1202523834697038/f
      {
        id: 'export',
        label: () => i18n.t('time_tracking.index.header.actions.export'),
        icon: 'export/export-16',
        clickEvent: () => emit('exportTrackings'),
        hidden: !$isSmallDevice.value || !isAssumedRoleAdmin.value,
      },
      {
        id: 'confirm',
        label: () => i18n.t('time_tracking.index.header.actions.confirm'),
        icon: 'tick-confirm/tick-confirm-16',
        clickEvent: () => emit('confirm-all'),
        hidden: !isAssumedRoleAdmin.value,
      },
      {
        id: 'switch',
        label: () => timeFormatLabel.value,
        icon: 'switch/switch-16',
        clickEvent: switchTimeFormat,
      },
      {
        id: 'filterTrackings',
        label: () => i18n.t('time_tracking.index.header.actions.filter'),
        icon: 'filter-funnel/filter-funnel-16',
        clickEvent: showNewFilterForm,
        hidden: !$isSmallDevice.value,
      },
    ]
  })

  const displayedActionOptions = actionOptions.value.filter(
    (option) => !option.hidden
  )

  return {
    actionOptions: displayedActionOptions,
  }
}
