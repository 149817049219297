<template>
  <div class="sticky z-8 top-16 bg-white">
    <div class="py-2 border-coal-100 border-b">
      <mobile-action-bar :spacing="4">
        <base-button type="is-filled" @click="actionsOpen = true">
          {{ $t('time_tracking.index.header_mobile.actions_title') }}
        </base-button>
        <time-tracking-date-range-picker />
        <time-tracking-tabs-header />
      </mobile-action-bar>

      <time-tracking-header-actions-mobile
        v-if="actionsOpen && !selectedItems.length"
        :can-create-tracking="canCreateTracking"
        @close="actionsOpen = false"
        @exportTrackings="openAction('exportTrackings')"
        @addTrackings="$emit('addTrackings')"
        @confirm-all="$emit('confirm-all')"
      />

      <base-group-selection-toolbar-mobile
        v-if="actionsOpen && selectedItems.length"
        :selected-items="selectedItems"
        :prevent-confirm="confirmableTrackings.length === 0"
        :prevent-destroy="preventDestroy"
        @close="actionsOpen = false"
        @confirmTrackings="
          openAction('confirmTimeTrackings', confirmableTrackings)
        "
        @assignTags="openAction('assignTags')"
        @exportRows="openAction('exportTrackings')"
        @addNote="openAction('addNote')"
        @deleteTrackings="deleteTrackings()"
      />
    </div>
    <time-tracking-filterbar />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseGroupSelectionToolbarMobile from '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarMobile'
import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar'
import TimeTrackingDateRangePicker from '../time-tracking-date-range-picker/TimeTrackingDateRangePicker'
import TimeTrackingTabsHeader from '../time-tracking-tabs-header/TimeTrackingTabsHeader'
import TimeTrackingFilterbar from '../time-tracking-filterbar/TimeTrackingFilterbar'
import TimeTrackingHeaderActionsMobile from '../time-tracking-header-actions/TimeTrackingHeaderActionsMobile'
import { TIME_TRACKING_ACTION_DELETE_LIST } from '@/app/modules/time-tracking/store/actions/time-tracking.actions.names'
import { TIME_TRACKING_STATES } from '@/app/modules/time-tracking/constants'

export default {
  name: 'TimeTrackingHeaderMobile',
  components: {
    BaseButton,
    MobileActionBar,
    BaseGroupSelectionToolbarMobile,
    TimeTrackingDateRangePicker,
    TimeTrackingTabsHeader,
    TimeTrackingFilterbar,
    TimeTrackingHeaderActionsMobile,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    canCreateTracking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionsOpen: false,
    }
  },
  computed: {
    selectedItemsIds() {
      return this.selectedItems.map((item) => item.id)
    },
    preventDestroy() {
      return this.selectedItems.some((item) => !item.permissions.destroy)
    },
    confirmableTrackings() {
      return this.selectedItems.filter(
        (item) =>
          item.current_status === TIME_TRACKING_STATES.FINISHED &&
          item.permissions.confirm &&
          !item.confirmed
      )
    },
  },
  methods: {
    ...mapActions({
      deleteTrackingsAction: TIME_TRACKING_ACTION_DELETE_LIST,
    }),
    openAction(eventName, param) {
      this.actionsOpen = false
      this.$emit(eventName, param)
    },
    async deleteTrackings() {
      await this.deleteTrackingsAction({ trackingIds: this.selectedItemsIds })
      this.actionsOpen = false
      this.$buefy.snackbar.open(
        this.$t('group_selection.action_delete_success_message')
      )
    },
  },
}
</script>
