<template>
  <portal to="modal">
    <base-modal has-modal-card @close="$emit('close')">
      <base-card-modal-form
        :title="$t('time_tracking.signatures_modal.title')"
        :button-submit-text="$t('time_tracking.signatures_modal.button_done')"
        :loading="false"
        @submit="$emit('close')"
        @close="$emit('close')"
      >
        <div v-if="actionStatus.isLoading" class="relative w-full h-16">
          <base-loading :active="true" />
        </div>
        <template v-else>
          <signature-item
            v-for="signature in signatures"
            :key="signature.id"
            :title="signature.title"
            :time="signature.created_at"
            :image="signature.image_url"
          />
        </template>
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import SignatureItem from './TimeTrackingSignaturesModalItem'

export default {
  name: 'TimeTrackingSignaturesModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    BaseLoading,
    SignatureItem,
  },
  props: {
    signatures: {
      type: Array,
      default: () => [],
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
