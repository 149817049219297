<template>
  <div class="flex items-start md:items-center">
    <base-dropdown
      :position="position"
      class="time-tracking-export-dropdown"
      expanded
    >
      <template v-slot:trigger>
        <base-button
          type="is-filled"
          dropdown
          expanded
          class="time-tracking-export-dropdown-trigger"
        >
          {{ label }}
        </base-button>
      </template>

      <base-dropdown-text-item @click="$emit('selectOption', 'xlsx')">
        <span class="font-medium text-base">Excel</span>
      </base-dropdown-text-item>

      <base-dropdown-text-item @click="$emit('selectOption', 'csv')">
        <span class="font-medium text-base">CSV</span>
      </base-dropdown-text-item>
    </base-dropdown>

    <span class="flex-1">
      {{ description }}
    </span>
  </div>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'

export default {
  name: 'TimeTrackingExportDropdown',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    BaseButton,
  },
  props: {
    position: {
      type: String,
      default: 'is-bottom-left',
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
.time-tracking-export-dropdown {
  @apply mr-4 md:mr-8 w-1/3 min-w-35;
}
.time-tracking-export-dropdown-trigger {
  @apply flex justify-between;
}
.time-tracking-export-dropdown.dropdown >>> .icon svg {
  @apply transform rotate-90;
}
.time-tracking-export-dropdown.dropdown.is-active >>> .icon svg {
  @apply transform -rotate-90 !important;
}
</style>
