<template>
  <div role="listitem" class="flex items-center mb-4 md:mb-5 md:last:mb-4.5">
    <span
      class="flex-1 font-medium text-lg text-coal-550 mr-6 md:text-base md:mr-4"
    >
      {{ title }}
    </span>
    <div class="flex-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileTableRowDetailsBodyItem',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
