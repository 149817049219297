<template>
  <component
    :is="visibleComponentName"
    :is-open="true"
    :title="title"
    :employees="payload.employees"
    :action-status="$actions.batchAddNote"
    @addEmployee="addEmployee($event)"
    @submitEmployees="submitEmployees($event)"
    @removeEmployee="removeEmployee($event)"
    @closeModal="closeModal()"
    @submit="batchAddNote($event)"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { TIME_TRACKING_ACTION_BATCH_ADD_NOTE } from '../../store/actions/time-tracking.actions.names'

const TimeTrackingNoteAddDesktop = () => import('./TimeTrackingNoteAddDesktop')
const TimeTrackingNoteAddMobile = () => import('./TimeTrackingNoteAddMobile')

export default {
  name: 'TimeTrackingNoteAdd',
  components: {
    TimeTrackingNoteAddDesktop,
    TimeTrackingNoteAddMobile,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },
  trackedActions: {
    batchAddNote: TIME_TRACKING_ACTION_BATCH_ADD_NOTE,
  },
  data() {
    return {
      payload: {
        employees: [],
      },
    }
  },
  computed: {
    visibleComponentName() {
      return this.$isSmallDevice
        ? TimeTrackingNoteAddMobile
        : TimeTrackingNoteAddDesktop
    },
    title() {
      if (this.selectedItems.length > 1) {
        return this.$isSmallDevice
          ? this.$t(
              'time_tracking.add_notes_modal.to_multiple_trackings_title_mobile',
              {
                trackings: this.selectedItems.length,
              }
            )
          : this.$t(
              'time_tracking.add_notes_modal.to_multiple_trackings_title',
              {
                trackings: this.selectedItems.length,
              }
            )
      }

      return this.$t('time_tracking.add_notes_modal.to_single_tracking_title')
    },
  },
  methods: {
    ...mapActions({
      async batchAddNote(dispatch, { content, attachments }) {
        const trackingIds = this.selectedItems.map((item) => item.id)

        await dispatch(TIME_TRACKING_ACTION_BATCH_ADD_NOTE, {
          attachments,
          noteContent: content,
          trackingIds,
        })

        this.$buefy.snackbar.open(
          this.$t(
            'time_tracking.add_notes_modal.to_multiple_trackings_success_message'
          )
        )

        this.closeModal()
      },
    }),
    closeModal() {
      this.$emit('closeModal')
    },
    addEmployee(employee) {
      this.payload.employees.push(employee)
    },
    removeEmployee(index) {
      this.payload.employees.splice(index, 1)
    },
    submitEmployees(employees) {
      this.payload.employees = [...employees]
    },
  },
}
</script>
