<template>
  <div class="flex">
    <template v-if="$isSmallDevice || $isMediumDevice">
      <span
        class="text-coal-450 mr-2 flex items-center cursor-default"
        data-id="time_tracking.index.body.recorded_by_icon"
      >
        <base-icon :icon="recordedByData.icon" size="1rem" />
      </span>
      <table-row-cell-default
        :value="recordedByData.label"
        :archived="archived"
      />
    </template>
    <div v-else class="flex items-center">
      <base-tooltip
        class="ml-1"
        type="is-white"
        position="is-top"
        :animated="false"
        :label="recordedByTooltip"
      >
        <base-table-row-cell-icon
          :icon-name="recordedByData.icon"
          data-id="time_tracking.index.body.recorded_by_icon"
          class="md:py-0 lg:py-2.5 cursor-default"
          :icon-type="baseTableRowCellIconType"
        />
      </base-tooltip>
    </div>
  </div>
</template>

<script>
import TableRowCellDefault from '../table-row-cell-default/TableRowCellDefault'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseTableRowCellIcon from '@/app/util-modules/ui/table-row-cell-icon/BaseTableRowCellIcon'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'

export default {
  name: 'TableRowCellRecordedBy',
  components: {
    TableRowCellDefault,
    BaseIcon,
    BaseTableRowCellIcon,
    BaseTooltip,
  },
  props: {
    recordedBy: {
      type: String,
      default: '',
    },
    archived: {
      type: Boolean,
      default: false,
    },
    lastEditorUsername: {
      type: String,
      default: '',
    },
  },
  computed: {
    recordedByData() {
      switch (this.recordedBy) {
        case 'location-app':
          return {
            label: this.$t('time_tracking.index.body.recorded_by.location_app'),
            icon: 'phone-mobile/phone-mobile-16',
          }
        case 'browser-stamps':
          return {
            label: this.$t(
              'time_tracking.index.body.recorded_by.browser_stamps'
            ),
            icon: 'time-tracking/time-tracking-16',
          }
        case 'shift':
          return {
            label: this.$t('time_tracking.index.body.recorded_by.shift'),
            icon: 'calendar/calendar-16',
          }
        case 'merge-handler':
          return {
            label: this.$t(
              'time_tracking.index.body.recorded_by.merge_handler'
            ),
            icon: 'calendar/calendar-16',
          }
        case 'admin':
          return {
            label: this.lastEditorUsername
              ? this.lastEditorUsername
              : this.$t('time_tracking.index.body.recorded_by.admin'),
            icon: 'user/user-16',
          }
        case 'employee':
          return {
            label: this.lastEditorUsername
              ? this.lastEditorUsername
              : this.$t('time_tracking.index.body.recorded_by.employee'),
            icon: 'user/user-16',
          }
        case 'public-api':
          return {
            label: this.$t('time_tracking.index.body.recorded_by.public_api'),
            icon: 'actions/actions-16',
          }
        case 'import':
          return {
            label: this.$t('time_tracking.index.body.recorded_by.import'),
            icon: 'download/download-16',
          }
        case 'actions':
          return {
            label: this.$t('time_tracking.index.body.recorded_by.actions'),
            icon: 'actions/actions-16',
          }
        default:
          return {
            label: this.$t('time_tracking.index.body.recorded_by.admin'),
            icon: 'user/user-16',
          }
      }
    },
    recordedByTooltip() {
      return `${this.$t(
        'time_tracking.index.body.recorded_by.tooltip_label'
      )}: ${this.recordedByData.label}`
    },
    baseTableRowCellIconType() {
      return this.recordedByData.icon.includes('time-tracking')
        ? 'is-filled'
        : 'is-stroke'
    },
  },
}
</script>
