import { computed, watch } from '@vue/composition-api'
import realStore from '@/store'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../../time-tracking.module'
import { BASE_GETTER_IS_SMALL_DEVICE } from '@/store/getters/store.getters.names'
import {
  TIME_TRACKING_NOTE_ACTION_LIST,
  TIME_TRACKING_NOTE_ACTION_LIST_APPEND,
} from '../../../store/actions/time-tracking.actions.names'
import {
  NOTES_MODAL_ITEMS_PER_PAGE,
  NOTES_BOTTOM_SHEET_ITEMS_PER_PAGE,
} from '../../../models/time-tracking-note.model'

export default function useFetchNotes(timeTrackingId, noteType) {
  const store =
    process.env.VUE_APP_MODE === 'test' ? this._storeMock : realStore

  const meta = computed(
    () => store.state[TIME_TRACKING_MODULE_NAME].noteCurrentMeta
  )
  const notesPerPage = store.getters[BASE_GETTER_IS_SMALL_DEVICE]
    ? NOTES_BOTTOM_SHEET_ITEMS_PER_PAGE
    : NOTES_MODAL_ITEMS_PER_PAGE

  const fetchAppendNotes = async () => {
    if (meta.value.current_page >= meta.value.total_pages) {
      return
    }

    await store.dispatch(TIME_TRACKING_NOTE_ACTION_LIST_APPEND, {
      timeTrackingId: timeTrackingId.value,
      type: noteType.value,
      pagination: {
        page: meta.value.current_page + 1,
        items: notesPerPage,
      },
    })
  }

  const fetchNotes = () =>
    store.dispatch(TIME_TRACKING_NOTE_ACTION_LIST, {
      timeTrackingId: timeTrackingId.value,
      type: noteType.value,
      pagination: {
        page: 1,
        items: notesPerPage,
      },
    })

  // reset note list when system notes toggled
  watch(() => noteType.value, fetchNotes)

  return {
    notesPerPage,
    fetchNotes,
    fetchAppendNotes,
  }
}
