import BaseRequest from '@/utils/api/base-request'
import { TIME_TRACKING_ENDPOINT_LIST_ATTACHMENTS } from '../network/time-tracking.endpoints'
export const NOTE_ATTACHMENT_TYPE = 'attachment'

export interface AttachementListRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
}
export class TimeTrackingAttachmentListRequest extends BaseRequest {
  constructor({ workspaceId, timeTrackingId }: AttachementListRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_LIST_ATTACHMENTS(
      workspaceId,
      timeTrackingId
    )
    super.type = 'attachment'
  }
}
