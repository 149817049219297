<template>
  <div v-if="showFilters" class="mb-3 flex">
    <span class="w-1/3 md:w-1/5 mr-4">
      {{ $t('time_tracking.export_modal.filter') }}
    </span>
    <p class="text-coal-550 flex-1">
      <span v-if="showTabFilter">
        <span class="capitalize">{{ activeTab }}</span>
        {{ $t('time_tracking.export_modal.trackings') }}
        {{ showFilterbarFilters && ', ' }}
      </span>
      <span v-if="showFilterbarFilters">{{ displayedFilters }}</span>
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../time-tracking.module'
import availableFilters from '../time-tracking-filterbar/available-filters'
import filterbarUtils from '@/utils/filterbar.utils'
import { runOrReturn } from '@/utils/helpers'

export default {
  name: 'TimeTrackingAppliedFilterbarFilters',
  computed: {
    ...mapState(TIME_TRACKING_MODULE_NAME, {
      appliedFilters: (state) => state.timeTrackingFilterbarFilters,
      appliedFiltersValues: (state) => state.timeTrackingFilterbarValues,
      activeTab: (state) => state.timeTrackingTab,
    }),
    showFilters() {
      return this.showFilterbarFilters || this.showTabFilter
    },
    showTabFilter() {
      return this.activeTab !== '' && this.activeTab !== 'all'
    },
    showFilterbarFilters() {
      return this.displayedFilters !== ''
    },
    displayedFilters() {
      return this.appliedFilters
        .map((appliedFilter) => {
          const filter = availableFilters.find((f) => f.field === appliedFilter)
          const filterUtil = filterbarUtils[`${filter.type}Filter`]
          const appliedFilterValue = this.appliedFiltersValues[appliedFilter]

          const functionArgs =
            filter.type === 'select'
              ? [appliedFilterValue, filter.params]
              : [appliedFilterValue]

          return `
          ${runOrReturn(filter.label)}
          ${filterUtil.getDisplayedValue(...functionArgs)}
        `
        })
        .join(', ')
    },
  },
}
</script>
