import { MODULE_NAME } from '../time-tracking.module'
import TimeTrackingTabsTopbar from '../components/time-tracking-tabs/TimeTrackingTabsTopbar'

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout'
  )

export default [
  {
    path: '/workspaces/:workspaceId/time-trackings',
    name: `${MODULE_NAME}.index`,
    meta: {
      feature: MODULE_NAME,
      layout: DefaultLayout,
    },
    components: {
      'topbar-left': TimeTrackingTabsTopbar,
      default: () => import('../pages/PageTimeTracking'),
    },
  },
]
