import i18n from '@/utils/vendors/i18n/index'
import store from '@/store'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { TIME_TRACKING_NOTE_ACTION_DELETE } from '../../../store/actions/time-tracking.actions.names'

export default function useDeleteNote(timeTrackingId, fetchNotes) {
  const deleteNote = async (note) => {
    await store.dispatch(TIME_TRACKING_NOTE_ACTION_DELETE, {
      timeTrackingId: timeTrackingId.value,
      noteId: note.value.id,
    })
    await fetchNotes()
    Snackbar.open(i18n.tc('time_tracking.notes_modal.note_deleted'))
  }

  return { deleteNote }
}
