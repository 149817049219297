<template>
  <div :class="wrapperClass">
    <body-item
      v-if="!$isMediumDevice"
      :title="$t('time_tracking.index.body.row_cells.areas')"
    >
      <table-row-cell-default :value="area" :archived="details.archived" />
    </body-item>

    <body-item :title="$t('time_tracking.index.body.row_cells.tags')">
      <table-row-cell-default :value="tags" :archived="details.archived" />
    </body-item>

    <body-item
      v-if="!$isMediumDevice"
      :title="$t('time_tracking.index.body.row_details.date')"
    >
      <table-row-cell-default
        :value="details.dateValue"
        :archived="details.archived"
      />
    </body-item>

    <body-item :title="$t('time_tracking.index.body.row_details.time')">
      <table-row-cell-default
        :value="getTimeValue"
        :archived="details.archived"
      />
    </body-item>

    <body-item :title="$t('time_tracking.index.body.row_details.gross')">
      <table-row-cell-default
        :value="grossTimeValue"
        :archived="details.archived"
      />
    </body-item>

    <body-item
      class="table-row-cell-break-wrapper"
      :title="$t('time_tracking.index.body.row_details.break')"
    >
      <table-row-cell-break
        :breaks-sum="details.break_time"
        :breaks="details.breaks"
        :archived="details.archived"
      />
    </body-item>

    <body-item
      v-if="!$isMediumDevice"
      :title="$t('time_tracking.index.body.row_details.net')"
    >
      <table-row-cell-default
        :value="netTimeValue"
        :archived="details.archived"
      />
    </body-item>

    <body-item :title="$t('time_tracking.index.body.row_details.tracked_by')">
      <table-row-cell-recorded-by
        :recorded-by="details.created_by"
        :archived="details.archived"
        :last-editor-username="lastEditorUsername"
      />
    </body-item>
    <body-item
      v-if="!$isMediumDevice"
      :title="$t('time_tracking.index.body.row_details.confirmation')"
    >
      <table-row-cell-confirmation
        :status="details.current_status"
        :confirmed="details.confirmed"
        :archived="details.archived"
        font-weight-class="font-medium"
      />
    </body-item>
  </div>
</template>

<script>
// TODO: use base component: bottom-sheet/BottomSheetRow
import MobileTableRowDetailsBodyItem from './MobileTableRowDetailsBodyItem'
import TableRowCellConfirmation from '../table-row-cell-confirmation/TableRowCellConfirmation'
import TableRowCellBreak from '../table-row-cell-break/TableRowCellBreak'
import TableRowCellRecordedBy from '../table-row-cell-recorded-by/TableRowCellRecordedBy'
import TableRowCellDefault from '../table-row-cell-default/TableRowCellDefault'
import {
  formatTimeCell,
  formatViaRosterTimeCell,
  getDurationBasedOnUserSetting,
} from '@/utils/date-time.utils'
import { mapState } from 'vuex'
import {
  TIME_TRACKING_CREATED_BY,
  TIME_TRACKING_STATES,
} from '@/app/modules/time-tracking/constants'

export default {
  name: 'MobileTableRowDetailsBody',
  components: {
    'body-item': MobileTableRowDetailsBodyItem,
    TableRowCellDefault,
    TableRowCellConfirmation,
    TableRowCellBreak,
    TableRowCellRecordedBy,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentUser: ({ user }) => user.user,
    }),
    area() {
      return this.details.area?.name || '--'
    },
    tags() {
      return this.details.tags?.length > 0
        ? this.details.tags.map((t) => t.title).join(', ')
        : '--'
    },
    wrapperClass() {
      return this.details.archived
        ? 'px-4 pt-4 pb-1 md:px-2 md:pt-2'
        : 'p-4 pb-0 md:p-2 md:pb-0'
    },
    lastEditorUsername() {
      return this.details.last_editor?.username
        ? this.details.last_editor.username
        : ''
    },
    grossTimeValue() {
      return getDurationBasedOnUserSetting(
        this.details.gross_time,
        this.currentUser.attributes.timeFormat
      )
    },
    netTimeValue() {
      return getDurationBasedOnUserSetting(
        this.details.net_time,
        this.currentUser.attributes.timeFormat
      )
    },
    getTimeValue() {
      const details = this.details ? this.details : {}
      return this.details.current_status === TIME_TRACKING_STATES.RUNNING &&
        (this.details.created_by === TIME_TRACKING_CREATED_BY.SHIFT ||
          this.details.created_by === TIME_TRACKING_CREATED_BY.MERGE_HANDLER)
        ? formatViaRosterTimeCell(details)
        : formatTimeCell(details)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .table-row-cell-break-wrapper {
  .table-row-cell-break {
    @apply sm:flex-row;
  }

  .info-icon-wrapper {
    @apply m-0 flex;
  }
}
</style>
