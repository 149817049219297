<template>
  <base-tabs-header
    :tabs="tabs"
    :active-tab="activeTab"
    icon="time-tracking/time-tracking-24"
    @change="changeTab($event)"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../time-tracking.module'
import BaseTabsHeader from '@/app/util-modules/ui/tabs/BaseTabsHeader.vue'

import { TIME_TRACKING_MUTATION_SET_CURRENT_TAB } from '../../store/mutations/time-tracking.mutations.names'
import { TIME_TRACKING_TABS } from '@/app/modules/time-tracking/constants'

export default {
  name: 'TimeTrackingTabsHeader',
  components: { BaseTabsHeader },
  computed: {
    ...mapState(TIME_TRACKING_MODULE_NAME, {
      meta: (state) => state.timeTrackingAllMeta,
      activeTab: (state) => state.timeTrackingTab,
    }),
    tabs() {
      return [
        {
          id: TIME_TRACKING_TABS.ALL,
          label: this.$t('time_tracking.tabs.all'),
          labelTrigger: this.$t('time_tracking.tabs.all_time_trackings'),
          dataIdAttr: 'time_tracking.tabs.all',
          count: `${this.meta.total_count}`,
        },
        {
          id: TIME_TRACKING_TABS.UNCONFIRMED,
          label: this.$t('time_tracking.tabs.unconfirmed'),
          labelTrigger: this.$t(
            'time_tracking.tabs.unconfirmed_time_trackings'
          ),
          dataIdAttr: 'time_tracking.tabs.unconfirmed',
          count: `${this.meta.total_unconfirmed}`,
          badgeType: 'is-warning',
        },
        {
          id: TIME_TRACKING_TABS.CONFIRMED,
          label: this.$t('time_tracking.tabs.confirmed'),
          labelTrigger: this.$t('time_tracking.tabs.confirmed_time_trackings'),
          dataIdAttr: 'time_tracking.tabs.confirmed',
          count: `${this.meta.total_confirmed}`,
          badgeType: 'is-success',
        },
      ]
    },
  },
  methods: {
    ...mapMutations({
      switchTab: TIME_TRACKING_MUTATION_SET_CURRENT_TAB,
    }),
    changeTab(id) {
      this.switchTab({ tab: id })
    },
  },
}
</script>
