<template>
  <div class="flex flex-col">
    <div class="flex mb-6 md:items-center md:flex-row sm:flex-col">
      <div class="flex-1 md:mb-0 md:mr-6 sm:mb-6">
        <time-tracking-read-only-field
          v-if="!isAdmin && $isSmallDevice"
          :label="
            $t('time_tracking.add_modal.form_employee_search_field_label')
          "
          :value="employeePreviewName"
        />
        <employee-search
          v-else-if="isAdmin"
          v-model="passedValue.employee"
          :label="
            $t('time_tracking.add_modal.form_employee_search_field_label')
          "
          :placeholder="
            $t('time_tracking.add_modal.form_employee_search_field_placeholder')
          "
          :infinite-scroll="true"
          :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
          data-id="time_tracking.add_modal.form_employee_search_field_input"
          name="employee"
          icon="arrow"
          dropdown
          validation-mode="lazy"
          :validation-rules="employeeSearchValidationRule"
        />
        <time-tracking-read-only-field
          v-else
          :label="
            $t('time_tracking.add_modal.form_employee_search_field_label')
          "
          :value="employeePreviewName"
        />
      </div>
      <div class="flex-1">
        <area-search
          v-if="canEditAreas"
          v-model="passedValue.area"
          data-id="time_tracking_add_form_area"
          :label="$t('time_tracking.add_modal.form_area_search_field_label')"
          :placeholder="
            $t('time_tracking.add_modal.form_area_search_field_placeholder')
          "
          :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
          name="area"
          icon="arrow"
          dropdown
        />
        <time-tracking-read-only-field
          v-else
          :label="$t('time_tracking.add_modal.form_area_search_field_label')"
          :value="areasName"
        />
      </div>
    </div>
    <div class="mb-6 md:mb-8">
      <!-- TODO: this is temporary client-side validation, until server-side implemented -->
      <!-- https://app.asana.com/0/0/1200988132990828/1201053618266266/f -->
      <base-field
        v-if="canEditWorkingTime"
        v-slot="{ validate }"
        name="tracking_date_time_range"
        :validation-rules="trackingDateValidationRule"
      >
        <base-date-time-range-picker
          v-model="timeTrackingDateTimeRange"
          name="tracking_date_time_range"
          :range-start-label="
            $t('time_tracking.add_modal.form_tracking_date_range_start_label')
          "
          :range-end-label="
            $t('time_tracking.add_modal.form_tracking_date_range_end_label')
          "
          :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
          data-id-prefix="time_tracking.add_modal.form"
          append-to-body
          :end-date-shown="canHideEndDate"
          :end-date-disabled="passedValue.isRunning"
          :can-edit-working-time="canEditWorkingTime"
          :is-twenty-four-hours-flag="isTwentyFourHoursFlag"
          @input="validate($event)"
          @change-start="handleStartChange($event)"
        />
      </base-field>

      <div
        v-else
        class="start-time-read-only flex md:items-center md:flex-row sm:flex-col"
      >
        <time-tracking-read-only-field
          class="flex-1 md:mb-0 md:mr-6 sm:mb-6"
          :label="
            $t('time_tracking.add_modal.form_tracking_date_range_start_label')
          "
          :value="readOnlyStartDayValue"
          icon
          custom-background
        />
        <time-tracking-read-only-field
          v-if="!isRunning"
          class="flex-1"
          :label="
            $t('time_tracking.add_modal.form_tracking_date_range_end_label')
          "
          :value="readOnlyEndDayValue"
          icon
          custom-background
        />
      </div>
    </div>

    <div v-if="canEditWorkingTime" class="breaks">
      <div v-if="validBreaks.length" class="mb-6 md:mb-5">
        <div class="md:mb-2.5 sm:mb-5">
          <base-message
            v-if="breakValidationError"
            data-id="break_validation_error"
            type="is-danger"
            class="text-center"
          >
            {{ breakValidationError }}
          </base-message>

          <time-tracking-form-break
            v-for="breakItem in validBreaks"
            :key="breakItem.id"
            :break-item="breakItem"
            :is-same-day-time-tracking="isSameDayTimeTracking"
            @update-break="updateBreak($event)"
            @remove="removeBreak($event)"
          />
        </div>
        <base-checkbox
          v-if="isAdmin"
          v-model="passedValue.enforceExactBreakDuration"
          class="time-tracking-form-checkbox-exact-break"
          :size="!$isSmallDevice ? 'is-small' : 'is-medium'"
        >
          {{
            $t(
              'time_tracking.add_modal.form_tracking_break_exact_duration_checkbox_text'
            )
          }}
        </base-checkbox>
      </div>

      <div>
        <base-button
          v-if="!isRunningTimeTracking"
          :size="!$isSmallDevice ? 'is-small' : 'is-medium'"
          type="is-filled-grey"
          data-id="time_tracking.add_modal.form_tracking_break_add_button"
          @click="addBreak()"
        >
          {{
            $t('time_tracking.add_modal.form_tracking_break_add_button_text')
          }}
        </base-button>
      </div>
    </div>

    <div v-else>
      <div v-if="validBreaks.length" class="break-readonly sm:mt-3">
        <div class="break-read-only-header flex md:items-center md:flex-row">
          <h3 class="flex-1 md:mb-0 md:mr-6 font-semibold">{{
            $t(
              'time_tracking.add_modal.form_tracking_break_date_range_start_label'
            )
          }}</h3>
          <h3 class="flex-1 font-semibold">{{
            $t(
              'time_tracking.add_modal.form_tracking_break_date_range_end_label'
            )
          }}</h3>
        </div>
        <div
          v-for="(breakTime, index) in getReadOnlyBreakTimeDateHoursAndMinutes"
          :key="`break-${index}`"
          class="break-read-only flex md:items-center md:flex-row -mt-5"
        >
          <time-tracking-read-only-field
            class="flex-1 md:mb-0 md:mr-6"
            label=""
            :value="`${breakTime.startDateTime.date} \u00A0 ${breakTime.startDateTime.time.hours}:${breakTime.startDateTime.time.minutes}`"
          />
          <time-tracking-read-only-field
            class="flex-1"
            label=""
            :value="`${breakTime.endDateTime.date} \u00A0 ${breakTime.endDateTime.time.hours}:${breakTime.endDateTime.time.minutes}`"
          ></time-tracking-read-only-field>
        </div>
      </div>
      <time-tracking-read-only-field
        v-else
        class="flex-1 md:mt-5 sm:mt-3"
        :label="$t('filters.break')"
        value="--"
      />
    </div>

    <template v-if="true">
      <hr class="base-divider my-6" />

      <div v-if="canEditTags">
        <tag-search
          v-model="passedValue.tags"
          :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
          data-id="time_tracking_add_form_tags"
          name="tags"
          :label="$t('time_tracking.add_modal.form_tag_search_field_label')"
          :placeholder="
            $t('time_tracking.add_modal.form_tag_search_field_placeholder')
          "
          direction="top"
        />
      </div>
      <time-tracking-read-only-field
        v-else
        :label="$t('time_tracking.add_modal.form_tag_search_field_label')"
        :value="tagsName"
      />
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { setHours, startOfDay, isSameDay, isFuture } from 'date-fns'
import uniqueId from 'lodash/uniqueId'
import BaseField from '@/app/util-modules/ui/field/BaseField'
import BaseDateTimeRangePicker from '@/app/util-modules/ui/date-time-picker/BaseDateTimeRangePicker'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox'
import BaseMessage from '@/app/util-modules/ui/message/BaseMessage'
import AreaSearch from '@/app/core/area/components/area-search/AreaSearch'
import EmployeeSearch from '@/app/core/employee/components/employee-search/EmployeeSearch'
import TagSearch from '@/app/core/tag/components/tag-search/TagSearch'
import TimeTrackingFormBreak from './TimeTrackingFormBreak'
import {
  formatBreakTime,
  formatDateTime,
  isValidDate,
} from '@/utils/date-time.utils'
import { extend } from 'vee-validate'
import i18n from '@/utils/vendors/i18n'
import TimeTrackingReadOnlyField from '@/app/modules/time-tracking/components/time-tracking-form/TimeTrackingReadOnlyField'
import { mapGetters } from 'vuex'
import {
  USER_GETTER_FEATURE_PERMISSIONS,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'
import { WORKSPACE_GETTER_USER_CURRENT_WORKSPACE } from '@/app/core/workspace/store/getters/workspace.getters.names.js'
import {
  TIME_TRACKING_CREATED_BY,
  TIME_TRACKING_MODULE,
  TIME_TRACKING_STATES,
} from '@/app/modules/time-tracking/constants'

// TODO: this is temporary client-side validation, until server-side implemented
// https://app.asana.com/0/0/1200988132990828/1201053618266266/f
extend('tracking_date', {
  params: [
    'isRunning',
    'createdBy',
    'currentStatus',
    'isTwentyFourHoursFlag',
    'startDateValidator',
  ],
  validate: (
    { startDateTime, endDateTime },
    {
      createdBy,
      isRunning,
      currentStatus,
      isTwentyFourHoursFlag,
      startDateValidator,
    }
  ) => {
    if (
      isFuture(startDateTime) &&
      (isRunning || currentStatus === TIME_TRACKING_STATES.BREAKED) &&
      createdBy === TIME_TRACKING_CREATED_BY.ACTIONS
    ) {
      return i18n.t(
        'time_tracking.add_modal.validation_message.start_not_in_the_future'
      )
    }

    if (isRunning) {
      if (
        !isTwentyFourHoursFlag &&
        !isSameDay(startDateValidator, startDateTime)
      ) {
        return i18n.t(
          'time_tracking.add_modal.validation_message.not_in_the_past'
        )
      }
    }

    if (!isRunning) {
      if (isFuture(startDateTime) || isFuture(endDateTime)) {
        return i18n.t(
          'time_tracking.add_modal.validation_message.not_in_the_future'
        )
      }
    }

    if (!isRunning && startDateTime > endDateTime) {
      return i18n.t(
        'time_tracking.add_modal.validation_message.start_date_earlier_than_end_date'
      )
    }

    return true
  },
})

extend('employee_search', {
  params: ['isFieldValid'],
  validate: (_, { isFieldValid }) => {
    if (isFieldValid) return true
    return i18n.t('time_tracking.add_modal.form_employee_search_field_error')
  },
})

function startOfWorkingDay(date = new Date()) {
  return setHours(startOfDay(date), 9)
}

function endOfWorkingDay(date = new Date()) {
  return setHours(startOfDay(date), 17)
}

export default {
  name: 'TimeTrackingForm',
  components: {
    TimeTrackingReadOnlyField,
    BaseField,
    TagSearch,
    EmployeeSearch,
    AreaSearch,
    BaseCheckbox,
    BaseButton,
    BaseDateTimeRangePicker,
    BaseMessage,
    TimeTrackingFormBreak,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
      validator(form) {
        const isValidStart =
          typeof form.startDateTime === 'undefined' ||
          isValidDate(form.startDateTime)
        const isValidEnd =
          typeof form.endDateTime === 'undefined' ||
          form.endDateTime === null ||
          isValidDate(form.endDateTime)
        const isValidBreaks = form.breaks.every((breakItem) => {
          return (
            isValidDate(breakItem.startDateTime) &&
            isValidDate(breakItem.endDateTime)
          )
        })

        return isValidStart && isValidEnd && isValidBreaks
      },
    },
    actionStatus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDateValidator: null,
    }
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
      currentWorkspace: WORKSPACE_GETTER_USER_CURRENT_WORKSPACE,
    }),
    employeePreviewName() {
      if (this.details.user) {
        return `${this.details.user['username']}`
      }
      if (this.passedValue.employee) {
        return `${this.passedValue.employee['username']}`
      }
      if (this.user) {
        return `${this.user.attributes['username']}`
      }
      return ''
    },
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    canHideEndDate() {
      return (
        (this.details.created_by === TIME_TRACKING_CREATED_BY.LOCATION_APP ||
          this.details.created_by === TIME_TRACKING_CREATED_BY.ACTIONS) &&
        (this.details.current_status === TIME_TRACKING_STATES.RUNNING ||
          this.details.current_status === TIME_TRACKING_STATES.BREAKED)
      )
    },
    isTwentyFourHoursFlag() {
      return store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        TIME_TRACKING_MODULE
      ).company_allows_24h_shifts
    },
    isRunning() {
      return !!this.passedValue.isRunning
    },
    trackingDateValidationRule() {
      return {
        tracking_date: {
          isRunning: this.passedValue.isRunning,
          createdBy: this.passedValue.createdBy,
          currentStatus: this.passedValue.currentStatus,
          isTwentyFourHoursFlag: this.isTwentyFourHoursFlag,
          startDateValidator: this.startDateValidator,
        },
      }
    },
    employeeSearchValidationRule() {
      return {
        required: !this.passedValue.employee,
        employee_search: {
          isFieldValid: Boolean(this.passedValue.employee),
        },
      }
    },
    timeTrackingDateTimeRange: {
      get() {
        const { startDateTime, endDateTime } = this.passedValue

        return {
          startDateTime,
          endDateTime,
        }
      },
      set(newDateTimeRange) {
        const { startDateTime, endDateTime } = newDateTimeRange

        this.passedValue = {
          ...this.passedValue,
          startDateTime,
          endDateTime,
        }
      },
    },
    isSameDayTimeTracking() {
      return isSameDay(
        new Date(this.timeTrackingDateTimeRange.startDateTime),
        new Date(this.timeTrackingDateTimeRange.endDateTime)
      )
    },
    validBreaks() {
      return this.passedValue.breaks.filter((breakItem) => !breakItem.deleted)
    },
    breakValidationError() {
      if (this.actionStatus.isFailed) {
        return this.actionStatus.validationMessages.get(
          'working_session_pauses.base'
        )
      }
      return null
    },
    areasName() {
      return this.passedValue.area ? this.passedValue.area.name : '--'
    },
    tagsName() {
      return this.passedValue.tags.length
        ? this.passedValue.tags.map((tag) => tag.title).join(', ')
        : '--'
    },
    canEditWorkingTime() {
      if (this.editMode) {
        return !!(this.isAdmin || this.details.permissions.update)
      }
      return true
    },
    canEditAreas() {
      if (this.editMode) {
        return !!(this.isAdmin || this.details.permissions.update_area)
      }
      return true
    },
    canEditTags() {
      if (this.editMode) {
        return !!(this.isAdmin || this.details.permissions.update_tags)
      }
      return true
    },
    getReadOnlyStartDay() {
      return formatDateTime(
        new Date(this.timeTrackingDateTimeRange.startDateTime)
      )
    },
    getReadOnlyStartTime() {
      return {
        hours: this.timeTrackingDateTimeRange.startDateTime.getHours(),
        minutes:
          this.timeTrackingDateTimeRange.startDateTime.getMinutes() < 10
            ? `0${this.timeTrackingDateTimeRange.startDateTime.getMinutes()}`
            : this.timeTrackingDateTimeRange.startDateTime.getMinutes(),
      }
    },
    readOnlyStartDayValue() {
      return `${this.getReadOnlyStartDay} | ${this.getReadOnlyStartTime.hours}:${this.getReadOnlyStartTime.minutes}`
    },
    getReadOnlyEndDay() {
      return formatDateTime(
        new Date(this.timeTrackingDateTimeRange.endDateTime)
      )
    },
    getReadOnlyEndTime() {
      if (this.passedValue.isRunning) {
        return null
      }
      return {
        hours: this.timeTrackingDateTimeRange.endDateTime.getHours(),
        minutes:
          this.timeTrackingDateTimeRange.endDateTime.getMinutes() < 10
            ? `0${this.timeTrackingDateTimeRange.endDateTime.getMinutes()}`
            : this.timeTrackingDateTimeRange.endDateTime.getMinutes(),
      }
    },
    readOnlyEndDayValue() {
      if (this.passedValue.isRunning) {
        return '--'
      }
      return `${this.getReadOnlyEndDay} | ${this.getReadOnlyEndTime.hours}:${this.getReadOnlyEndTime.minutes}`
    },
    getReadOnlyBreakTimeDateHoursAndMinutes() {
      return this.validBreaks.map((breakItem) => {
        return {
          startDateTime: {
            date: formatDateTime(new Date(breakItem.startDateTime)),
            time: formatBreakTime(breakItem.startDateTime),
          },
          endDateTime: {
            date: formatDateTime(new Date(breakItem.endDateTime)),
            time: formatBreakTime(breakItem.endDateTime),
          },
        }
      })
    },
    isRunningTimeTracking() {
      return (
        this.details.created_by === TIME_TRACKING_CREATED_BY.ACTIONS &&
        (this.details.current_status === TIME_TRACKING_STATES.RUNNING ||
          this.details.currentStatus === TIME_TRACKING_STATES.BREAKED)
      )
    },
  },
  watch: {
    value: {
      handler() {
        this.$emit('updated')
      },
      deep: true,
    },
  },
  created() {
    this.startDateValidator =
      this.passedValue.startDateTime ?? startOfWorkingDay()
    this.passedValue.startDateTime =
      this.passedValue.startDateTime ?? startOfWorkingDay()

    this.passedValue.endDateTime =
      this.passedValue.endDateTime ?? endOfWorkingDay()
  },
  methods: {
    addBreak() {
      const breakItem = {
        id: uniqueId(),
        existing: false,
        deleted: false,
        startDateTime: this.passedValue.startDateTime ?? startOfWorkingDay(),
        endDateTime: this.passedValue.endDateTime ?? endOfWorkingDay(),
      }

      this.passedValue.breaks.push(breakItem)

      return breakItem
    },
    removeBreak(id) {
      const removedBreak = this.passedValue.breaks.find(
        (item) => item.id === id
      )
      const newBreakList = this.passedValue.breaks.filter(
        (item) => item.id !== id
      )

      this.passedValue.breaks = removedBreak.existing
        ? [...newBreakList, { ...removedBreak, deleted: true }]
        : newBreakList
    },
    updateBreak(newBreak) {
      this.passedValue.breaks = this.passedValue.breaks.map((breakItem) => {
        if (breakItem.id === newBreak.id) return newBreak

        return breakItem
      })
    },
    handleStartChange(startDateTime) {
      if (!this.isTwentyFourHoursFlag) {
        this.passedValue.endDateTime = this.passedValue.startDateTime
        this.passedValue = {
          ...this.passedValue,
          startDateTime,
          endDateTime: endOfWorkingDay(startDateTime),
        }
      } else {
        this.passedValue = {
          ...this.passedValue,
          startDateTime,
        }
      }
    },
  },
}
</script>

<style scoped>
.b-checkbox.checkbox.time-tracking-form-checkbox-exact-break {
  @apply md:text-coal-550 text-coal font-medium md:text-base text-lg;
}
</style>
