import axios from '@/utils/vendors/axios'
import {
  StopTimeTrackingBreakRequest,
  StartTimeTrackingBreakRequest,
} from '../models/time-tracking-break.model'
import {
  TimeTracking,
  TimeTrackingRequest,
  TimeTrackingListRequest,
  TimeTrackingConfirmRequest,
  TimeTrackingDeleteRequest,
  TimeTrackingCreateRequest,
  TimeTrackingUpdateRequest,
  TimeTrackingUpdateStartAtRequest,
  ActiveTimeTrackingRequest,
  StartTimeTrackingRequest,
  StopTimeTrackingRequest,
  TimeTrackingListRequestParams,
  TimeTrackingRequestParams,
  TimeTrackingConfirmRequestParams,
  TimeTrackingDeleteRequestParams,
} from '../models/time-tracking.model'
import {
  AttachementListRequestParams,
  TimeTrackingAttachmentListRequest,
} from '../models/time-tracking-attachment.model'
import {
  TimeTrackingNoteListRequest,
  TimeTrackingNoteAddRequest,
  TimeTrackingNoteUpdateRequest,
  TimeTrackingNoteDeleteRequest,
  NoteListRequestParams,
  NoteAddRequestParams,
  NoteUpdateRequestParams,
  NoteDeleteRequestParams,
} from '../models/time-tracking-note.model'
import {
  TimeTrackingFollowerListRequest,
  TimeTrackingFollowerAddRequest,
  TimeTrackingFollowerDeleteRequest,
  FollowerListRequestParams,
  FollowerAddRequestParams,
  FollowerDeleteRequestParams,
} from '../models/time-tracking-follower.model'
import {
  SignatureListRequestParams,
  TimeTrackingSignatureList,
} from '../models/time-tracking-signature.model'
import {
  TimeTrackingBatchConfirmRequest,
  TimeTrackingBatchDeleteRequest,
  TimeTrackingBatchAssignTagsRequest,
  TimeTrackingBatchAddNoteRequest,
  TimeTrackingBatchAssignTagsRequestParams,
} from '../models/time-tracking-batch.model'
import {
  TimeTrackingShowExportRequest,
  TimeTrackingExportRequest,
  TimeTrackingExportRequestParams,
} from '../models/time-tracking-export.model'
import { Area } from '../../employee/models/employee.model'

import { TimeTrackingUpdateActionsRequest } from '../models/time-tracking-actions.model'

export default {
  async listTimeTrackings(requestParams: TimeTrackingListRequestParams) {
    const response = await axios.request(
      new TimeTrackingListRequest(requestParams)
    )
    return response.data
  },

  async getTimeTracking(requestParams: TimeTrackingRequestParams) {
    const response = await axios.request(new TimeTrackingRequest(requestParams))
    return response.data
  },

  async listTimeTrackingNotes(requestParams: NoteListRequestParams) {
    const response = await axios.request(
      new TimeTrackingNoteListRequest(requestParams)
    )
    return response.data
  },

  async addTimeTrackingNote(requestParams: NoteAddRequestParams) {
    const response = await axios.request(
      new TimeTrackingNoteAddRequest(requestParams)
    )
    return response.data
  },

  async updateTimeTrackingNote(requestParams: NoteUpdateRequestParams) {
    const response = await axios.request(
      new TimeTrackingNoteUpdateRequest(requestParams)
    )
    return response.data
  },

  deleteTimeTrackingNote(requestParams: NoteDeleteRequestParams) {
    return axios.request(new TimeTrackingNoteDeleteRequest(requestParams))
  },

  async listTimeTrackingFollowers(requestParams: FollowerListRequestParams) {
    const response = await axios.request(
      new TimeTrackingFollowerListRequest(requestParams)
    )
    return response.data
  },

  async addTimeTrackingFollower(requestParams: FollowerAddRequestParams) {
    const response = await axios.request(
      new TimeTrackingFollowerAddRequest(requestParams)
    )
    return response.data
  },

  deleteTimeTrackingFollower(requestParams: FollowerDeleteRequestParams) {
    return axios.request(new TimeTrackingFollowerDeleteRequest(requestParams))
  },

  async listTimeTrackingAttachments(
    requestParams: AttachementListRequestParams
  ) {
    const response = await axios.request(
      new TimeTrackingAttachmentListRequest(requestParams)
    )
    return response.data
  },

  async listTimeTrackingSignatures(requestParams: SignatureListRequestParams) {
    const response = await axios.request(
      new TimeTrackingSignatureList(requestParams)
    )
    return response.data
  },

  async confirmTimeTracking(requestParams: TimeTrackingConfirmRequestParams) {
    await axios.request(new TimeTrackingConfirmRequest(requestParams))
  },

  async showActiveTimeTracking(workspaceId: RecordId) {
    const response = await axios.request(
      new ActiveTimeTrackingRequest({ workspaceId })
    )

    return response.data
  },

  async startTimeTracking(
    workspaceId: RecordId,
    userId: RecordId,
    areas: Area[]
  ) {
    const response = await axios.request(
      new StartTimeTrackingRequest({ workspaceId, userId, areas })
    )

    return response.data
  },

  async stopTimeTracking(workspaceId: RecordId, timeTrackingId: RecordId) {
    const response = await axios.request(
      new StopTimeTrackingRequest({ workspaceId, timeTrackingId })
    )

    return response.data
  },

  async breakTimeTracking(workspaceId: RecordId, timeTrackingId: RecordId) {
    const response = await axios.request(
      new StartTimeTrackingBreakRequest({ workspaceId, timeTrackingId })
    )

    return response.data
  },

  async continueTimeTracking(
    workspaceId: RecordId,
    timeTrackingId: RecordId,
    breakId: RecordId
  ) {
    const response = await axios.request(
      new StopTimeTrackingBreakRequest({
        workspaceId,
        timeTrackingId,
        breakId,
      })
    )

    return response.data
  },

  async createTimeTracking(workspaceId: RecordId, timeTracking: TimeTracking) {
    const response = await axios.request(
      new TimeTrackingCreateRequest({ workspaceId, timeTracking })
    )

    return response.data
  },

  async updateTimeTracking(
    workspaceId: RecordId,
    timeTrackingId: RecordId,
    timeTracking: TimeTracking
  ) {
    const response = await axios.request(
      new TimeTrackingUpdateRequest({
        workspaceId,
        timeTrackingId,
        timeTracking,
      })
    )

    return response.data
  },

  async updateTimeTrackingStartAt(
    workspaceId: RecordId,
    actionId: RecordId,
    timeTracking: TimeTracking
  ) {
    const response = await axios.request(
      new TimeTrackingUpdateStartAtRequest({
        workspaceId,
        actionId,
        timeTracking,
      })
    )

    return response.data
  },

  async deleteTimeTracking(requestParams: TimeTrackingDeleteRequestParams) {
    await axios.request(new TimeTrackingDeleteRequest(requestParams))
  },

  getExport(workspaceId: RecordId, exportId: RecordId) {
    return axios.request(
      new TimeTrackingShowExportRequest(workspaceId, exportId)
    )
  },

  exportTimeTrackings({
    workspaceId,
    requestParams,
    filter,
    sorting,
  }: TimeTrackingExportRequestParams) {
    return axios.request(
      new TimeTrackingExportRequest({
        workspaceId,
        requestParams,
        filter,
        sorting,
      })
    )
  },

  batchConfirmTimeTrackings(
    workspaceId: RecordId,
    trackingIds: RecordId[],
    checkAll: boolean
  ) {
    return axios.request(
      new TimeTrackingBatchConfirmRequest(workspaceId, trackingIds, checkAll)
    )
  },

  batchDeleteTimeTrackings(workspaceId: RecordId, trackingIds: RecordId[]) {
    return axios.request(
      new TimeTrackingBatchDeleteRequest(workspaceId, trackingIds)
    )
  },

  batchAssignTags(requestParams: TimeTrackingBatchAssignTagsRequestParams) {
    return axios.request(new TimeTrackingBatchAssignTagsRequest(requestParams))
  },

  batchAddNote(
    workspaceId: RecordId,
    timeTrackingIds: RecordId[],
    noteContent: string,
    attachments: []
  ) {
    return axios.request(
      new TimeTrackingBatchAddNoteRequest(
        workspaceId,
        timeTrackingIds,
        noteContent,
        attachments
      )
    )
  },

  timeTrackingUpdateActions({
    workspaceId,
    userId,
    actionType,
  }: {
    workspaceId: RecordId
    userId: RecordId
    actionType: string
  }) {
    return axios.request(
      new TimeTrackingUpdateActionsRequest({
        workspaceId,
        userId,
        actionType,
      })
    )
  },
}
