




















import { defineComponent } from '@vue/composition-api'
import { runOrReturn } from '@/utils/helpers'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import useOptions from './composables/use-options'

export default defineComponent({
  name: 'TimeTrackingHeaderActionsMobile',
  components: {
    BottomSheet,
    BottomSheetListItem,
  },
  props: {
    canCreateTracking: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const { actionOptions } = useOptions(emit, _props.canCreateTracking)

    const close = (cb: () => void) => {
      emit('close')
      cb()
    }

    return {
      actionOptions,
      runOrReturn,
      close,
    }
  },
})
