<template>
  <portal to="modal">
    <base-dialog
      :title="title"
      :button-submit-text="buttonSubmitText"
      @close="$emit('close')"
      @done="$emit('done', confirmableTrackings)"
    >
      <p>{{ content }}</p>
    </base-dialog>
  </portal>
</template>

<script>
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog'

export default {
  name: 'TimeTrackingConfirmDialog',
  components: {
    BaseDialog,
  },
  props: {
    unconfirmedTrackings: {
      type: Array,
      required: true,
    },
    confirmableTrackings: {
      type: Array,
      required: true,
    },
  },
  computed: {
    unconfirmedTrackingsCount() {
      return this.unconfirmedTrackings.length
    },
    confirmableTrackingsCount() {
      return this.confirmableTrackings.length
    },
    title() {
      return this.$t('group_selection.confirm_dialog_title', {
        confirmableTrackings: this.confirmableTrackingsCount,
        unconfirmedTrackings: this.unconfirmedTrackingsCount,
      })
    },
    buttonSubmitText() {
      return this.$t('group_selection.confirm_dialog_submit_button', {
        confirmableTrackings: this.confirmableTrackingsCount,
        unconfirmedTrackings: this.unconfirmedTrackingsCount,
      })
    },
    content() {
      return this.$t('group_selection.confirm_dialog_text', {
        unconfirmableTrackings:
          this.unconfirmedTrackingsCount - this.confirmableTrackingsCount,
        unconfirmedTrackings: this.unconfirmedTrackingsCount,
      })
    },
  },
}
</script>
