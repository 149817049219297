<template>
  <bottom-sheet
    :header-title="$t('time_tracking.signatures_bottom_sheet.title')"
    :header-left-actions="headerLeftActions"
    @back-click="$emit('close')"
  >
    <div v-if="actionStatus.isLoading" class="relative w-full h-16">
      <base-loading :active="true" />
    </div>
    <div v-else class="p-4">
      <signature-item
        v-for="signature in signatures"
        :key="signature.id"
        :title="signature.title"
        :time="signature.created_at"
        :image="signature.image_url"
      />
    </div>
  </bottom-sheet>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import SignatureItem from './TimeTrackingSignaturesModalItem'

export default {
  name: 'TimeTrackingSignaturesBottomSheet',
  components: {
    BottomSheet,
    BaseLoading,
    SignatureItem,
  },
  props: {
    signatures: {
      type: Array,
      default: /* istanbul ignore next */ () => [],
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
  },
  data() {
    return {
      headerLeftActions: [
        {
          title: this.$t('time_tracking.signatures_bottom_sheet.back_button'),
          class: 'text-coal-550',
          event: 'back-click',
          icon: {
            icon: 'arrow/arrow-16',
            rotation: -180,
            size: '1rem',
          },
        },
      ],
    }
  },
}
</script>
