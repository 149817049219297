<template>
  <portal to="bottom-sheet">
    <bottom-sheet @cancel-click="closeModal()">
      <template slot="header">
        <time-tracking-export-bottom-sheet-header
          :title="title"
          @close="closeModal()"
        />
      </template>
      <slot />
    </bottom-sheet>
  </portal>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import TimeTrackingExportBottomSheetHeader from './TimeTrackingExportBottomSheetHeader'

export default {
  name: 'TimeTrackingExportMobile',
  components: {
    BottomSheet,
    TimeTrackingExportBottomSheetHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>
