<template>
  <div
    class="flex items-center justify-between h-14 ml-4 pr-4 border-b border-coal-150 last:border-b-0"
  >
    <base-avatar
      size="is-extra-small"
      :img-url="employee.avatar"
      img-class="w-8 h-8"
      :username="employee.username"
      class="avatar mr-2.5"
      avatar-class="bg-blue-300 text-white"
    />

    <div class="flex-1 text-lg font-medium">
      {{ employee.username }}
    </div>

    <div v-if="actionStatus.isLoading" class="relative h-6 w-6">
      <base-loading :active="true" size="1.5rem" />
    </div>

    <base-button
      v-else-if="employee.isNew"
      type="is-filled"
      size="is-small"
      icon-right="plus"
      @click="$emit('add', employee)"
    />

    <button v-else @click="$emit('remove', employee)">
      <base-icon
        class="text-coal-450"
        icon="cross-close/cross-close-16"
        size="1rem"
      />
    </button>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'

export default {
  name: 'TimeTrackingNotesBottomSheetEmployeeItem',
  components: {
    BaseIcon,
    BaseButton,
    BaseAvatar,
    BaseLoading,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.avatar {
  padding: 0;
}
</style>
