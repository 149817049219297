<template>
  <bottom-sheet
    :header-title="$t('time_tracking.attachments_bottom_sheet.title')"
    :header-left-actions="headerLeftActions"
    @back-click="$emit('close')"
  >
    <div v-if="actionStatus.isLoading" class="relative w-full h-16">
      <base-loading :active="true" />
    </div>
    <div v-else class="p-4">
      <base-attachment-item
        v-for="attachment in attachments"
        :key="attachment.id"
        :attachment="attachment"
        data-id-prefix="time_tracking.bottom_sheet.index.body"
      />
    </div>
  </bottom-sheet>
</template>

<script>
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import BaseAttachmentItem from '@/app/util-modules/ui/attachment-item/BaseAttachmentItem.vue'

export default {
  name: 'TimeTrackingAttachmentsBottomSheet',
  components: {
    BottomSheet,
    BaseLoading,
    BaseAttachmentItem,
  },
  props: {
    attachments: {
      type: Array,
      default: /* istanbul ignore next */ () => [],
    },
    actionStatus: {
      type: Object,
      default: /* istanbul ignore next */ () => ({}),
    },
  },
  data() {
    return {
      headerLeftActions: [
        {
          title: this.$t('time_tracking.attachments_bottom_sheet.back_button'),
          class: 'text-coal-550',
          event: 'back-click',
          icon: {
            icon: 'arrow/arrow-16',
            rotation: -180,
            size: '1rem',
          },
        },
      ],
    }
  },
}
</script>
