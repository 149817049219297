export const TIME_TRACKING_ENDPOINT_LIST_TIME_TRACKINGS = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings`

export const TIME_TRACKING_ENDPOINT_CREATE_TIME_TRACKING = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings`

export const TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}`

export const TIME_TRACKING_ENDPOINT_UPDATE_TIME_TRACKING_START_AT = (
  workspaceId,
  actionId
) => `/workspaces/${workspaceId}/time_trackings/actions/${actionId}`
export const TIME_TRACKING_ENDPOINT_SHOW_TIME_TRACKING = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}`

export const TIME_TRACKING_ENDPOINT_CONFIRM_TIME_TRACKING = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/confirm`

export const TIME_TRACKING_ENDPOINT_LIST_NOTES = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/notes`

export const TIME_TRACKING_ENDPOINT_ADD_NOTE = (workspaceId, timeTrackingId) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/notes`

export const TIME_TRACKING_ENDPOINT_UPDATE_NOTE = (
  workspaceId,
  timeTrackingId,
  noteId
) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/notes/${noteId}`

export const TIME_TRACKING_ENDPOINT_DELETE_NOTE = (
  workspaceId,
  timeTrackingId,
  noteId
) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/notes/${noteId}`

export const TIME_TRACKING_ENDPOINT_LIST_FOLLOWERS = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/followers`

export const TIME_TRACKING_ENDPOINT_ADD_FOLLOWER = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/followers`

export const TIME_TRACKING_ENDPOINT_DELETE_FOLLOWER = (
  workspaceId,
  timeTrackingId,
  userId
) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/followers/${userId}`

export const TIME_TRACKING_ENDPOINT_LIST_SIGNATURES = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/signatures`

export const TIME_TRACKING_ENDPOINT_LIST_ATTACHMENTS = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/attachments`

export const TIME_TRACKING_ENDPOINT_DELETE_TIME_TRACKING = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}`

export const TIME_TRACKING_ENDPOINT_SHOW_ACTIVE = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings`

export const TIME_TRACKING_ENDPOINT_START = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings`

export const TIME_TRACKING_ENDPOINT_STOP = (workspaceId, timeTrackingId) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}`

export const TIME_TRACKING_ENDPOINT_START_BREAK = (
  workspaceId,
  timeTrackingId
) => `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/breaks`

export const TIME_TRACKING_ENDPOINT_STOP_BREAK = (
  workspaceId,
  timeTrackingId,
  breakId
) =>
  `/workspaces/${workspaceId}/time_trackings/${timeTrackingId}/breaks/${breakId}`

export const TIME_TRACKING_ENDPOINT_SHOW_EXPORT = (workspaceId, exportId) =>
  `/workspaces/${workspaceId}/time_trackings/exports/${exportId}`

export const TIME_TRACKING_ENDPOINT_EXPORT = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/exports`

export const TIME_TRACKING_ENDPOINT_BATCH_DELETE = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/batch_actions`

export const TIME_TRACKING_ENDPOINT_BATCH_CONFIRM = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/batch_actions`

export const TIME_TRACKING_ENDPOINT_BATCH_ASSIGN_TAGS = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/batch_actions`

export const TIME_TRACKING_ENDPOINT_BATCH_ADD_NOTE = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/batch_actions`

export const TIME_TRACKING_ENDPOINT_UPDATE_ACTIONS = (workspaceId) =>
  `/workspaces/${workspaceId}/time_trackings/actions`
