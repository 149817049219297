import BaseRequest from '@/utils/api/base-request'
import { TIME_TRACKING_ENDPOINT_UPDATE_ACTIONS } from '../network/time-tracking.endpoints'

export const TIME_TRACKING_ACTION_TYPE = 'action'

export const TIME_TRACKING_ACTIONS_TYPE = {
  START: 'start',
  START_BREAK: 'start-break',
  END_BREAK: 'end-break',
  END: 'end',
}

export class TimeTrackingUpdateActionsRequest extends BaseRequest {
  constructor({
    workspaceId,
    userId,
    actionType,
  }: {
    workspaceId: RecordId
    userId: RecordId
    actionType: string
  }) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_UPDATE_ACTIONS(workspaceId)
    super.type = 'action'
    super.method = 'post'

    super.attributes = {
      action_type: actionType,
      device_id: null,
      action_time: new Date(),
      user_id: Number(userId),
    }
  }
}
