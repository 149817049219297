<template>
  <div class="py-3.5 border-b border-coal-150 last:border-b-0">
    <span v-if="breakData.ends_at">
      {{ finishedBreak }}
    </span>
    <span v-else class="text-coal-450">
      {{ ongoingBreak }} ({{ $t('time_tracking.index.body.breaks.ongoing') }})
    </span>
  </div>
</template>

<script>
import { formatTime } from '@/utils/date-time.utils'

export default {
  name: 'TableRowCellBreakDurationItem',
  props: {
    breakData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    startsAt() {
      return this.breakData.starts_at
    },
    endsAt() {
      return this.breakData.ends_at
    },
    finishedBreak() {
      return `
          ${this.formatTime(this.startsAt)} \u2013
          ${this.formatTime(this.endsAt)}
        `
    },
    ongoingBreak() {
      return this.formatTime(this.startsAt)
    },
  },
  methods: {
    formatTime(input) {
      return formatTime(new Date(input))
    },
  },
}
</script>
