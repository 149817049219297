<template>
  <div
    class="px-4 md:px-2 md:pt-2"
    :class="{
      'border-t border-coal-150': showConfirmAction || actions.length > 0,
    }"
  >
    <div
      v-if="showConfirmAction"
      class="border-b border-coal-150 py-4"
      role="listitem"
    >
      <base-button
        type="is-filled"
        icon-left="tick-confirm"
        size="is-large"
        class="w-full"
        :disabled="!canConfirm"
        :loading="actionsStatuses.confirm.isLoading"
        @click="$emit('confirm-click')"
      >
        {{ $t('time_tracking.index.body.row_details.confirm_tracking') }}
      </base-button>

      <p v-if="!canConfirm" class="mt-2 text-center font-medium text-coal-550">
        {{ $t('time_tracking.index.body.no_confirm_permission') }}
      </p>
    </div>

    <mobile-table-row-details-action-item
      v-for="item in actions"
      :key="item.label"
      v-bind="item"
      @click.native="item.event ? $emit(item.event) : null"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import MobileTableRowDetailsActionItem from './MobileTableRowDetailsActionItem'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'MobileTableRowDetailsFooter',
  components: {
    BaseButton,
    MobileTableRowDetailsActionItem,
  },
  props: {
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    canConfirm: {
      type: Boolean,
      default: false,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    relationshipsCounts: {
      type: Object,
      required: true,
    },
    actionsStatuses: {
      type: Object,
      default: () => ({
        confirm: {},
      }),
    },
    isRunning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    actions() {
      const arr = []

      if (!this.isRunning) {
        arr.unshift({
          label: this.$t('time_tracking.index.body.row_details.notes'),
          icon: 'note-document/note-document-16',
          iconType: 'is-filled',
          event: 'notes-click',
          badge: this.relationshipsCounts.notes + '',
        })
      }

      if (this.canUpdate) {
        arr.unshift({
          label: this.$t('time_tracking.index.body.row_details.edit_tracking'),
          icon: 'settings/settings-16',
          event: 'settings-click',
        })
      }

      if (this.relationshipsCounts.attachments) {
        arr.push({
          label: this.$t('time_tracking.index.body.row_details.attachments'),
          icon: 'file-clip/file-clip-16',
          event: 'attachments-click',
          badge: this.relationshipsCounts.attachments + '',
        })
      }

      if (this.relationshipsCounts.signatures) {
        arr.push({
          label: this.$t('time_tracking.index.body.row_details.signatures'),
          icon: 'signature/signature-16',
          event: 'signatures-click',
          badge: this.relationshipsCounts.signatures + '',
        })
      }

      return arr
    },
    showConfirmAction() {
      return (
        this.isFinished &&
        !this.isConfirmed &&
        !this.$isMediumDevice &&
        this.isAdmin
      )
    },
  },
}
</script>
