import { MODULE_NAME } from '../../time-tracking.module'

export const TIME_TRACKING_ACTION_LIST_TIME_TRACKINGS = `${MODULE_NAME}/ACTION_LIST_TIME_TRACKINGS`
export const TIME_TRACKING_ACTION_LIST_APPEND_TIME_TRACKINGS = `${MODULE_NAME}/ACTION_LIST_APPEND_TIME_TRACKINGS`
export const TIME_TRACKING_ACTION_GET_TIME_TRACKING = `${MODULE_NAME}/ACTION_GET_TIME_TRACKING`
export const TIME_TRACKING_ACTION_CLEAR_TIME_TRACKINGS = `${MODULE_NAME}/ACTION_CLEAR_TIME_TRACKINGS`
export const TIME_TRACKING_ACTION_CREATE_TIME_TRACKING = `${MODULE_NAME}/ACTION_CREATE_TIME_TRACKING`
export const TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING = `${MODULE_NAME}/ACTION_UPDATE_TIME_TRACKING`
export const TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING_START_AT = `${MODULE_NAME}/ACTION_UPDATE_TIME_TRACKING_START_AT`
export const TIME_TRACKING_ACTION_CONFIRM_TIME_TRACKING = `${MODULE_NAME}/ACTION_CONFIRM_TIME_TRACKING`
export const TIME_TRACKING_ACTION_DELETE_TIME_TRACKING = `${MODULE_NAME}/ACTION_DELETE_TIME_TRACKING`
export const TIME_TRACKING_ACTION_SHOW_ACTIVE = `${MODULE_NAME}/ACTION_SHOW_ACTIVE`
export const TIME_TRACKING_ACTION_START = `${MODULE_NAME}/ACTION_START`
export const TIME_TRACKING_ACTION_STOP_ACTIVE = `${MODULE_NAME}/ACTION_STOP_ACTIVE`
export const TIME_TRACKING_ACTION_BREAK_ACTIVE = `${MODULE_NAME}/ACTION_BREAK_ACTIVE`
export const TIME_TRACKING_ACTION_CONTINUE_ACTIVE = `${MODULE_NAME}/ACTION_CONTINUE_ACTIVE`
export const TIME_TRACKING_ACTION_SHOW_NEW_FILTER_FORM = `${MODULE_NAME}/ACTION_SHOW_NEW_FILTER_FORM`
export const TIME_TRACKING_ACTION_CONFIRM_LIST = `${MODULE_NAME}/ACTION_CONFIRM_LIST`
export const TIME_TRACKING_ACTION_DELETE_LIST = `${MODULE_NAME}/ACTION_DELETE_LIST`
export const TIME_TRACKING_ACTION_BATCH_ASSIGN_TAGS = `${MODULE_NAME}/ACTION_BATCH_ASSIGN_TAGS`
export const TIME_TRACKING_ACTION_BATCH_ADD_NOTE = `${MODULE_NAME}/ACTION_BATCH_ADD_NOTE`
export const TIME_TRACKING_ACTION_UPDATE_META = `${MODULE_NAME}/ACTION_UPDATE_META`
export const TIME_TRACKING_ACTION_UPDATE_DATA_AFTER_CONFIRM = `${MODULE_NAME}/ACTION_UPDATE_DATA_AFTER_CONFIRM`

export const TIME_TRACKING_ACTION_EXPORT = `${MODULE_NAME}/ACTION_EXPORT`
export const TIME_TRACKING_ACTION_POLL_EXPORT = `${MODULE_NAME}/ACTION_POLL_EXPORT`

export const TIME_TRACKING_NOTE_ACTION_LIST = `${MODULE_NAME}/NOTE_ACTION_LIST`
export const TIME_TRACKING_NOTE_ACTION_LIST_APPEND = `${MODULE_NAME}/NOTE_ACTION_LIST_APPEND`
export const TIME_TRACKING_NOTE_ACTION_ADD = `${MODULE_NAME}/NOTE_ACTION_ADD`
export const TIME_TRACKING_NOTE_ACTION_UPDATE = `${MODULE_NAME}/NOTE_ACTION_UPDATE`
export const TIME_TRACKING_NOTE_ACTION_DELETE = `${MODULE_NAME}/NOTE_ACTION_DELETE`

export const TIME_TRACKING_ATTACHMENT_ACTION_LIST = `${MODULE_NAME}/ATTACHMENT_ACTION_LIST`

export const TIME_TRACKING_SIGNATURE_ACTION_LIST = `${MODULE_NAME}/SIGNATURE_ACTION_LIST`

export const TIME_TRACKING_FOLLOWER_ACTION_LIST = `${MODULE_NAME}/FOLLOWER_ACTION_LIST`
export const TIME_TRACKING_FOLLOWER_ACTION_ADD = `${MODULE_NAME}/FOLLOWER_ACTION_ADD`
export const TIME_TRACKING_FOLLOWER_ACTION_DELETE = `${MODULE_NAME}/FOLLOWER_ACTION_DELETE`

export const TIME_TRACKING_ACTION_UPDATE_ACTIONS = `${MODULE_NAME}/ACTION_UPDATE_ACTIONS`
