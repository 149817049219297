<template>
  <component
    :is="actionsComponent"
    :class="{ 'px-2 py-0': $isMediumDevice }"
    class="border-b border-coal-150 last:border-b-0 md:border-b-0"
    aria-role="listitem"
  >
    <button class="flex items-center py-4 md:py-2.5 text-coal w-full">
      <base-icon
        :icon="icon"
        :size="iconSize"
        class="mr-2.5"
        :type="iconType"
      />
      <span class="text-lg font-medium md:text-base">{{ label }}</span>
      <base-badge
        v-if="hasBadge"
        class="ml-auto"
        :title="badge"
        type="is-success"
        :size="$isSmallDevice ? 'is-medium' : 'is-small'"
      />
    </button>
  </component>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge'

const BaseDropdownItem = () =>
  import('@/app/util-modules/ui/dropdown/BaseDropdownItem')

export default {
  name: 'MobileTableRowDetailsActionItem',
  components: {
    BaseIcon,
    BaseBadge,
    BaseDropdownItem,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    badge: {
      type: [String],
      default: '',
    },
  },
  computed: {
    hasBadge() {
      return this.badge !== ''
    },
    iconSize() {
      return this.$isSmallDevice ? '1.25rem' : '1rem'
    },
    actionsComponent() {
      return !this.$isMediumDevice ? 'div' : BaseDropdownItem
    },
  },
}
</script>
