<template>
  <div>
    <time-tracking-applied-filters-panel />

    <div class="flex flex-col py-6 border-b border-t border-coal-150">
      <div v-if="rows.length" class="flex mb-6">
        <base-radio
          v-model="passedPayload.export_all"
          name="exportOption"
          :native-value="false"
          class="time-tracking-export-form-radio-all"
          :size="inputSize"
        >
          {{
            $t('time_tracking.export_modal.export_selected_option', {
              trackings: rows.length,
            })
          }}
        </base-radio>
        <base-radio
          v-model="passedPayload.export_all"
          name="exportOption"
          :native-value="true"
          :size="inputSize"
        >
          {{
            $t('time_tracking.export_modal.export_all_option', {
              trackings: total,
            })
          }}
        </base-radio>
      </div>

      <base-checkbox
        v-model="passedPayload.include_deactivated_areas"
        class="mb-4"
        :size="inputSize"
      >
        {{ $t('time_tracking.export_modal.include_deactivated_areas') }}
      </base-checkbox>
      <base-checkbox v-model="passedPayload.staff_number" :size="inputSize">
        {{ $t('time_tracking.export_modal.staff_number') }}
      </base-checkbox>
    </div>

    <div class="py-6">
      <span class="block md:hidden font-semibold mb-4">
        {{ $t('time_tracking.export_modal.export_types') }}
      </span>
      <time-tracking-export-dropdown
        v-for="(option, index) in formatOptions"
        :key="`${option}-${index}`"
        :position="dropdownPosition(index)"
        :label="exportLabel(option)"
        :description="option.description"
        class="mb-8 last:mb-0"
        @selectOption="selectOption($event, option.type)"
      />
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox'
import BaseRadio from '@/app/util-modules/ui/radio/BaseRadio'
import TimeTrackingExportDropdown from './TimeTrackingExportDropdown'
import TimeTrackingAppliedFiltersPanel from '../time-tracking-applied-filters-panel/TimeTrackingAppliedFiltersPanel'

export default {
  name: 'TimeTrackingExportForm',
  components: {
    BaseCheckbox,
    BaseRadio,
    TimeTrackingExportDropdown,
    TimeTrackingAppliedFiltersPanel,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formatOptions: [
        {
          type: 'sum',
          desktopLabel: this.$t(
            'time_tracking.export_modal.minimal_export_label_desktop'
          ),
          mobileLabel: this.$t(
            'time_tracking.export_modal.minimal_export_label_mobile'
          ),
          description: this.$t(
            'time_tracking.export_modal.minimal_export_description'
          ),
        },
        {
          type: 'list',
          desktopLabel: this.$t(
            'time_tracking.export_modal.simple_export_label_desktop'
          ),
          mobileLabel: this.$t(
            'time_tracking.export_modal.simple_export_label_mobile'
          ),
          description: this.$t(
            'time_tracking.export_modal.simple_export_description'
          ),
        },
        {
          type: 'detail',
          desktopLabel: this.$t(
            'time_tracking.export_modal.detailed_export_label_desktop'
          ),
          mobileLabel: this.$t(
            'time_tracking.export_modal.detailed_export_label_mobile'
          ),
          description: this.$t(
            'time_tracking.export_modal.detailed_export_description'
          ),
        },
        {
          type: 'zip',
          desktopLabel: this.$t(
            'time_tracking.export_modal.zip_export_label_desktop'
          ),
          mobileLabel: this.$t(
            'time_tracking.export_modal.zip_export_label_mobile'
          ),
          description: this.$t(
            'time_tracking.export_modal.zip_export_description'
          ),
        },
      ],
    }
  },
  computed: {
    inputSize() {
      return this.$isSmallDevice ? 'is-medium' : 'is-small'
    },
    passedPayload: {
      get() {
        return this.value
      },
      set(newPayload) {
        this.$emit('input', newPayload)
      },
    },
  },
  methods: {
    dropdownPosition(index) {
      return index === this.formatOptions.length - 1 ? 'is-top-left' : null
    },
    exportLabel(option) {
      return this.$isSmallDevice ? option.mobileLabel : option.desktopLabel
    },
    selectOption(format, type) {
      this.$emit('submit', {
        format,
        type,
      })
    },
  },
}
</script>

<style scoped>
.b-radio.radio.time-tracking-export-form-radio-all {
  @apply mr-6;
}
</style>
