<template>
  <div class="flex flex-col md:flex-row items-left table-row-cell-break">
    <div v-if="breaks.length" class="flex items-center mr-2 md:mr-1">
      <base-tooltip type="is-white" position="is-top">
        <template v-slot:content>
          <break-tooltip-content :breaks="breaks" />
        </template>

        <div
          ref="informationIconWrapper"
          class="text-blue-400 hidden md:flex ml-2 md:ml-0 info-icon-wrapper"
        >
          <base-icon icon="help/help-16" :size="iconSize" type="is-stroke" />
        </div>
      </base-tooltip>
    </div>
    <table-row-cell-default :value="breaksValue" :archived="archived" />
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'
import TableRowCellDefault from '../table-row-cell-default/TableRowCellDefault'
import BreakTooltipContent from './TableRowCellBreakTooltipContent'
import { getDurationBasedOnUserSetting } from '@/utils/date-time.utils'
import { mapState } from 'vuex'

export default {
  name: 'TableRowCellBreak',
  components: {
    BaseIcon,
    BaseTooltip,
    TableRowCellDefault,
    BreakTooltipContent,
  },
  props: {
    breaksSum: {
      type: Number,
      default: 0,
    },
    breaks: {
      type: Array,
      default: () => [],
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentUser: ({ user }) => user.user,
    }),
    breaksValue() {
      return getDurationBasedOnUserSetting(
        this.breaksSum,
        this.currentUser.attributes.timeFormat
      )
    },
    iconSize() {
      return this.$isSmallDevice ? '1.25rem' : '1rem'
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .tooltip-content {
  @apply pt-0 px-4 pb-1;
}
</style>
