<template>
  <div class="relative">
    <button type="button" @click="isOpen = !isOpen">
      <base-icon
        type="is-filled"
        class="context-menu text-coal-450"
        :data-id="`${dataIdPrefix}.trigger`"
        :icon="icon.icon"
        :size="icon.size"
      />
    </button>

    <base-dropdown
      v-if="isOpen"
      :is-open="true"
      :position="position"
      :append-to-body="true"
      aria-role="menu"
      @active-change="handleActiveChange($event)"
    >
      <base-dropdown-text-item
        v-for="opt in options"
        :key="opt.text"
        :class="opt.class"
        class="min-w-24"
        :data-id="`${dataIdPrefix}.${opt.text}`"
        @click="$emit(opt.event)"
      >
        <template slot="left">
          <base-icon
            :icon="opt.icon"
            size="1rem"
            class="inline-block mb-px mr-2"
          />
        </template>
        <span>{{ opt.text }}</span>
      </base-dropdown-text-item>
    </base-dropdown>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TimeTrackingNotesNoteOptions',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    BaseIcon,
  },
  props: {
    position: {
      type: String,
      default: 'is-bottom-left',
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    options() {
      const displayedOptions = []
      if (this.permissions.update) {
        displayedOptions.push({
          icon: 'edit-pencil/edit-pencil-16',
          text: this.$t('time_tracking.notes_modal.edit_action'),
          event: 'edit',
        })
      }

      if (this.permissions.destroy) {
        displayedOptions.push({
          icon: 'delete/delete-16',
          text: this.$t('time_tracking.notes_modal.delete_action'),
          class: 'note-delete-btn',
          event: 'delete',
        })
      }

      return displayedOptions
    },
    icon() {
      return {
        size: this.$isSmallDevice ? '1.5rem' : '1rem',
        icon: this.$isSmallDevice
          ? 'ellipsis/ellipsis-24'
          : 'ellipsis/ellipsis-16',
      }
    },
  },
  methods: {
    // change isOpen data prop when dropdown closes
    // e.g. on "outside-click". Debounce, because
    // buefy fires events multiple times, since
    // we are providing is-open prop manually
    handleActiveChange: debounce(function (open) {
      if (!open) {
        this.isOpen = false
      }
    }, 500),
  },
}
</script>

<style scoped>
::v-deep .background {
  display: none;
}
.note-delete-btn {
  @apply text-error-400 hover:text-error-400;
}
</style>
