<template>
  <div
    :class="{
      hidden: $isSmallDevice && selectedFilters.length === 0,
      'py-2 border-coal-100 border-b': $isSmallDevice,
    }"
  >
    <base-filterbar
      ref="filterbar"
      :label="$t('time_tracking.filterbar.button_filter')"
      :filters="formattedFiltersList"
      :selected-filters="selectedFilters"
      :selected-filters-values="selectedFiltersValues"
      @apply="applyFieldFilter($event)"
      @remove="removeFieldFilter($event)"
      @close="hideNewFilterForm()"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { MODULE_NAME as TIME_TRACKING_MODULE_NAME } from '../../time-tracking.module'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import {
  TIME_TRACKING_MUTATION_ADD_FILTERBAR_FILTER,
  TIME_TRACKING_MUTATION_SET_SHOW_NEW_FILTER_FORM,
  TIME_TRACKING_MUTATION_REMOVE_FILTERBAR_FILTER,
} from '../../store/mutations/time-tracking.mutations.names'
import BaseFilterbar from '@/app/util-modules/ui/filterbar/BaseFilterbar'
import filters from './available-filters'

export default {
  name: 'TimeTrackingFilterbar',
  components: {
    BaseFilterbar,
  },
  data() {
    return {
      filters,
    }
  },
  computed: {
    ...mapState(TIME_TRACKING_MODULE_NAME, {
      showFormNew: (state) => state.timeTrackingFilterbarShowFormNew,
      selectedFilters: (state) => state.timeTrackingFilterbarFilters,
      selectedFiltersValues: (state) => state.timeTrackingFilterbarValues,
    }),
    ...mapGetters({
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    formattedFiltersList() {
      if (this.isAssumedRoleAdmin) {
        return this.filters
      }
      return this.filters.filter((filter) => filter.field !== 'user_username')
    },
  },
  watch: {
    showFormNew(show) {
      if (show) {
        this.$refs.filterbar.addFirstAvailableFilter()
      }
    },
  },
  methods: mapMutations({
    applyFieldFilter: TIME_TRACKING_MUTATION_ADD_FILTERBAR_FILTER,
    hideNewFilterForm: (commit) =>
      commit(TIME_TRACKING_MUTATION_SET_SHOW_NEW_FILTER_FORM, false),
    removeFieldFilter: TIME_TRACKING_MUTATION_REMOVE_FILTERBAR_FILTER,
  }),
}
</script>
