<template>
  <bottom-sheet
    key="note-list"
    :header-title="header.title"
    :header-left-actions="header.left"
    :header-right-actions="header.right"
    :full-height="true"
    @submit-click="newNote ? submitNewNote() : submitEditingNote()"
    @cancel-click="$emit('close')"
  >
    <base-loading v-if="actionStatus.isLoading" :active="true" />

    <div
      class="content-container"
      :class="[isFormOpen ? 'px-4 pt-4 pb-0' : 'p-4']"
    >
      <followers-mobile
        class="mb-4"
        data-id-prefix="time_tracking_note_bottomsheet"
        :followers="followers"
        @manage-click="toggleSearchOverlay(true)"
      />

      <base-overlay-search
        v-if="employeeSearchShown"
        placeholder="Start typing to add more"
        @typing="searchEmployees($event)"
        @click-button-done="toggleSearchOverlay(false)"
      >
        <employee-result-item
          v-for="employee in employees"
          :key="employee.id"
          slot="list"
          :employee="employee"
          :action-status="employee.actionStatus || {}"
          @add="addFollower($event)"
          @remove="removeFollower($event)"
        />
      </base-overlay-search>

      <base-system-notes-toggler
        v-if="hasSystemNotesPermission && !isFormOpen"
        class="mb-6 bg-coal-30 flex justify-between align-middle p-2 rounded font-semibold md:text-sm"
        :value="systemNotesShown"
        data-id-prefix="time_tracking_note_bottomsheet"
        @toggle="toggleSystemNotes($event)"
      />

      <base-button
        v-if="showAddNewNoteButton"
        type="is-filled"
        icon-left="plus"
        size="is-medium"
        class="w-full"
        @click="addNewNoteCard()"
      >
        {{ $t('time_tracking.notes_modal.new_note_button') }}
      </base-button>

      <div
        v-if="!isFormOpen && !showAddNewNoteButton"
        class="flex items-center"
      >
        <button class="add-button flex-none" @click="addNewNoteCard()">
          <base-icon
            icon="plus/plus-24"
            size="1.5rem"
            class="text-coal-450"
            :style="{ height: listHeight }"
          />
        </button>

        <base-virtual-list
          ref="note-list"
          direction="horizontal"
          :data-sources="notes"
          :data-component="noteCardComponent"
          :estimate-size="220"
          :extra-props="{ currentNote }"
          :keeps="notesPerPage"
          class="flex -mr-4 overflow-x-auto hidden-scrollbar"
          wrap-class="flex items-center"
          :wrap-style="{ height: listHeight }"
          item-class="flex-none h-full w-55 mr-2.5 last:mr-4"
          @card-click="currentNote = $event"
          @edit="editNote($event)"
          @delete="deleteNote($event)"
          @tobottom="fetchAppendNotes()"
        />
      </div>

      <note-details
        v-if="currentNote && !editingNote"
        :current-note="currentNote"
        class="mt-5 pt-5 border-t border-coal-150"
        @delete="deleteNote(currentNote)"
      />

      <note-form
        v-if="editingNote"
        :note="editingNote"
        :action-status="$actions.updateNote"
        @input="editingNote.content = $event"
        @file-input="setNewAttachments($event)"
        @attachment-delete="removeExistingAttachment($event)"
        @validity-change="submitEnabled = $event"
      />

      <note-form
        v-if="newNote"
        :note="newNote"
        :action-status="$actions.addNote"
        @input="newNote.content = $event"
        @file-input="setNewAttachments($event)"
        @attachment-delete="removeExistingAttachment($event)"
        @validity-change="submitEnabled = $event"
      />
    </div>
  </bottom-sheet>
</template>

<script>
import store from '@/store'
import { defineComponent, ref, toRefs, computed } from '@vue/composition-api'
import { USER_GETTER_FEATURE_PERMISSIONS } from '@/app/core/user/store/getters/user.getters.names'
import {
  TIME_TRACKING_NOTE_ACTION_ADD,
  TIME_TRACKING_NOTE_ACTION_UPDATE,
  TIME_TRACKING_FOLLOWER_ACTION_ADD,
  TIME_TRACKING_FOLLOWER_ACTION_DELETE,
} from '../../../store/actions/time-tracking.actions.names'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseVirtualList from '@/app/util-modules/ui/virtual-list/BaseVirtualList'
import FollowersMobile from '@/app/util-modules/ui/followers/FollowersMobile'
import BaseOverlaySearch from '@/app/util-modules/ui/overlay-search/BaseOverlaySearch'
import BaseSystemNotesToggler from '@/app/util-modules/ui/system-notes-toggler/BaseSystemNotesToggler'
import NoteCard from '../TimeTrackingNotesNoteCard'
import NoteDetails from '../TimeTrackingNotesDetails'
import NoteForm from '../TimeTrackingNotesForm'
import EmployeeResultItem from './TimeTrackingNotesBottomSheetEmployeeItem'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'

import useFetchNotes from '../composables/use-fetch-notes'
import useSystemNotes from '../composables/use-system-notes'
import useCurrentNote from '../composables/use-current-note'
import useScrollToNote from '../composables/use-scroll-to-note'
import useNewNote from '../composables/use-new-note'
import useEditNote from '../composables/use-edit-note'
import useDeleteNote from '../composables/use-delete-note'
import useAttachments from '../composables/use-attachments'
import useFollowers from '../composables/use-followers'
import useEmployeeSearch from '../composables/use-employee-search'

export default defineComponent({
  name: 'TimeTrackingNotesBottomSheet',
  components: {
    BaseButton,
    BottomSheet,
    BaseLoading,
    BaseIcon,
    BaseVirtualList,
    FollowersMobile,
    BaseOverlaySearch,
    BaseSystemNotesToggler,
    NoteDetails,
    NoteForm,
    EmployeeResultItem,
  },
  props: {
    timeTrackingId: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    followers: {
      type: Array,
      default: () => [],
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { refs, root }) {
    const $actions = useActionTracker({
      addNote: TIME_TRACKING_NOTE_ACTION_ADD,
      updateNote: TIME_TRACKING_NOTE_ACTION_UPDATE,
      addFollower: {
        action: TIME_TRACKING_FOLLOWER_ACTION_ADD,
        trackByKey: 'userId',
      },
      removeFollower: {
        action: TIME_TRACKING_FOLLOWER_ACTION_DELETE,
        trackByKey: 'userId',
      },
    })
    const username = store.state.user.user.attributes.username
    const hasSystemNotesPermission =
      store.getters[USER_GETTER_FEATURE_PERMISSIONS](
        'General'
      ).show_system_notes

    const { notes, followers, timeTrackingId } = toRefs(props)
    const { systemNotesShown, toggleSystemNotes, noteType } = useSystemNotes()
    const { notesPerPage, fetchNotes, fetchAppendNotes } = useFetchNotes(
      timeTrackingId,
      noteType
    )
    const { scrollToNote } = useScrollToNote(refs, notes)
    const { currentNote, switchToNote } = useCurrentNote(notes, scrollToNote)
    const {
      newAttachments,
      removedAttachments,
      setNewAttachments,
      removeExistingAttachment,
      clearAttachments,
    } = useAttachments()
    const { newNote, addNewNoteCard, submitNewNote } = useNewNote(
      timeTrackingId,
      currentNote,
      newAttachments,
      clearAttachments,
      switchToNote,
      fetchNotes
    )
    const { editNote, editingNote, submitEditingNote } = useEditNote(
      timeTrackingId,
      currentNote,
      newAttachments,
      removedAttachments,
      clearAttachments,
      switchToNote,
      fetchNotes
    )
    const { deleteNote } = useDeleteNote(timeTrackingId, fetchNotes)
    const { addFollower, removeFollower } = useFollowers(
      followers,
      timeTrackingId
    )
    const {
      employeeSearchShown,
      toggleSearchOverlay,
      searchEmployees,
      employees,
    } = useEmployeeSearch($actions, followers)

    const listHeight = ref('5.67rem')
    const submitEnabled = ref(false)
    const isFormOpen = computed(() =>
      Boolean(newNote.value || editingNote.value)
    )
    const header = computed(() => {
      const options = {
        title: root.$t('time_tracking.notes_bottom_sheet.title', {
          user: username,
        }),
        left: [],
        right: [
          {
            title: root.$t('time_tracking.notes_bottom_sheet.button_done'),
            class: 'font-semibold text-green-400',
            event: 'cancel-click',
          },
        ],
      }

      if (isFormOpen.value) {
        options.title = root.$t(
          'time_tracking.notes_bottom_sheet.title_add_note'
        )
        options.left = null
        options.right = [
          {
            title: root.$t('time_tracking.notes_bottom_sheet.button_submit'),
            class:
              'font-semibold ' + (submitEnabled.value ? 'text-green-400' : ''),
            event: 'submit-click',
            disabled: !submitEnabled.value,
          },
        ]
      }

      return options
    })

    const showAddNewNoteButton = computed(() => {
      return (
        !systemNotesShown.value &&
        !notes.value?.length &&
        !newNote.value?.author
      )
    })

    return {
      $actions,
      hasSystemNotesPermission,
      listHeight,
      submitEnabled,
      isFormOpen,
      header,

      notesPerPage,
      fetchAppendNotes,

      systemNotesShown,
      toggleSystemNotes,

      currentNote,

      showAddNewNoteButton,

      newNote,
      addNewNoteCard,
      submitNewNote,

      editingNote,
      editNote(note) {
        editNote(ref(note))
      },
      submitEditingNote,

      deleteNote(note) {
        deleteNote(ref(note))
      },

      setNewAttachments,
      removeExistingAttachment,

      addFollower,
      removeFollower,

      employeeSearchShown,
      toggleSearchOverlay,
      searchEmployees,

      employees,

      noteCardComponent: NoteCard,
    }
  },
})
</script>

<style scoped>
.content-container {
  height: calc(100% - 5rem);
}
.add-button {
  @apply w-12 h-full mr-2.5 border border-coal-100 rounded;
}
</style>
