<template>
  <portal v-if="isOpen" to="modal">
    <base-modal @close="closeModal()">
      <base-card-modal-form :title="title" @close="closeModal()">
        <base-textarea
          v-model="content"
          :show-action-submit="true"
          :show-action-attachment="true"
          :is-loading="actionStatus.isLoading"
          :submit-button-label="$t('time_tracking.add_notes_modal.submit_note')"
          @submit-click="$emit('submit', { content, attachments })"
          @file-input="addAttachment($event)"
        />

        <template v-slot:footer>
          <div />
          <!-- TODO: batch add followers are to be implemented in future task: https://app.asana.com/0/1175527195735801/1200838322239190/f
          <time-tracking-note-add-footer
            :employees="employees"
            @addEmployee="$emit('addEmployee', $event)"
            @removeEmployee="$emit('removeEmployee', $event)"
          /-->
        </template>
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseTextarea from '@/app/util-modules/ui/textarea/BaseTextarea'
import { isSameFile } from '@/utils/helpers'
//import TimeTrackingNoteAddFooter from './TimeTrackingNoteAddFooter'

export default {
  name: 'TimeTrackingNoteAddDesktop',
  components: {
    BaseModal,
    BaseCardModalForm,
    BaseTextarea,
    //TimeTrackingNoteAddFooter,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      content: '',
      attachments: [],
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    addAttachment(files) {
      const attachments = [...this.attachments]
      const filesToAdd = files.filter((f) => {
        const foundAttachment = attachments.findIndex((f2) => isSameFile(f2, f))
        return foundAttachment === -1
      })
      this.attachments = [...attachments, ...filesToAdd]
    },
  },
}
</script>
