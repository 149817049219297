import { ref, computed } from '@vue/composition-api'
import { NOTE_TYPES } from '@/constants'

export default function useSystemNotes() {
  const systemNotesShown = ref(true)

  const noteType = computed(() =>
    !systemNotesShown.value ? `eq:${NOTE_TYPES.USER}` : ''
  )
  const toggleSystemNotes = (show) => {
    systemNotesShown.value = show
  }

  return {
    noteType,
    systemNotesShown,
    toggleSystemNotes,
  }
}
