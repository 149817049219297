import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import { downloadExportedTrackings } from './time-tracking.actions.utils'
import * as actionNamesObj from './time-tracking.actions.names'
import * as getterNamesObj from '../getters/time-tracking.getters.names'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import api from '../../network/time-tracking.api'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n'
import { TimeTrackingState } from '../time-tracking.state'

const actionNames = shortname(actionNamesObj)
const getterNames = shortname(getterNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.TIME_TRACKING_ACTION_EXPORT]: async (
    {
      state,
      rootState,
      getters,
      dispatch,
      commit,
    }: ActionContext<TimeTrackingState, any>,
    requestParams: Record<string, unknown>
  ) => {
    const response = await api.exportTimeTrackings({
      workspaceId: rootState.workspace.workspace.id,
      requestParams,
      filter: getters[getterNames.TIME_TRACKING_GETTER_ACTIVE_FILTERS],
      sorting: state.timeTrackingsSorting,
    })

    const exportId = response.data.data.id
    commit(mutationNames.TIME_TRACKING_MUTATION_SET_EXPORT_ID, exportId)

    const pollingSnackbar = Snackbar.open({
      message: i18n.t('export.polling_message'),
      info: i18n.t('export.polling_message_info'),
      type: 'is-info',
    })

    /*TODO:
      return promise from poll export action to close the snackbar. To avoid passing the snackbar instance as param
      Improvement as suggested in this PR https://github.com/Papershift/papershift-frontend/pull/768
    */
    dispatch(actionNames.TIME_TRACKING_ACTION_POLL_EXPORT, {
      exportId,
      closeSnackbar: pollingSnackbar.closeSnackbar,
    })
  },

  [actionNames.TIME_TRACKING_ACTION_POLL_EXPORT]: async (
    { dispatch, commit, rootState }: ActionContext<TimeTrackingState, any>,
    {
      exportId,
      closeSnackbar,
    }: { exportId: RecordId; closeSnackbar: () => void }
  ) => {
    const response = await api.getExport(
      rootState.workspace.workspace.id,
      exportId
    )

    const responseData = response.data.data

    if (responseData.attributes.status === 'pending') {
      setTimeout(() => {
        dispatch(actionNames.TIME_TRACKING_ACTION_POLL_EXPORT, {
          exportId,
          closeSnackbar,
        })
      }, 3000)
    } else {
      commit(mutationNames.TIME_TRACKING_MUTATION_REMOVE_EXPORT_ID, exportId)
      if (responseData.attributes.status === 'error') {
        closeSnackbar()
        Snackbar.open({
          message: i18n.t('export.error'),
          type: 'is-danger',
        })
      } else {
        closeSnackbar()
        downloadExportedTrackings(responseData)
      }
    }
  },
}
