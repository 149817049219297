<template>
  <a :href="fileUrl" target="_blank">
    <base-icon icon="file-clip/file-clip-16" size="1rem" />
    {{ fileName }}
  </a>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TimeTrackingNotesNoteFieldAttachment',
  components: {
    BaseIcon,
  },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    fileUrl: {
      type: String,
      default: '',
    },
  },
}
</script>
