import api from '../../network/time-tracking.api'
import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import * as actionNamesObj from './time-tracking.actions.names'
import { TimeTrackingState } from '../time-tracking.state'

const actionNames = shortname(actionNamesObj)

export default {
  [actionNames.TIME_TRACKING_ACTION_UPDATE_ACTIONS]: async (
    { rootState, dispatch }: ActionContext<TimeTrackingState, any>,
    actionType: string
  ) => {
    await api.timeTrackingUpdateActions({
      workspaceId: rootState.workspace.workspace.id,
      userId: rootState.user.user.id,
      actionType,
    })

    await dispatch(actionNames.TIME_TRACKING_ACTION_SHOW_ACTIVE)
  },
}
