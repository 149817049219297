import { MODULE_NAME } from '../../time-tracking.module'

export const TIME_TRACKING_MUTATION_SET_RECORDS = `${MODULE_NAME}/MUTATION_SET_RECORDS`
export const TIME_TRACKING_MUTATION_ADD_RECORDS = `${MODULE_NAME}/MUTATION_ADD_RECORDS`
export const TIME_TRACKING_MUTATION_ADD_INCLUDED = `${MODULE_NAME}/MUTATION_ADD_INCLUDED`
export const TIME_TRACKING_MUTATION_PREPEND_RECORDS = `${MODULE_NAME}/MUTATION_PREPEND_RECORDS`
export const TIME_TRACKING_MUTATION_APPEND_RECORDS = `${MODULE_NAME}/MUTATION_APPEND_RECORDS`
export const TIME_TRACKING_MUTATION_REPLACE_RECORD = `${MODULE_NAME}/MUTATION_REPLACE_RECORD`
export const TIME_TRACKING_MUTATION_DELETE_RECORDS = `${MODULE_NAME}/MUTATION_DELETE_RECORDS`
export const TIME_TRACKING_MUTATION_CLEAR_RECORDS = `${MODULE_NAME}/MUTATION_CLEAR_RECORDS`
export const TIME_TRACKING_MUTATION_ATTACH_RELATIONSHIPS = `${MODULE_NAME}/MUTATION_ATTACH_RELATIONSHIPS`
export const TIME_TRACKING_MUTATION_DETACH_RELATIONSHIPS = `${MODULE_NAME}/MUTATION_DETACH_RELATIONSHIPS`

export const TIME_TRACKING_MUTATION_SET_TIME_TRACKING_CHECKED = `${MODULE_NAME}/MUTATION_SET_TIME_TRACKING_CHECKED`
export const TIME_TRACKING_MUTATION_SET_ALL_TIME_TRACKINGS_CHECKED = `${MODULE_NAME}/MUTATION_SET_ALL_TIME_TRACKINGS_CHECKED`
export const TIME_TRACKING_MUTATION_UPDATE_TABS_META_TIME_TRACKINGS = `${MODULE_NAME}/MUTATION_UPDATE_TABS_META_TIME_TRACKINGS`
export const TIME_TRACKING_MUTATION_SET_META_TIME_TRACKINGS = `${MODULE_NAME}/MUTATION_SET_META_TIME_TRACKINGS`
export const TIME_TRACKING_MUTATION_SET_TIME_TRACKING_SORTING_FIELD = `${MODULE_NAME}/MUTATION_SET_TIME_TRACKING_SORTING_FIELD`
export const TIME_TRACKING_MUTATION_INCREMENT_TIME_TRACKINGS_PAGE = `${MODULE_NAME}/MUTATION_INCREMENT_TIME_TRACKINGS_PAGE`
export const TIME_TRACKING_MUTATION_RESET_TIME_TRACKINGS_PAGE = `${MODULE_NAME}/MUTATION_RESET_TIME_TRACKINGS_PAGE`

export const TIME_TRACKING_MUTATION_SET_FIELD_SORTING = `${MODULE_NAME}/MUTATION_SET_FIELD_SORTING`
export const TIME_TRACKING_MUTATION_SET_CURRENT_TAB = `${MODULE_NAME}/MUTATION_SET_CURRENT_TAB`
export const TIME_TRACKING_MUTATION_SET_DATE_RANGE = `${MODULE_NAME}/MUTATION_SET_DATE_RANGE`
export const TIME_TRACKING_MUTATION_REMOVE_FILTERBAR_FILTER = `${MODULE_NAME}/MUTATION_REMOVE_FILTERBAR_FILTER`
export const TIME_TRACKING_MUTATION_ADD_FILTERBAR_FILTER = `${MODULE_NAME}/MUTATION_ADD_FILTERBAR_FILTER`
export const TIME_TRACKING_MUTATION_SET_SHOW_NEW_FILTER_FORM = `${MODULE_NAME}/MUTATION_SET_SHOW_NEW_FILTER_FORM`

export const TIME_TRACKING_MUTATION_SET_NOTE_META = `${MODULE_NAME}/MUTATION_SET_NOTE_META`

export const TIME_TRACKING_MUTATION_SET_EXPORT_ID = `${MODULE_NAME}/MUTATION_SET_EXPORT_ID`
export const TIME_TRACKING_MUTATION_REMOVE_EXPORT_ID = `${MODULE_NAME}/MUTATION_REMOVE_EXPORT_ID`

export const TIME_TRACKING_MUTATION_SET_ACTION_BREAKS = `${MODULE_NAME}/MUTATION_SET_ACTION_BREAKS`
export const TIME_TRACKING_MUTATION_CLEAR_ACTION_BREAKS = `${MODULE_NAME}/MUTATION_CLEAR_ACTION_BREAKS`
