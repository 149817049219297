<template>
  <div class="time-tracking-edit">
    <portal v-if="!$isSmallDevice" to="modal">
      <base-modal has-modal-card>
        <base-card-modal-form
          v-slot="{ handleInputChange }"
          :title="$t('time_tracking.edit_modal.title')"
          :button-submit-text="$t('time_tracking.edit_modal.button_submit')"
          :action-status="$actions.updateTimeTracking"
          :loading="$actions.updateTimeTracking.isLoading"
          :footer-more-options="moreOptions"
          @submit="handleUpdate()"
          @selected="handleSelection($event)"
          @close="$emit('close')"
        >
          <time-tracking-form
            v-model="timeTrackingData"
            :action-status="$actions.updateTimeTracking"
            :is-admin="isAssumedRoleAdmin"
            :details="details"
            edit-mode
            @updated="handleInputChange(true)"
          />
        </base-card-modal-form>
      </base-modal>
    </portal>
    <div v-else>
      <time-tracking-edit-mobile
        v-model="timeTrackingData"
        :action-status="$actions.updateTimeTracking"
        :more-options="moreOptions"
        :details="details"
        @close="$emit('close')"
        @submit="handleUpdate()"
        @selected="handleSelection($event)"
      />
    </div>
  </div>
</template>

<script>
import TimeTrackingForm from '../time-tracking-form/TimeTrackingForm'
import {
  TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING,
  TIME_TRACKING_ACTION_DELETE_TIME_TRACKING,
  TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING_START_AT,
} from '../../store/actions/time-tracking.actions.names'
import { TIME_TRACKING_GETTER_GET_BY_ID } from '../../store/getters/time-tracking.getters.names'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import TimeTrackingEditMobile from './time-tracking-edit-mobile/TimeTrackingEditMobile'
import { mapGetters } from 'vuex'
import {
  TIME_TRACKING_STATES,
  TIME_TRACKING_CREATED_BY,
} from '@/app/modules/time-tracking/constants'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'TimeTrackingEdit',
  components: {
    TimeTrackingForm,
    TimeTrackingEditMobile,
    BaseCardModalForm,
    BaseModal,
  },
  trackedActions: {
    updateTimeTracking: TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING,
    updateTimeTrackingStartAt:
      TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING_START_AT,
  },
  props: {
    timeTrackingId: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeTrackingData: {
        tags: [],
        breaks: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      getById: TIME_TRACKING_GETTER_GET_BY_ID,
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    initialTimeTrackingData() {
      return this.getById(this.timeTrackingId)
    },
    moreOptions() {
      if (
        !this.initialTimeTrackingData ||
        !this.initialTimeTrackingData.permissions.destroy
      ) {
        return []
      }

      return [
        {
          id: 'delete',
          label: this.$t('time_tracking.edit_modal.option_label_delete'),
          icon: 'delete/delete-16',
          classes: 'text-error-400',
        },
      ]
    },
  },
  async mounted() {
    this.initializeTimeTracking()
  },
  methods: {
    initializeTimeTracking() {
      const {
        starts_at,
        ends_at,
        force_custom_break_duration,
        area,
        user,
        tags,
        breaks,
        current_status,
        created_by,
      } = this.details

      const employeeData = user
        ? {
            id: user.id,
            type: user._type || user.type,
            username: user.username,
          }
        : null
      const areaData = area
        ? {
            id: area.id,
            type: area._type || area.type,
            name: area.name,
          }
        : null
      const tagsData = tags
        ? tags.map((tag) => ({
            id: tag.id,
            type: tag._type || tag.type,
            title: tag.title,
          }))
        : []
      const breaksData = breaks.map((timeTrackingBreak) => {
        return {
          id: timeTrackingBreak.id,
          existing: true,
          deleted: false,
          startDateTime: new Date(timeTrackingBreak.starts_at),
          endDateTime: new Date(timeTrackingBreak.ends_at),
        }
      })

      const isRunning = current_status === TIME_TRACKING_STATES.RUNNING

      this.timeTrackingData = {
        id: this.timeTrackingId,
        startDateTime: new Date(starts_at),
        endDateTime: isRunning ? null : new Date(ends_at),
        enforceExactBreakDuration: force_custom_break_duration,
        employee: employeeData,
        area: areaData,
        tags: tagsData,
        breaks: breaksData,
        isRunning,
        currentStatus: current_status,
        createdBy: created_by,
      }
    },
    async handleUpdate() {
      if (
        this.timeTrackingData.createdBy === TIME_TRACKING_CREATED_BY.ACTIONS &&
        (this.timeTrackingData.isRunning ||
          this.timeTrackingData.currentStatus === TIME_TRACKING_STATES.BREAKED)
      ) {
        try {
          await this.$store.dispatch(
            TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING_START_AT,
            {
              actionId: this.timeTrackingId,
              timeTracking: this.timeTrackingData,
            }
          )
          this.$emit('close')
        } catch (err) {
          this.$buefy.snackbar.open({
            message: this.$t(
              'time_tracking.add_modal.validation_message.not_overlap'
            ),
            type: 'is-danger',
          })
        }
      } else {
        if (
          this.timeTrackingData.createdBy ===
            TIME_TRACKING_CREATED_BY.LOCATION_APP &&
          (this.timeTrackingData.isRunning ||
            this.timeTrackingData.currentStatus ===
              TIME_TRACKING_STATES.BREAKED)
        ) {
          this.timeTrackingData.endDateTime = null
        }
        await this.$store.dispatch(TIME_TRACKING_ACTION_UPDATE_TIME_TRACKING, {
          timeTrackingId: this.timeTrackingId,
          timeTracking: {
            ...this.timeTrackingData,
          },
        })
        this.$emit('close')
      }
    },
    async handleSelection(option) {
      if (option.id === 'delete') {
        this.$emit('close')

        await this.$store.dispatch(
          TIME_TRACKING_ACTION_DELETE_TIME_TRACKING,
          this.timeTrackingId
        )

        this.$buefy.snackbar.open(
          this.$t('time_tracking.edit_modal.delete_success_message')
        )
      }
    },
  },
}
</script>
