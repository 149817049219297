



































import { defineComponent } from '@vue/composition-api'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseDropdownTextItem from '@/app/util-modules/ui/dropdown/BaseDropdownTextItem.vue'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import { runOrReturn } from '@/utils/helpers'
import useOptions from './composables/use-options'

export default defineComponent({
  name: 'TimeTrackingHeaderActions',
  components: {
    BaseDropdown,
    BaseDropdownTextItem,
    BaseButton,
    BaseIcon,
  },
  setup(_props, { emit }) {
    const { actionOptions } = useOptions(emit)

    return {
      actionOptions,
      runOrReturn,
    }
  },
})
