import debounce from 'lodash/debounce'
import { ref, computed, watch } from '@vue/composition-api'
import realStore from '@/store'
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@/constants'
import api from '@/app/core/employee/network/employee.api'

async function getSearchResults(store, searchQuery, selectedFollowers) {
  const workspaceId = store.state.workspace.workspace.id

  if (searchQuery.value.length < 2) {
    return []
  }
  const { results } = await api.searchEmployees(workspaceId, {
    page: 1,
    query: searchQuery.value,
    filter: selectedFollowers?.length
      ? {
          id_not_in: selectedFollowers.map((follower) => follower.id),
        }
      : null,
  })
  return results
}

function isEmployeeFollower(employee, followers) {
  return followers.value.some((f) => f.id === employee.id)
}

function getEmployeeActionStatus(employee, isFollower, actions) {
  const actionsAdd = actions.addFollower.instances || {}
  const actionsRemove = actions.removeFollower.instances || {}

  return isFollower ? actionsRemove[employee.id] : actionsAdd[employee.id]
}

export default function useEmployeeSearch(actions, followers) {
  const store =
    process.env.VUE_APP_MODE === 'test' ? this._storeMock : realStore
  const searchQuery = ref('')
  const employeeSearchShown = ref(false)
  const employeeSearchResults = ref([])

  const toggleSearchOverlay = (val) => (employeeSearchShown.value = val)

  const searchEmployees = debounce(async function (query) {
    searchQuery.value = query.trim()
    employeeSearchResults.value = await getSearchResults(
      store,
      searchQuery,
      followers.value
    )
  }, DEFAULT_SEARCH_DEBOUNCE_TIME)

  const employees = computed(() => {
    if (searchQuery.value) {
      return employeeSearchResults.value.map((employee) => {
        const isFollower = isEmployeeFollower(employee, followers)
        const actionStatus = getEmployeeActionStatus(
          employee,
          isFollower,
          actions
        )

        return {
          isNew: !isFollower,
          actionStatus,
          ...employee,
        }
      })
    } else {
      return followers.value.map((employee) => ({
        actionStatus: getEmployeeActionStatus(employee, true, actions),
        ...employee,
      }))
    }
  })

  watch(
    () => employeeSearchShown.value,
    (shown) => {
      if (!shown) {
        searchQuery.value = ''
      }
    }
  )

  return {
    employeeSearchShown,
    toggleSearchOverlay,
    searchEmployees,
    employees,
  }
}
