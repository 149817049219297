import BaseRequest from '@/utils/api/base-request'
import {
  TIME_TRACKING_ENDPOINT_STOP_BREAK,
  TIME_TRACKING_ENDPOINT_START_BREAK,
} from '../network/time-tracking.endpoints'

const INCLUDE_PARAMS = 'time_tracking'
const PERMISSIONS_PARAMS = { update: true, destroy: true, confirm: true }

export const TIME_TRACKING_BREAK_TYPE = 'break'

export interface BreakInclude {
  time_tracking?: {
    id: RecordId
    attributes: Record<string, unknown>
  }[]
}

export interface Break {
  id?: RecordId
  created_at: Date
  created_by: string
  ends_at: Date
  starts_at: Date
  startDateTime: Date
  endDateTime: Date
  existing: boolean
  deleted: boolean
}

export class StopTimeTrackingBreakRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    breakId,
  }: {
    workspaceId: RecordId
    timeTrackingId: RecordId
    breakId: RecordId
  }) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_STOP_BREAK(
      workspaceId,
      timeTrackingId,
      breakId
    )
    super.type = TIME_TRACKING_BREAK_TYPE

    super.method = 'patch'

    super.attributes = {
      ends_at: new Date(),
    }

    super.include = INCLUDE_PARAMS

    super.permissions = PERMISSIONS_PARAMS
  }
}

export class StartTimeTrackingBreakRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
  }: {
    workspaceId: RecordId
    timeTrackingId: RecordId
  }) {
    super()

    super.url = TIME_TRACKING_ENDPOINT_START_BREAK(workspaceId, timeTrackingId)
    super.type = TIME_TRACKING_BREAK_TYPE

    super.method = 'post'

    super.attributes = {
      starts_at: new Date(),
      workingSessionId: timeTrackingId,
    }

    super.include = INCLUDE_PARAMS

    super.permissions = PERMISSIONS_PARAMS
  }
}
