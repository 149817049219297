<template>
  <span class="font-medium text-lg md:text-base" :class="textColorClassName">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'TableRowCellDefault',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    archived: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textColorClassName() {
      return this.archived ? 'text-coal-550' : 'text-coal'
    },
  },
}
</script>
