<template>
  <div>
    <time-tracking-applied-filters-panel />

    <div class="border-t border-coal-150 pt-6">
      <tag-search
        v-model="passedSelectedTag"
        :size="!$isSmallDevice ? 'is-medium' : 'is-large'"
        :name="$t('time_tracking.assign_tag_modal.tag_select_name')"
        :label="$t('time_tracking.assign_tag_modal.tag_select_label')"
        dropdown-position="top"
        validation-rules="required"
        class="mb-6"
      />

      <div class="flex flex-col md:flex-row md:items-center">
        <base-radio
          v-model="passedAssignOption"
          name="assignOption"
          native-value="add"
          class="time-tracking-assign-tag-form-radio-add"
          :size="radioButtonSize"
        >
          {{ $t('time_tracking.assign_tag_modal.add_assigned_tags_option') }}
        </base-radio>
        <base-radio
          v-model="passedAssignOption"
          name="assignOption"
          native-value="override"
          :size="radioButtonSize"
        >
          {{
            $t('time_tracking.assign_tag_modal.override_assigned_tags_option')
          }}
        </base-radio>
      </div>
    </div>
  </div>
</template>

<script>
import TimeTrackingAppliedFiltersPanel from '../time-tracking-applied-filters-panel/TimeTrackingAppliedFiltersPanel'
import TagSearch from '@/app/core/tag/components/tag-search/TagSearch'
import BaseRadio from '@/app/util-modules/ui/radio/BaseRadio'

export default {
  name: 'TimeTrackingAssignTagForm',
  components: {
    TagSearch,
    TimeTrackingAppliedFiltersPanel,
    BaseRadio,
  },
  props: {
    selectedTag: {
      type: Object,
      default: null,
    },
    assignOption: {
      type: String,
      default: '',
    },
  },
  computed: {
    radioButtonSize() {
      return this.$isSmallDevice ? 'is-medium' : 'is-small'
    },
    passedSelectedTag: {
      get() {
        return this.selectedTag
      },
      set(newTag) {
        this.$emit('update:selectedTag', newTag)
      },
    },
    passedAssignOption: {
      get() {
        return this.assignOption
      },
      set(newOption) {
        this.$emit('update:assignOption', newOption)
      },
    },
  },
}
</script>

<style scoped>
.b-radio.radio.time-tracking-assign-tag-form-radio-add {
  @apply mb-5 md:mr-6 md:mb-0;
}
</style>
