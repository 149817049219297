import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import * as actionNamesObj from './time-tracking.actions.names'
import * as mutationNamesObj from '../mutations/time-tracking.mutations.names'
import api from '../../network/time-tracking.api'
import { TIME_TRACKING_NOTE_TYPE } from '../../models/time-tracking-note.model'
import { NOTE_ATTACHMENT_TYPE } from '../../models/time-tracking-attachment.model'
import { Attachment } from '@/utils/note-attachments'
import { restructureRecord } from '@/utils/jsonapi/utils'
import { TimeTrackingState } from '../time-tracking.state'
import { BaseRequestPagination } from '@/utils/api/base-request'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  // adds note records to store. This method removes existing
  // notes (for the time tracking) and then adds new ones
  [actionNames.TIME_TRACKING_NOTE_ACTION_LIST]: async (
    {
      dispatch,
      commit,
      state,
      rootState,
    }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      type,
      pagination,
    }: {
      timeTrackingId: RecordId
      type: string
      pagination: BaseRequestPagination
    }
  ) => {
    const responseData = await api.listTimeTrackingNotes({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      filter: { type },
      pagination,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
      records: responseData.included,
    })

    // remove current notes before appending.
    // for some cases, like for toggling system notes, we have to clear existing
    // notes before adding new.
    const timeTracking = state.records.time_tracking.find(
      (item) => item.id === timeTrackingId
    )
    if (timeTracking && timeTracking.notes) {
      const notesForRemoval = timeTracking.notes.map((item) => ({
        id: item.id,
      }))
      commit(mutationNames.TIME_TRACKING_MUTATION_DELETE_RECORDS, {
        type: TIME_TRACKING_NOTE_TYPE,
        matches: notesForRemoval,
      })
    }

    commit(mutationNames.TIME_TRACKING_MUTATION_APPEND_RECORDS, {
      type: TIME_TRACKING_NOTE_TYPE,
      records: responseData.data,
    })
    commit(
      mutationNames.TIME_TRACKING_MUTATION_SET_NOTE_META,
      responseData.meta
    )

    // re-fetching time tracking is a solution to update note counts
    // and tracking->notes relationships
    await dispatch(
      actionNames.TIME_TRACKING_ACTION_GET_TIME_TRACKING,
      timeTrackingId
    )
  },

  [actionNames.TIME_TRACKING_NOTE_ACTION_LIST_APPEND]: async (
    { commit, rootState }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      type,
      pagination,
    }: {
      timeTrackingId: RecordId
      type: string
      pagination: BaseRequestPagination
    }
  ) => {
    const responseData = await api.listTimeTrackingNotes({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      filter: { type },
      pagination,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_ADD_INCLUDED, {
      records: responseData.included,
    })

    commit(mutationNames.TIME_TRACKING_MUTATION_APPEND_RECORDS, {
      type: TIME_TRACKING_NOTE_TYPE,
      records: responseData.data,
    })
    commit(
      mutationNames.TIME_TRACKING_MUTATION_SET_NOTE_META,
      responseData.meta
    )

    return responseData
  },

  [actionNames.TIME_TRACKING_NOTE_ACTION_ADD]: async (
    { state, rootState }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      content,
      attachments,
    }: {
      timeTrackingId: RecordId
      content: string
      attachments: Attachment[]
    }
  ) => {
    const responseData = await api.addTimeTrackingNote({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      content,
      attachments,
    })
    return restructureRecord(responseData.data, state)
  },

  [actionNames.TIME_TRACKING_NOTE_ACTION_UPDATE]: async (
    { commit, state, rootState }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      id,
      content,
      newAttachments,
      removedAttachments = [],
    }: {
      timeTrackingId: RecordId
      id: RecordId
      content: string
      newAttachments: Attachment[]
      removedAttachments: Attachment[]
    }
  ) => {
    const responseData = await api.updateTimeTrackingNote({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      id,
      content,
      newAttachments,
      removedAttachments,
    })

    // delete removed attachments
    commit(mutationNames.TIME_TRACKING_MUTATION_DELETE_RECORDS, {
      type: NOTE_ATTACHMENT_TYPE,
      matches: removedAttachments.map(({ id }) => ({ id })),
    })

    return restructureRecord(responseData.data, state)
  },

  [actionNames.TIME_TRACKING_NOTE_ACTION_DELETE]: (
    { rootState }: ActionContext<TimeTrackingState, any>,
    {
      timeTrackingId,
      noteId,
    }: {
      timeTrackingId: RecordId
      noteId: RecordId
    }
  ) => {
    return api.deleteTimeTrackingNote({
      workspaceId: rootState.workspace.workspace.id,
      timeTrackingId,
      noteId,
    })
  },
}
