<template>
  <portal to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-more-options="moreOptions"
      :header-title="$t('time_tracking.edit_modal.title')"
      :header-right-actions="bottomSheetActions"
      :action-status="actionStatus"
      @done="$emit('submit')"
      @close="$emit('close')"
      @handle-selection="$emit('selected', $event)"
    >
      <time-tracking-form
        v-model="passedValue"
        :action-status="actionStatus"
        :details="details"
        edit-mode
        :is-admin="isAdmin"
        class="px-4 pt-4 pb-6"
      />
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex'
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'
import TimeTrackingForm from '../../time-tracking-form/TimeTrackingForm'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'TimeTrackingEditMobile',
  components: {
    TimeTrackingForm,
    BottomSheetFormWrapper,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    bottomSheetActions() {
      return [
        {
          title: this.$t('time_tracking.edit_modal.button_submit_mobile'),
          loading: this.actionStatus.isLoading,
          class: 'justify-self-end text-green font-semibold text-lg',
          event: 'update',
        },
      ]
    },
  },
}
</script>
