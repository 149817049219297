<template>
  <base-textarea
    :value="note.content"
    class="h-full"
    data-id-prefix="time_tracking.notes_modal"
    :attachments="attachments"
    :show-action-submit="true"
    :show-action-attachment="true"
    :is-loading="actionStatus.isLoading"
    :submit-button-label="$t('time_tracking.add_notes_modal.submit_note')"
    :no-borders="$isSmallDevice"
    @input="$emit('input', $event)"
    @attachment-delete="removedAttachmentsIds.push($event.id)"
    v-on="$listeners"
  />
</template>

<script>
import BaseTextarea from '@/app/util-modules/ui/textarea/BaseTextarea'

export default {
  name: 'TimeTrackingNotesForm',
  components: {
    BaseTextarea,
  },
  props: {
    note: {
      type: Object,
      default: () => ({}),
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      removedAttachmentsIds: [],
    }
  },
  computed: {
    attachments() {
      if (!this.note.attachments) {
        return []
      }
      return this.note.attachments
        .map((a) => ({
          id: a.id,
          name: a.file_name,
        }))
        .filter((a) => !this.removedAttachmentsIds.includes(a.id))
    },
  },
}
</script>
