<template>
  <div class="flex justify-start mb-1 font-semibold">
    <div class="w-20 mr-10">
      {{ label }}
    </div>

    <div class="flex-1">
      <div v-for="(item, index) in values" :key="index" class="mb-3">
        <component
          :is="item.component"
          v-if="item.component"
          v-bind="item.props"
        />
        <template v-else>
          {{ item }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'TimeTrackingNotesNoteField',
  components: {
    BaseIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object, Array],
      default: () => [],
    },
  },
  computed: {
    values() {
      if (!Array.isArray(this.value)) {
        return [this.value]
      }
      return this.value
    },
  },
}
</script>
