<template>
  <div
    class="flex items-center rounded-lg p-2"
    :class="{ 'bg-coal-40': customBackground }"
  >
    <div>
      <base-icon
        v-if="icon"
        icon="time-tracking/time-tracking-26"
        type="is-filled"
        class="mr-1.5"
      />
    </div>
    <div class="flex flex-col">
      <div class="label">
        <span class="font-semibold text-coal-550">{{ label }}</span>
      </div>
      <div class="flex items-center">
        <span class="font-bold line-clamp-2">{{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
export default {
  name: 'TimeTrackingReadOnlyField',
  components: {
    BaseIcon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      required: false,
    },
    customBackground: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
