var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center",class:_vm.wrapperClass},[_c(_vm.wrapperComponent,{tag:"component",class:[
      _vm.textClass,
      _vm.archivedClass,
      _vm.responsiveClass,
      _vm.labelClass,
      'w-full font-bold md:text-base text-lg flex-1.5' ],attrs:{"to":{
      name: _vm.routeLinkToName,
      params: {
        userId: _vm.user.id,
      },
    }}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.archived)?_c('span',{staticClass:"text-coal-300 flex items-center"},[_c('base-icon',{attrs:{"icon":"archive/archive-16","size":"0.875rem"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }