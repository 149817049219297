<template>
  <portal to="bottom-sheet">
    <bottom-sheet key="row-details" @cancel-click="close()">
      <template v-slot:header>
        <mobile-table-row-details-header
          :label="details.user.username"
          :user="details.user"
          :archived="details.archived"
          @close="close()"
        />
      </template>

      <mobile-table-row-details-body :details="details" />

      <template v-if="!details.archived" v-slot:footer>
        <mobile-table-row-details-footer
          :is-confirmed="details.confirmed"
          :is-finished="isFinished"
          :can-confirm="details.permissions.confirm"
          :can-update="canUpdate"
          :is-running="isRunning"
          :relationships-counts="relationshipsCounts"
          :actions-statuses="actionsStatuses"
          @confirm-click="$emit('confirm-action-click')"
          @notes-click="$emit('notes-action-click')"
          @attachments-click="$emit('attachments-action-click')"
          @signatures-click="$emit('signatures-action-click')"
          @settings-click="$emit('edit-action-click', details.id)"
        />
      </template>
    </bottom-sheet>
  </portal>
</template>

<script>
import { TIME_TRACKING_STATES } from '@/app/modules/time-tracking/constants'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
import MobileTableRowDetailsHeader from './MobileTableRowDetailsHeader'
import MobileTableRowDetailsBody from './MobileTableRowDetailsBody'
import MobileTableRowDetailsFooter from './MobileTableRowDetailsFooter'

export default {
  name: 'MobileTableRowDetails',
  components: {
    BottomSheet,
    MobileTableRowDetailsHeader,
    MobileTableRowDetailsBody,
    MobileTableRowDetailsFooter,
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    actionsStatuses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    relationshipsCounts() {
      return {
        notes: this.details.user_notes_count,
        signatures: this.details.signatures_count,
        attachments: this.details.attachments_count,
      }
    },
    canUpdate() {
      return true
    },
    isFinished() {
      return this.details.current_status === TIME_TRACKING_STATES.FINISHED
    },
    isRunning() {
      return this.details.current_status === TIME_TRACKING_STATES.RUNNING
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
