import BaseRequest, { BaseRequestPagination } from '@/utils/api/base-request'
import {
  TIME_TRACKING_ENDPOINT_LIST_NOTES,
  TIME_TRACKING_ENDPOINT_ADD_NOTE,
  TIME_TRACKING_ENDPOINT_UPDATE_NOTE,
  TIME_TRACKING_ENDPOINT_DELETE_NOTE,
} from '../network/time-tracking.endpoints'
import { Attachment, addAttachments } from '@/utils/note-attachments'

export const TIME_TRACKING_NOTE_TYPE = 'note'
export const NOTES_MODAL_ITEMS_PER_PAGE = 20
export const NOTES_BOTTOM_SHEET_ITEMS_PER_PAGE = 5

export interface Note {
  id?: RecordId
  content: string
  author: string
  author_role: string
  created_at: Date
}

export interface NoteListRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  filter: Record<string, unknown>
  pagination: BaseRequestPagination
}

export interface NoteAddRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  content: string
  attachments: Attachment[]
}

export interface NoteUpdateRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  id: RecordId
  content: string
  newAttachments: Attachment[]
  removedAttachments: Attachment[]
}

export interface NoteDeleteRequestParams {
  workspaceId: RecordId
  timeTrackingId: RecordId
  noteId: RecordId
}
export class TimeTrackingNoteListRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    filter = {},
    pagination,
  }: NoteListRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_LIST_NOTES(workspaceId, timeTrackingId)
    super.type = TIME_TRACKING_NOTE_TYPE

    super.include = 'attachments'

    super.filter = filter
    super.sorting = {
      created_at: -1,
    }
    super.pagination = {
      items: pagination.items,
      page: pagination.page,
    }

    super.permissions = {
      update: true,
      destroy: true,
    }
  }
}

export class TimeTrackingNoteAddRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    content,
    attachments,
  }: NoteAddRequestParams) {
    super()

    super.method = 'post'
    super.url = TIME_TRACKING_ENDPOINT_ADD_NOTE(workspaceId, timeTrackingId)
    super.type = TIME_TRACKING_NOTE_TYPE

    const data = new FormData()
    data.append('note[content]', content)
    addAttachments(data, attachments)

    super.data = data
  }
}

export class TimeTrackingNoteUpdateRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    id,
    content,
    newAttachments,
    removedAttachments,
  }: NoteUpdateRequestParams) {
    super()

    super.method = 'put'
    super.url = TIME_TRACKING_ENDPOINT_UPDATE_NOTE(
      workspaceId,
      timeTrackingId,
      id
    )
    super.type = TIME_TRACKING_NOTE_TYPE

    const data = new FormData()
    data.append('note[content]', content)
    addAttachments(data, newAttachments, removedAttachments)

    super.data = data
  }
}

export class TimeTrackingNoteDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    timeTrackingId,
    noteId,
  }: NoteDeleteRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_DELETE_NOTE(
      workspaceId,
      timeTrackingId,
      noteId
    )
    super.method = 'delete'
    super.type = TIME_TRACKING_NOTE_TYPE
  }
}
