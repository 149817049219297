<template>
  <div class="flex pb-3 px-4 border-b border-coal-150">
    <div class="justify-self-start text-left flex-1">
      <button class="text-lg text-coal-550" @click="$emit('close')">
        {{ $t('time_tracking.index.body.row_details.close') }}
      </button>
    </div>

    <table-row-cell-employee
      :label="label"
      :archived="archived"
      class="flex-3 justify-center"
      label-class="text-center"
      :user="user"
    />
    <div class="flex-1" />
  </div>
</template>

<script>
import TableRowCellEmployee from '../table-row-cell-employee/TableRowCellEmployee'

export default {
  name: 'MobileTableRowDetailsHeader',
  components: {
    TableRowCellEmployee,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    archived: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
