import { ref } from '@vue/composition-api'

export default function useAttachments() {
  const newAttachments = ref([])
  const removedAttachments = ref([])

  const setNewAttachments = (files) => {
    newAttachments.value = files
  }

  const removeExistingAttachment = (att) => {
    if (att instanceof File === false && att.id != null) {
      removedAttachments.value.push(att)
    }
  }

  const clearAttachments = () => {
    newAttachments.value = []
    removedAttachments.value = []
  }

  return {
    newAttachments,
    removedAttachments,
    setNewAttachments,
    removeExistingAttachment,
    clearAttachments,
  }
}
