<template>
  <div>
    <div
      v-if="!row.archived"
      ref="actionsContent"
      class="h-full flex items-center justify-end"
    >
      <base-group-selection-toolbar-action-item
        v-if="showConfirmAction"
        :tooltip="confirmTooltipText"
        :tooltip-multilined="!row.permissions.confirm"
        tooltip-position="is-left"
        tooltip-size="is-small"
      >
        <base-group-selection-toolbar-action-button
          icon="tick-confirm"
          type="is-filled"
          role="button"
          data-id="time_tracking.index.body.confirm_button_text"
          :disabled="!row.permissions.confirm"
          :aria-label="$t('time_tracking.index.body.confirm_button_text')"
          @click="$emit('confirm-action-click')"
        />
      </base-group-selection-toolbar-action-item>

      <template v-if="!$isMediumDevice">
        <base-group-selection-toolbar-action-item
          v-if="canUpdate"
          :tooltip="$t('time_tracking.index.body.edit_tracking')"
        >
          <base-group-selection-toolbar-action-button
            type="is-filled-grey"
            class="my-1.5 hover:text-coal-850"
            icon="settings"
            role="button"
            data-id="time_tracking.index.body.edit_tracking"
            :aria-label="$t('time_tracking.index.body.edit_tracking')"
            @click="$emit('edit-action-click')"
          />
        </base-group-selection-toolbar-action-item>

        <base-group-selection-toolbar-action-item
          v-if="canUpdateNotes"
          data-id="time_tracking.index.body.tooltip_notes"
          :tooltip="$t('time_tracking.index.body.tooltip_notes')"
        >
          <base-group-selection-toolbar-action-button
            type="is-filled-grey"
            class="my-1.5 hover:text-coal-850"
            icon="list"
            role="button"
            data-id="time_tracking.index.body.tooltip_notes"
            :aria-label="$t('time_tracking.index.body.tooltip_notes')"
            @click="$emit('notes-action-click')"
          />
        </base-group-selection-toolbar-action-item>
      </template>

      <base-dropdown v-else position="is-bottom-left" :append-to-body="true">
        <template v-slot:trigger="{ active }">
          <base-group-selection-toolbar-action-item
            :tooltip="$t('time_tracking.index.body.tooltip_other')"
            :hide-tooltip="active"
          >
            <base-group-selection-toolbar-action-button
              class="text-coal-850 hover:text-coal-550 my-1.5"
              icon="ellipsis"
              icon-type="is-filled"
              role="button"
              data-id="time_tracking.index.body.tooltip_other"
              :aria-label="$t('time_tracking.index.body.tooltip_other')"
              type="is-filled-grey"
            />
          </base-group-selection-toolbar-action-item>
        </template>

        <div class="w-64">
          <mobile-table-row-details-body :details="row" />

          <mobile-table-row-details-footer
            v-if="!row.archived"
            :is-confirmed="row.confirmed"
            :can-confirm="row.permissions.confirm"
            :can-update="row.permissions.update"
            :relationships-counts="relationshipsCounts"
            @notes-click="$emit('notes-action-click')"
            @attachments-click="$emit('attachments-action-click')"
            @signatures-click="$emit('signatures-action-click')"
            @settings-click="$emit('edit-action-click', row.id)"
          />
        </div>
      </base-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TIME_TRACKING_STATES } from '@/app/modules/time-tracking/constants'

const BaseGroupSelectionToolbarActionItem = () =>
  import(
    '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem'
  )
const BaseGroupSelectionToolbarActionButton = () =>
  import(
    '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionButton'
  )

const BaseDropdown = () => import('@/app/util-modules/ui/dropdown/BaseDropdown')

import MobileTableRowDetailsBody from '../mobile-table-row-details/MobileTableRowDetailsBody'
import MobileTableRowDetailsFooter from '../mobile-table-row-details/MobileTableRowDetailsFooter'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { WORKSPACE_GETTER_USER_CURRENT_WORKSPACE } from '@/app/core/workspace/store/getters/workspace.getters.names'

const ACTIONS = 'actions'

export default {
  name: 'TableRowCellActions',
  components: {
    BaseGroupSelectionToolbarActionItem,
    BaseGroupSelectionToolbarActionButton,
    BaseDropdown,
    MobileTableRowDetailsBody,
    MobileTableRowDetailsFooter,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
      currentWorkspace: WORKSPACE_GETTER_USER_CURRENT_WORKSPACE,
    }),
    showConfirmAction() {
      return (
        this.row.current_status === TIME_TRACKING_STATES.FINISHED &&
        !this.row.confirmed &&
        this.isAdmin
      )
    },
    confirmTooltipText() {
      return this.row.permissions.confirm
        ? this.$t('time_tracking.index.body.confirm_button_text')
        : this.$t('time_tracking.index.body.no_confirm_permission')
    },
    relationshipsCounts() {
      return {
        notes: this.row.user_notes_count,
        signatures: this.row.signatures_count,
        attachments: this.row.attachments_count,
      }
    },
    canUpdate() {
      return true
    },
    canUpdateNotes() {
      return (
        this.row.current_status !== TIME_TRACKING_STATES.RUNNING ||
        this.row.created_by !== ACTIONS
      )
    },
  },
}
</script>
