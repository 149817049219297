<template>
  <span
    :data-id="dataIdAttr"
    class="md:uppercase md:text-sm text-coal font-bold text-right"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: 'TableHeaderActions',
  props: {
    value: {
      type: String,
      default: '',
    },
    dataIdAttr: {
      type: String,
      default: '',
    },
  },
}
</script>
