<template>
  <portal to="bottom-sheet">
    <bottom-sheet-form-wrapper
      :header-title="title"
      :header-right-actions="rightActions"
      @done="$emit('submit')"
      @close="$emit('closeModal')"
    >
      <slot />
    </bottom-sheet-form-wrapper>
  </portal>
</template>

<script>
import BottomSheetFormWrapper from '@/app/util-modules/ui/bottom-sheet/BottomSheetFormWrapper'

export default {
  name: 'TimeTrackingAssignTagMobile',
  components: {
    BottomSheetFormWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    trackedAction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rightActions() {
      return [
        {
          title: this.$t('time_tracking.assign_tag_modal.button_submit_mobile'),
          loading: this.trackedAction.isLoading,
          class: 'text-green-400 text-right font-semibold text-lg',
          event: 'submit',
        },
      ]
    },
  },
}
</script>
