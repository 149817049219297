import BaseRequest from '@/utils/api/base-request'
import {
  TIME_TRACKING_ENDPOINT_BATCH_CONFIRM,
  TIME_TRACKING_ENDPOINT_BATCH_DELETE,
  TIME_TRACKING_ENDPOINT_BATCH_ASSIGN_TAGS,
  TIME_TRACKING_ENDPOINT_BATCH_ADD_NOTE,
} from '../network/time-tracking.endpoints'
import { Attachment, addAttachments } from '@/utils/note-attachments'

const TIME_TRACKING_BATCH_TYPE = 'batch_action'

export interface TimeTrackingBatchAssignTagsRequestParams {
  workspaceId: RecordId
  trackingIds: RecordId[]
  tagId: RecordId
  overrideTags: boolean
}

class TimeTrackingBatchOperationRequest extends BaseRequest {
  formatData(action: string, ids: string[], all: boolean = false) {
    return {
      action,
      ids,
      all,
    }
  }
}

export class TimeTrackingBatchConfirmRequest extends TimeTrackingBatchOperationRequest {
  constructor(
    workspaceId: RecordId,
    timeTrackingIds: RecordId[],
    checkAll: boolean
  ) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_BATCH_CONFIRM(workspaceId)
    super.type = TIME_TRACKING_BATCH_TYPE
    super.method = 'post'

    super.attributes = super.formatData('confirm', timeTrackingIds, checkAll)
  }
}

export class TimeTrackingBatchDeleteRequest extends TimeTrackingBatchOperationRequest {
  constructor(workspaceId: RecordId, timeTrackingIds: RecordId[]) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_BATCH_DELETE(workspaceId)
    super.type = TIME_TRACKING_BATCH_TYPE
    super.method = 'post'

    super.attributes = super.formatData('destroy', timeTrackingIds)
  }
}

export class TimeTrackingBatchAssignTagsRequest extends TimeTrackingBatchOperationRequest {
  constructor({
    workspaceId,
    trackingIds,
    tagId,
    overrideTags,
  }: TimeTrackingBatchAssignTagsRequestParams) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_BATCH_ASSIGN_TAGS(workspaceId)
    super.type = TIME_TRACKING_BATCH_TYPE
    super.method = 'post'

    super.attributes = {
      ...super.formatData('tag', trackingIds),
      tag_ids: [tagId],
      override_tags: overrideTags,
    }
  }
}

export class TimeTrackingBatchAddNoteRequest extends TimeTrackingBatchOperationRequest {
  constructor(
    workspaceId: RecordId,
    timeTrackingIds: RecordId[],
    noteContent: string,
    attachments: Attachment[]
  ) {
    super()
    super.url = TIME_TRACKING_ENDPOINT_BATCH_ADD_NOTE(workspaceId)
    super.type = TIME_TRACKING_BATCH_TYPE
    super.method = 'post'

    const data = new FormData()

    data.append(`${TIME_TRACKING_BATCH_TYPE}[action]`, 'note')

    timeTrackingIds.forEach((id) =>
      data.append(`${TIME_TRACKING_BATCH_TYPE}[ids][]`, id)
    )

    if (noteContent) {
      data.append(`${TIME_TRACKING_BATCH_TYPE}[note][content]`, noteContent)
    }

    if (attachments.length) {
      addAttachments(
        data,
        attachments,
        [],
        `${TIME_TRACKING_BATCH_TYPE}[note][note_attachments_attributes]`
      )
    }

    // super.attributes = data
    super.data = data
  }
}
