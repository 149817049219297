<template>
  <portal v-if="isOpen" to="modal">
    <base-modal has-modal-card full-screen @close="$emit('close')">
      <base-card-modal-form title="" class="base-card-modal">
        <template v-slot:header> <div /> </template>
        <div class="flex flex-col h-full">
          <div class="px-4 py-6 border-b border-coal-150">
            <employee-search
              :placeholder="
                $t('time_tracking.add_notes_modal.textarea_placeholder_mobile')
              "
              icon="arrow"
              icon-left="search/search-16"
              :infinite-scroll="true"
              dropdown
              name="employee-search"
              dropdown-position="bottom"
              @input="selectEmployee($event)"
            />
          </div>

          <div class="flex-1 overflow-y-auto">
            <div
              v-for="(employee, index) in employees"
              :key="`${employee}-avatar-${index}-mobile`"
              class="pl-4"
            >
              <div
                class="p-4 pl-0 flex items-center border-coal-150"
                :class="{ 'border-b ': index < employees.length - 1 }"
              >
                <base-avatar
                  size="is-small"
                  :img-url="employee.avatar_thumb_url"
                  img-class="w-8 h-8"
                  :username="employee.username"
                  class="employee-avatar mr-2.5"
                  avatar-class="bg-blue-300 text-white"
                />

                <span class="text-lg flex-1">{{ employee.username }}</span>

                <button @click="removeEmployee(index)">
                  <base-icon
                    class="text-xs text-coal-450"
                    icon="cross-close/cross-close-12"
                    size="1rem"
                  />
                </button>
              </div>
            </div>
          </div>

          <div class="flex px-4 py-3">
            <base-button
              type="is-filled"
              class="self-end"
              expanded
              @click="submitEmployees()"
            >
              {{ $t('time_tracking.add_notes_modal.button_done') }}
            </base-button>
          </div>
        </div>
        <template v-slot:footer> <div /> </template>
      </base-card-modal-form>
    </base-modal>
  </portal>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar'
import EmployeeSearch from '@/app/core/employee/components/employee-search/EmployeeSearch'

export default {
  name: 'TimeTrackingNoteAddMobileEmployeeModal',
  components: {
    BaseModal,
    BaseIcon,
    BaseButton,
    BaseCardModalForm,
    EmployeeSearch,
    BaseAvatar,
  },
  props: {
    currentEmployees: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employees: this.currentEmployees,
    }
  },
  methods: {
    selectEmployee(selectedEmployee) {
      if (
        this.employees.find((employee) => employee.id === selectedEmployee.id)
      )
        return
      this.employees.push(selectedEmployee)
    },
    removeEmployee(index) {
      this.employees.splice(index, 1)
    },
    submitEmployees() {
      this.$emit('submitEmployees', this.employees)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.base-card-modal {
  .base-card-modal-body {
    @apply p-0 h-full;
  }
}

.employee-avatar {
  @apply p-0;
}
</style>
