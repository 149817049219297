<template>
  <div>
    <portal to="bottom-sheet">
      <bottom-sheet
        key="add-note"
        :header-title="title"
        :header-right-actions="bottomSheetActions"
        @submit-click="submitNote()"
        @cancel-click="$emit('closeModal')"
      >
        <div class="p-4">
          <!-- TODO: batch add followers are to be implemented in future task: https://app.asana.com/0/1175527195735801/1200838322239190/f
          <div class="mb-6" :class="wrapperClass">
            <div class="flex items-center mr-1.5">
              <time-tracking-note-avatar
                v-for="(employee, index) in employees"
                :key="`${employee}-avatar-${index}`"
                :employee="employee"
                @click="$emit('removeEmployee', index)"
              />
            </div>

            <div v-if="employees.length === 0" class="flex items-center">
              <time-tracking-note-avatar-add-button
                @click="manageEmployees()"
              />

              <span class="text-coal-550 text-sm font-semibold">
                {{ $t('time_tracking.add_notes_modal.notified_colleagues') }}
              </span>
            </div>

            <base-button
              v-else
              type="is-outlined-grey"
              size="is-small"
              @click="manageEmployees()"
            >
              {{ $t('time_tracking.add_notes_modal.manage_followers') }}
            </base-button>
          </div-->

          <base-textarea
            v-model="content"
            class="h-full"
            :show-action-submit="true"
            :show-action-attachment="true"
            :is-loading="actionStatus.isLoading"
            :no-borders="true"
            :submit-button-label="
              $t('time_tracking.add_notes_modal.submit_note')
            "
            @validity-change="submitEnabled = $event"
            @file-input="addAttachment($event)"
          />
        </div>
      </bottom-sheet>
    </portal>

    <time-tracking-note-add-mobile-employee-modal
      v-if="openEmployeeSelectModal"
      :is-open="openEmployeeSelectModal"
      :current-employees="employees"
      @submitEmployees="submitEmployees($event)"
    />
  </div>
</template>

<script>
//import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet'
//import TimeTrackingNoteAvatar from './TimeTrackingNoteAvatar'
//import TimeTrackingNoteAvatarAddButton from './TimeTrackingNoteAvatarAddButton'
import BaseTextarea from '@/app/util-modules/ui/textarea/BaseTextarea'
import { isSameFile } from '@/utils/helpers'
const TimeTrackingNoteAddMobileEmployeeModal = () =>
  import('./TimeTrackingNoteAddMobileEmployeeModal')

export default {
  name: 'TimeTrackingNoteAddMobile',
  components: {
    //BaseButton,
    BottomSheet,
    BaseTextarea,
    //TimeTrackingNoteAvatar,
    //TimeTrackingNoteAvatarAddButton,
    TimeTrackingNoteAddMobileEmployeeModal,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      openEmployeeSelectModal: false,
      submitEnabled: false,
      content: '',
      attachments: [],
    }
  },
  computed: {
    wrapperClass() {
      return {
        'flex items-center justify-between': this.employees.length !== 0,
      }
    },
    bottomSheetActions() {
      return [
        {
          title: this.$t('time_tracking.add_notes_modal.submit_note_mobile'),
          class: 'font-semibold text-green-400',
          event: 'submit-click',
        },
      ]
    },
  },
  methods: {
    manageEmployees() {
      this.openEmployeeSelectModal = true
    },
    addAttachment(files) {
      const attachments = [...this.attachments]
      const filesToAdd = files.filter((f) => {
        const foundAttachment = attachments.findIndex((f2) => isSameFile(f2, f))
        return foundAttachment === -1
      })
      this.attachments = [...attachments, ...filesToAdd]
    },
    submitEmployees(employees) {
      this.$emit('submitEmployees', employees)
      this.closeEmployeeSelectModal()
    },
    closeEmployeeSelectModal() {
      this.openEmployeeSelectModal = false
    },
    submitNote() {
      if (this.submitEnabled) {
        this.$emit('submit', {
          content: this.content,
          attachments: this.attachments,
        })
      }
    },
  },
}
</script>
